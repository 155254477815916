<template>
	<div class="editer-page">
		<full-loading ref="fullLoading" :title="loadingText"></full-loading>
		<qa-setting ref="qaSetting" :doc="docInfo" @submit="queryQaData" v-if="docInfo.projectId"></qa-setting>
		<div class="header" v-if="docInfo">
			<editer-tools
				:doc="docInfo"
				:saving="showAutoSave"
				:saveTime="lastSaveTime"
				:flex="2"
				:sourceMode="sourceMode"
				:showRight="showRight"
                :filterSize="filterSize"
                :loadingKey="actionLoadingkey"
                :sentPermission="sentPermission"
                :showSpace="showSpace"
				@saveSentences="saveSentences"
				@saveDocuments="saveDocuments"
				@reTranslate="reTranslate"
				@setQAPanel="showQAPanelEvent"
				@setFRPanel="showFindReplacePanelEvent"
				@repeal="repealOrRecoverBtnEvent"
				@recover="repealOrRecoverBtnEvent"
				@setStyle="setSentencesStyle"
				@action="executeAction">
			</editer-tools>
		</div>
		<div class="edit" v-if="docInfo">
			<div class="left">
				<basic-editer
					ref="editerDom"
					:doc="docInfo"
					:preview="previewInfo"
					:sentId="currentSentId"
					:pagination="pagination"
					:jargons="jargons"
					:sourceMode="sourceMode"
                    :loadingKey="actionLoadingkey"
                    :fontSize="editorFontSize"
                    :showSpace="showSpace"
					@setSentId="setCurrentSentId"
					@pageNumChange="pageNumChangeEvent"
					@prevPage="initPrevPage"
					@saveSentences="saveSentences"
					@getRelationInfo="queryRelationInfo"
					@setCursortPosition="setCursortPosition"
					@clear="clearFlagStatus"
					@setQAPanel="showQAPanelEvent"
					@onLocked="setSentencesLockStatus"
					@setStyle="setSentencesStyle"
					@executeAction="executeAction">
				</basic-editer>
				<!-- <basic-editer-vertical v-else
					ref="editerDom"
					:doc="docInfo"
					:pagination="pagination"
					@pageNumChange="pageNumChangeEvent"
					@prevPage="initPrevPage"
					@saveSentences="saveSentences"
					@getRelationInfo="queryRelationInfo"
					@setCursortPosition="setCursortPosition">
				</basic-editer-vertical> -->
			</div>
			
			<transition name="editer-right-slide"
				enter-active-class="animated slideInRight faster"
				leave-active-class="animated slideOutRight faster">
				<div class="right" v-show="showRight">
					<editer-right
						:jargonsLoading="jargonsLoading"
						:translatesLoading="translatesLoading"
						:doc="docInfo"
						:jargons="jargons"
						:translates="translates"
						:memoriesLoading="memoriesLoading"
						:memories="memories"
                        :sentPermission="sentPermission"
                        @action="executeAction"
						@applyTranslate="applyTranslate"
						@applyMemory="applyMemory"
						@applyJargon="applyJargon"
						v-if="panel.showDefaultRight">
					</editer-right>
					<qa-right v-if="panel.showPanel === 1"
                        :doc="docInfo"
						:data="panel.qaData"
						:issueId="panel.locateSentId"
                        :sentPermission="sentPermission"
                        @init="queryQaData"
						@close="hidePanelEvent"
						@ignore="ignoreQA"
						@locate="setQALocate"
                        @action="executeAction">
                    </qa-right>
					<find-replace-right v-if="panel.showPanel === 2"
						:doc="docInfo"
						:list="panel.frList"
						:loading="panel.frLoading"
						:replaceLoading="panel.replaceLoading"
						:replaceAllLoading="panel.replaceAllLoading"
						:showRepeal="panel.showRepealReplaceAllBtn"
                        :canReplaceAll="panel.canReplaceAll"
						@close="hidePanelEvent"
						@search="queryFindReplaceData"
						@replace="replaceData"
						@replaceAll="replaceAllData"
						@locate="setFindReplaceLocate"
						@clearList="clearFindReplaceData"
						@repeal="repealReplaceAllEvent">
					</find-replace-right>
					<find-mt-right v-if="panel.showPanel === 3"
						ref="searchMemoryRight"
						:doc="docInfo"
						@close="hidePanelEvent">
                    </find-mt-right>
					<comment-right v-if="panel.showPanel === 4"
						ref="addCommentRight"
						:doc="docInfo"
                        :sentPermission="sentPermission"
						@close="hidePanelEvent"
						@action="executeAction">
                    </comment-right>
                    <edit-record-right v-if="panel.showPanel === 5"
						:doc="docInfo"
                        :data="panel.editRecordData"
                        :sentId="currentSentId"
                        :sentPermission="sentPermission"
						@close="hidePanelEvent"
						@action="executeAction">
                    </edit-record-right>
				</div>
			</transition>
		</div>
        <drag-dialog :title="$t('workbench.editer_page_dialog_1_title')" :top="128" :right="20" :width="340" @close="closeFilterDialog" v-if="showFilter">
            <div class="filter-main">
                <div class="option-group">
                    <div class="group-title">{{$t('workbench.editer_page_dialog_1_search_input_label')}}</div>
                    <div class="option-keyword">
                        <el-input v-model="filterOptions.keyword" :placeholder="$t('workbench.editer_page_dialog_1_search_input_placeholder')" size="small" maxlength="100" clearable></el-input>
                    </div>
                </div>
                <div class="option-group">
                    <div class="group-title">{{$t('workbench.editer_page_dialog_1_group_1_title')}}</div>
                    <el-radio-group v-model="filterOptions.confirmed">
                        <div class="option-list">
                            <div class="item">
                                <el-radio :label="''">{{$t('workbench.editer_page_dialog_1_group_1_item_1_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="false">{{$t('workbench.editer_page_dialog_1_group_1_item_2_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="true">{{$t('workbench.editer_page_dialog_1_group_1_item_3_label')}}</el-radio>
                            </div>
                        </div>
                    </el-radio-group>
                </div>
                <div class="option-group">
                    <div class="group-title">{{$t('workbench.editer_page_dialog_1_group_2_title')}}</div>
                    <el-radio-group v-model="filterOptions.locked">
                        <div class="option-list">
                            <div class="item">
                                <el-radio :label="''">{{$t('workbench.editer_page_dialog_1_group_2_item_1_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="false">{{$t('workbench.editer_page_dialog_1_group_2_item_2_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="true">{{$t('workbench.editer_page_dialog_1_group_2_item_3_label')}}</el-radio>
                            </div>
                        </div>
                    </el-radio-group>
                </div>
                <div class="option-group">
                    <div class="group-title">{{$t('workbench.editer_page_dialog_1_group_3_title')}}</div>
                    <el-radio-group v-model="filterOptions.targetTextProvider">
                        <div class="option-list">
                            <div class="item">
                                <el-radio :label="''">{{$t('workbench.editer_page_dialog_1_group_3_item_1_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="'TM'">{{$t('workbench.editer_page_dialog_1_group_3_item_2_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="'MT'">{{$t('workbench.editer_page_dialog_1_group_3_item_3_label')}}</el-radio>
                            </div>
                        </div>
                    </el-radio-group>
                </div>
                <div class="option-group">
                    <div class="group-title">{{$t('workbench.editer_page_dialog_1_group_4_title')}}</div>
                    <el-radio-group v-model="filterOptions.hasProblem">
                        <div class="option-list">
                            <div class="item">
                                <el-radio :label="''">{{$t('workbench.editer_page_dialog_1_group_4_item_1_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="false">{{$t('workbench.editer_page_dialog_1_group_4_item_2_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="true">{{$t('workbench.editer_page_dialog_1_group_4_item_3_label')}}</el-radio>
                            </div>
                        </div>
                    </el-radio-group>
                </div>
                <!-- <div class="option-group">
                    <div class="group-title">{{$t('workbench.editer_page_dialog_1_group_5_title')}}</div>
                    <el-radio-group v-model="filterOptions.hasComment" @change="filterOptionChangeEvent">
                        <div class="option-list">
                            <div class="item">
                                <el-radio :label="''">{{$t('workbench.editer_page_dialog_1_group_5_item_1_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="false">{{$t('workbench.editer_page_dialog_1_group_5_item_2_label')}}</el-radio>
                            </div>
                            <div class="item">
                                <el-radio :label="true">{{$t('workbench.editer_page_dialog_1_group_5_item_3_label')}}</el-radio>
                            </div>
                        </div>
                    </el-radio-group>
                </div> -->
                
            </div>
            <template slot="footer">
                <div class="text-center margin-t-10">
                    <el-button plain round size="mini" @click="clearFilterOptionsEvent">{{$t('workbench.editer_page_dialog_1_btn_1_label')}}</el-button>
                    <el-button type="primary" size="mini" round @click="filterOptionChangeEvent">{{$t('workbench.editer_page_dialog_1_btn_2_label')}}</el-button>
                </div>
            </template>
        </drag-dialog>
        <el-dialog :title="$t('workbench.editer_page_dialog_2_title')" :visible.sync="workflowErrorShow" destroy-on-close :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false" width="480px" top="20px">
            <div class="alert-dialog-main">
                <div class="message-content" v-html="workflowErrorMessage"></div>
                <div class="actions">
                    <el-button round plain size="small" @click="workflowErrorCancelEvent">{{$t('workbench.editer_page_dialog_2_btn_1_label')}}</el-button>
                    <el-button round type="primary" size="small" @click="workflowErrorConfirmEvent">{{$t('workbench.editer_page_dialog_2_btn_2_label')}}{{workflowTask.typeDesc}}</el-button>
                </div>
            </div>
        </el-dialog>
        <reference-engine ref="referenceEngineDom"></reference-engine>
	</div>
</template>
<script>
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import $ from "jquery";
import qs from 'querystring';
import tools from "@/utils/tools";
import config from '@/utils/config';
import moment from "moment";
import FullLoading from "@/components/FullLoading";
import DiffMatchPatch from "diff-match-patch";
import EditerTools from "./components/Tools";
import BasicEditer from "./components/BasicEditerV3";

import BasicEditerVertical from "./components/BasicEditerVertical";
import EditerRight from "./components/EditerRight";
import ReferenceEngine from './components/ReferenceEngine';
import QaRight from "./components/QARight";
import FindReplaceRight from "./components/FindReplaceRight";
import FindMtRight from "./components/FindMTRight";
import CommentRight from "./components/CommentRight";
import EditRecordRight from "./components/EditRecordRight";

import QaSetting from "@/components/QaSetting";

import DragDialog from '@/components/DragDialog';
import AlertDialog from '@/components/AlertDialog';

import { docApi, jargonApi, memoryApi, qaApi, findReplaceApi, commonApi } from "@/utils/api";

const dmp = new DiffMatchPatch(); //diff差异

export default {
	name: "Editer",
	components: {
		EditerTools,
		BasicEditer,
		BasicEditerVertical,
		EditerRight,
		QaRight,
		FindReplaceRight,
		FindMtRight,
		CommentRight,
        EditRecordRight,
		FullLoading,
		QaSetting,
        DragDialog,
        AlertDialog,
        ReferenceEngine
	},
	data() {
		return {
			canViewFileType: ["doc", "docx", "xls", "xlsx", "csv", "txt", "rtf", "srt", "ppt", "pptx", "sdlxliff", "sdlxlf", "xliff", "xlf", 'pdf' ],
			docInfo: {
                sentences: [],
            },
			previewInfo: null,
			currentSentId: "",
			loadingText: this.$t('workbench.editer_page_data_loadingText'),
			showAutoSave: null,
			sourceMode: -1,
			lastSaveTime: moment().format("YYYY-MM-DD HH:mm:ss"),
			type: null,
			jargonsLoading: false,
			jargons: [],
			translatesLoading: false,
			translates: {},
			memoriesLoading: false,
			memories: [],
			jargonsSource: null,
			translatesSource: null,
			memorySource: null,
			showRight: true,
			pagination: {
				hasNext: true,
				pageSize: 200,
				pageNumber: 1,
				total: 0,
			},
			cursortPosition: 0,
			markMode: 1, //标注模式 1=术语，2=QA
			panel: {
				showDefaultRight: true, //true
				showPanel: -1, // -1:默认，只显示辅助翻译弹窗  1:QA  2:查找替换

				// qaPanelLoading: false,
				qaData: [], //QA数据
                qaList: [], //所有QA

				frList: [],
				frLoading: false,
				replaceLoading: false,
				replaceAllLoading: false,
                canReplaceAll: false,
				showRepealReplaceAllBtn: false,

				isLocate: false, //面板定位标记
				locateData: null, //面板定位数据
				locateSentId: "", //面板定位句段ID
                editRecordsource: [], //原始编辑痕迹返回数据
                editRecordData: [],

			},
			notComfirmSent: null,

            showFilter: false,
            filterSize: 0,
            filterTimer: null,
            filterOptions: {
                keyword: '',
                confirmed: '',
                locked: '',
                targetTextProvider: '',
                hasProblem: '',
                hasComment: '',
            },
            actionLoadingkey: '',

            workflowErrorShow: false,
            workflowErrorMessage: '',
            workflowTask: {},

            editorPermissionMessage: null,
            sentPermission: false,
            editorFontSize: 'edit-font-14',
            showSpace: false,
            
		};
	},
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
            engineDist:state => state.common.engineConfig,
		}),
	},
	methods: {
		queryNotConfirm(type) {
			let url = `${docApi.queryNotConfirmSent}?docId=${this.$route.query.id}&pageSize=${this.pagination.pageSize}`;
            if(this.$route.query.taskId){
                url = `${url}&assignmentId=${this.$route.query.taskId}`;
            }
			this.$ajax.get(url).then((res) => {
                if (res.status === 200) {
                    let data = res.data;
                    if (data && data.found) {
                        this.notComfirmSent = data;
                        this.pagination.pageNumber = data.pageNumber;
                    }
                }
            }).finally(() => {
                this.initPage(type || "not");
            });
		},
        //type: prev or marge or not or repeal or null
		initPage(type, callback) {
			//重置数据防止v-html渲染缓存
            if(!(['marge', 'repeal'].indexOf(type) > -1)){
			    this.$refs.fullLoading.setShow();
                this.docInfo = {
                    ...this.docInfo ? this.docInfo:{},
                    sentences: [],
                };
            }
			let url = `${docApi.queryDocumentSentences}` +
                `?documentId=${this.$route.query.id}` +
				`&page_size=${this.pagination.pageSize}` +
				`&page_number=${this.pagination.pageNumber}`;
            if(this.$route.query.taskId){
                url = `${url}&taskId=${this.$route.query.taskId}`;
            }
            if(this.filterOptions.keyword !== ''){
                url = `${url}&keyword=${encodeURI(this.filterOptions.keyword)}`;
            }
            if (this.filterOptions.confirmed !== ''){
                url = `${url}&confirmed=${this.filterOptions.confirmed}`;
            }
            if (this.filterOptions.locked !== ''){
                url = `${url}&locked=${this.filterOptions.locked}`;
            }
            if (this.filterOptions.hasComment !== ''){
                url = `${url}&hasComment=${this.filterOptions.hasComment}`;
            }
            if (this.filterOptions.hasProblem !== ''){
                url = `${url}&hasProblem=${this.filterOptions.hasProblem}`;
            }
            if (this.filterOptions.targetTextProvider){
                url = `${url}&targetTextProvider=${this.filterOptions.targetTextProvider}`;
            }
            
			this.$ajax.get(url).then(res => {
                if (res.status === 200) {
                    let data = res.data;
                    this.pagination.total = data.sentences.total;
                    this.pagination.hasNext = data.sentences.hasNext;
                    this.docInfo = {
                        ...data,
                        ...data.document ?? {},
                        sentences: data.sentences.records.map((item) => {
                            return {
                                ...item,
                                customText: item.targetText || "",
                                qa: null,
                                participles: [],
                            };
                        }),
                    };
                    //所有qa问题句段
                    this.panel.qaList = data.problemSentences;

                    if(this.docInfo.permission && !this.docInfo.permission.editable) {
                        this.editorPermissionMessage = this.$message({
                            message: this.$t('workbench.editer_page_methods_initPage_editorPermissionMessage_message'),
                            showClose: true,
                            duration: 0,
                            type: 'warning',
                        });
                    }
                    // if (this.docInfo.canPreview) {
                        this.initDocumentPreview();
                    // }
                    this.getSourceTextWordSize();
                    //默认选中句段设置
                    let tmpSentId = '';
                    let tmpSentIndex = -1;
                    if(this.docInfo.permission.editable){
                        if (type === "not" && this.notComfirmSent) {
                            tmpSentId = this.notComfirmSent.sentence.sentId;
                            tmpSentIndex = this.notComfirmSent.indexInPage * 1;
                        }else {
                            if (type === "marge"){
                                tmpSentId = this.currentSentId; //this.docInfo.sentences[i].sentId;
                                tmpSentIndex = $(".editer-main .item[data-sentid="+tmpSentId+"]").data("docindex") * 1;
                            }else if(type === "prev"){
                                //反向遍历
                                for(let i = this.docInfo.sentences.length -1; i >= 0; i--){
                                    let sItem = this.docInfo.sentences[i];
                                    if (!sItem.locked && sItem.editorStatus !== 'confirm') {
                                        tmpSentId = sItem.sentId;
                                        tmpSentIndex = i;
                                        break;
                                    }
                                }
                            }else{
                                //正向遍历
                                for (let i = 0; i < this.docInfo.sentences.length; i++) {
                                    let sItem = this.docInfo.sentences[i];
                                    if (!sItem.locked && sItem.editorStatus !== 'confirm') {
                                        tmpSentId = sItem.sentId;
                                        tmpSentIndex = i;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    this.currentSentId = tmpSentId;
                    this.panel.locateSentId = tmpSentId;
                    if(tmpSentId && tmpSentIndex > -1){
                        this.queryRelationInfo(this.docInfo.sentences[tmpSentIndex]);
                    }
                    this.initSentPermission(this.currentSentId);
                    
                    //QA部分
                    if(this.currentSentId){
                        this.queryQaData();
                    }
                    else if(this.panel.qaList){
                        this.setSentQaFlag(this.panel.qaList);
                    }
                    
                    // 自动滚动到句段
                    this.$nextTick(()=>{
                        if(tmpSentId && tmpSentIndex > -1){
                            this.$refs.editerDom.setActiveItemIndex(tmpSentIndex);
                            let cnode = $(".editer-main");
                            if (["doc", "docx"].indexOf(this.docInfo.fileType) < 0) {
                                cnode = $(".edit .left");
                            }
                            let node = $(".editer-main .item[data-sentid="+tmpSentId+"]");
                            this.$scrollTo(node, 30, {container: cnode[0], offset: -100,});
                        }
                        callback && callback();
                    });
                }else if(res.status === 205) {
                    this.workflowTask = res.data.myTask;
                    this.workflowErrorMessage = `<span style="font-weight: 600; color: #2979FF;">${res.data.myTask.taskTypeDesc}</span>阶段尚未完成，无法进行当前阶段，是否返回<span style="font-weight: 600; color: #2979FF;">${res.data.myTask.taskTypeDesc}</span>？`;
                    this.workflowErrorShow = true;
                }
            }).finally(() => {
                this.$refs.fullLoading.setHide();
            });
		},
		initDocumentPreview() {
			let url = `${docApi.queryDocumentPreview}` +
				`?doc_id=${this.$route.query.id}` ;
				// `&type=${this.docInfo.fileType}`;
			this.$ajax.get(url).then((res) => {
                if (res.status === 200) {
                    if(res.data) this.previewInfo = JSON.parse(res.data);
                }
            }).finally(() => {

            });
		},
		updateDocumentPreview(item) {

        },
		initPrevPage() {
			this.pagination.pageNumber = this.pagination.pageNumber - 1;
			this.initPage("prev");
		},
        initNextPage(){
            this.pagination.pageNumber = this.pagination.pageNumber + 1;
			this.initPage();
        },
		pageNumChangeEvent(num) {
			this.pagination.pageNumber = num;
			this.initPage();
		},
		setCurrentSentId(item) {
			this.currentSentId = item.sentId; //预览定位ID
			let node = $('.preview-content p span[data-sentid="' + item.sentId + '"]')[0];
			this.$scrollTo(node, 30, {
				container: $(".preview-main")[0],
				offset: -300,
				x: true,
				y: true,
			});
			this.panel.locateSentId = item.sentId; //面板通过ID做定位
			if (this.showRight && this.panel.showPanel === 1) {
				let qNode = $('.qa-list .item[data-sid="' + item.sentId + '"]')[0];
				this.$scrollTo(qNode, 30, {
					container: $(".qa-list")[0],
					offset: -300,
					x: true,
					y: true,
				});
			}
		},
		initEvent() {
			let self = this;
			$(document).on("keydown", function (e) {
				if ((e.ctrlKey || e.metaKey) && e.keyCode === 83) {
					//Ctrl(Command) + S
					self.saveDocuments();
					e.preventDefault();
					return false;
				} else {
                    let start = $(".editer-main .item.active").data("docindex") * 1;
                    let index = -1;
                    let sentId = '';
					let keyCode = e.keyCode;
					switch (keyCode) {
						case 40: //下键  key = "ArrowDown"
                            if(start >= self.docInfo.sentences.length - 1){
                                if(self.pagination.hasNext){
                                    self.initNextPage();
                                }
                            }else{
                                for (let i = start + 1; i < self.docInfo.sentences.length; i++){
                                    let item = self.docInfo.sentences[i];
                                    if (!item.locked){
                                        index = i;
                                        sentId = item.sentId;
                                        console.log('item',index, sentId, item);
                                        break;
                                    }
                                }
                                if(sentId){
                                    let obj = $('.editer-main .item[data-sentid=' + sentId + ']');
                                    let docNode = obj.find(".target-doc .doc")[0];
                                    obj.click();
                                    tools.setCursorAtTheEnd(docNode);
                                }
                            }
                            
							break;
						case 38: //上键  key = "ArrowUp"
							if (start === 0) {
                                if(self.pagination.pageNumber > 1){
                                    //向上翻页
                                    self.initPrevPage();
                                }
							} else {
                                for (let i = start - 1; i >= 0; i--){
                                    let item = self.docInfo.sentences[i];
                                    if (!item.locked){
                                        index = i;
                                        sentId = item.sentId;
                                        console.log('item',index, sentId, item);
                                        break;
                                    }
                                }
                                if(sentId){
                                    let obj = $('.editer-main .item[data-sentid=' + sentId + ']');
                                    let docNode = obj.find(".target-doc .doc")[0];
                                    obj.click();
                                    tools.setCursorAtTheEnd(docNode);
                                }else {
                                    if(self.pagination.pageNumber > 1){
                                        //向上翻页
                                        self.initPrevPage();
                                    }
                                }
							}
							break;
					}
				}
			});
		},
        
		/**
		 * 保存句段
		 * @param idx 保存的句段 index
		 * @param isFocusEnd 是否把光标放到句尾
		 */
		saveSentences: debounce(function(idx, status, isFocusEnd, type) {
            // console.log('saveSentences', new Date().getTime())
			let activeNode = $(".editer-main .item.active");
			let docNode = activeNode.find(".target-doc .doc");
			let index = activeNode.data("docindex");
			let text = docNode.text();
			let html = docNode.html();
            let position = tools.getCursorPosition(docNode[0]);
            // console.log("position", position)
			if (idx !== null && idx !== undefined) {
				activeNode = $(".editer-main .item[data-docindex=" + idx + "]");
				docNode = activeNode.find(".target-doc .doc");
				text = docNode.text();
				html = docNode.html();
				index = idx;
			}
			let id = this.docInfo.sentences[index].sentId;
            let targetTextProvider = this.docInfo.sentences[index].fillMode;
			if (status === "confirm" && text === "") {
				text = this.docInfo.sentences[index].translationText || '';
				html = text;
				docNode.html(html);
			}
            text = text.replace(/\u200B/g, '').replace(/\s/g,' ');
            html = html.replace(/\u200B/g, '');
            // 修改自动保存逻辑
            if(status === 'non-confirm'){
                this.docInfo.sentences[index].editorStatus = status;
                if (isFocusEnd !== false) {
                    // tools.setCursorAtTheEnd(docNode[0]);
                }
                // return;
            }

            if(status === 'confirm'){
                this.actionLoadingkey = 'confirmSent';
            }

			let url = docApi.saveSentences;
			this.showAutoSave = true;
			this.$ajax.put(url, {
                targetTextProvider,
                targetText: text,
                targetTaggedText: this.clearTempTagFlage(html),
                editorStatus: status,
                sentId: id,
                docId: this.docInfo.docId,
                terms: this.jargons,
                submitType: type,
            }).then((res) => {
                this.docInfo.sentences[index].editorStatus = status; // 'confirm'，'non-confirm'
                this.docInfo.sentences[index].customText = text;
                this.docInfo.sentences[index].targetText = text;
                // this.docInfo.sentences[index].targetTaggedText = html;
                // this.docInfo.sentences[index].translationProvider = null;
                if (status === "confirm") {
                    if(res.data.progress !== null){
                        this.docInfo.progress = res.data.progress;
                    }
                    // this.docInfo.sentences[index].translationProvider = "MEM";
                    //重新获取QA检查及当前句段标记渲染
                    if(this.currentSentId){
                        this.queryQaData();
                    }
                    
                    //保存完后重新渲染术语标注
                    if(res.data.affectedSentences){
                        this.reuseSentencesTarget(res.data.affectedSentences);
                    }
                }

                /* if (status === "confirm" && index === this.docInfo.sentences.length - 1 && this.pagination.hasNext) {
                    this.pagination.pageNumber = this.pagination.pageNumber + 1;
                    this.initPage();
                } */
                this.lastSaveTime = moment().format("YYYY-MM-DD HH:mm:ss");

                // if (this.docInfo.canPreview) {
                    this.initDocumentPreview();
                    this.updateDocumentPreview(this.docInfo.sentences[index]);
                // }
                this.$nextTick(() => {
                    if (isFocusEnd !== false) {
                        tools.setCursorAtTheEnd(docNode[0]);
                    }
                })
            }).finally(() => {
                this.showAutoSave = false;
                this.actionLoadingkey = '';
            });
		}),
		saveDocuments() {
			let url = this.$ajax.format(docApi.saveDocuments, {id: this.docInfo.docId});
			// this.$refs.fullLoading.setShow();
            this.actionLoadingkey = 'save';
			this.$ajax.put(url, {}).then((res) => {
                if (res.status === 200) {
                    this.$message.success(this.$t('workbench.editer_page_methods_saveDocuments_success_message'));
                }
            }).finally(() => {
                // this.$refs.fullLoading.setHide();
                this.actionLoadingkey = '';
            });
		},
        /**
         * 设置相同句段复用翻译结果
         */
        reuseSentencesTarget(array){
            array.map(item => {
                let node = $(".editer-main .item[data-sentid=" + item.sentenceId + "]");
                if (node.length > 0){
                    let index = node.data('docindex') * 1;
                    if(index !== undefined){
                        this.docInfo.sentences.splice(index, 1, {...this.docInfo.sentences[index], ...item});
                    }
                }
            });
        },
		/**
		 * 获取当前进度
		 */
		getEditorProgress() {
			let list = this.docInfo.sentences.filter((item) => item.editorStatus === "confirm" && item);
			this.docInfo.editorProgress = parseFloat(((list.length / this.docInfo.sentences.length) * 100).toFixed(1));
		},
		/**
		 * 获取原文字符
		 */
		getSourceTextWordSize() {
			let size = 0;
			this.docInfo.sentences.map((item) => {
				size = size + item.sourceText.length;
			});
			this.docInfo.wordCount = size;
		},
		queryRelationJargon(item) {
            // if(this.docInfo.splitMode  === 'PARAGRAPH') return;
			if (this.jargonsSource) {
				this.jargonsSource.cancel("");
				this.jargonsSource = null;
			}
			this.jargonsLoading = true;
			let url = jargonApi.matchJargon;
			let postData = {
				source: $("<span>" + item.sourceText + "</span>").text(),
				projectId: this.docInfo.projectId,
                documentId: this.docInfo.docId,
				// sourceLang: this.docInfo.sourceLang,
			};
			this.jargonsSource = this.$ajax.axios.CancelToken.source();
			this.$ajax.axios.post(url, postData, { cancelToken: this.jargonsSource.token }).then((res) => {
                if (res && res.status === 200) {
                    this.jargons = res.data;
                    let index = $(".editer-main .item.active").data(
                        "docindex"
                    );
                    this.setTermFlag(index, item);
                }
            }).finally(() => {
                this.jargonsLoading = false;
            });
		},
		queryRelationTranslate(item) {
            if(this.docInfo.splitMode  === 'PARAGRAPH') return;
			if (this.translatesSource) {
				this.translatesSource.cancel("");
				this.translatesSource = null;
			}
			this.translatesLoading = true;
            let engine = null;
            if(this.docInfo.enableAiTrans){
                engine = {
                    engine: this.docInfo.engineInfo.icon,
                    domain: this.docInfo.engineInfo.domain,
                    engineKey: this.docInfo.engineInfo.key,
                    name: this.docInfo.engineInfo.name,
                    target: item.translationText,
                };
            }
            this.translates = {engine, reference: null};
			let url = docApi.translationSentence;
			let postData = {
				docId: this.docInfo.docId,
                sentId: item.sentId,
				domain: 'general', //this.docInfo.domain,
				engine: this.docInfo.randomEngine,
                engineKey: this.docInfo.randomEngine,
				sourceText: $("<span>" + item.sourceText + "</span>").text(),
				projectId: this.docInfo.projectId,
				sourceLang: this.docInfo.sourceLang,
				targetLang: this.docInfo.targetLang,
			};
			this.translatesSource = this.$ajax.axios.CancelToken.source();
			this.$ajax.axios.post(url, postData, {
                cancelToken: this.translatesSource.token,
            }).then((res) => {
                if (res && res.status === 200) {
                    let reference = null;
                    if(res.data.target){
                        reference = {
                            ...res.data,
                            name: this.engineDist[res.data.engine].name,
                        };
                    }
                    this.translates = {...this.translates, reference};
                }
            }).finally(() => {
                this.translatesLoading = false;
            });
		},
		queryRelationMemory(item) {
            if(this.docInfo.splitMode  === 'PARAGRAPH') return;
			if (this.memorySource) {
				this.memorySource.cancel("");
				this.memorySource = null;
			}
			this.memoriesLoading = true;
			let url = memoryApi.queryMemoriesForSource;
			let postData = {
				projectId: this.docInfo.projectId,
				source: $("<span>" + item.sourceText + "</span>").text(),
                documentId: this.docInfo.docId,
				// sourceLang: this.docInfo.sourceLang,
                memMatchingRate: this.docInfo.memMatchingRate,
			};
			this.memorySource = this.$ajax.axios.CancelToken.source();
			this.$ajax.axios.post(url, postData, { cancelToken: this.memorySource.token }).then((res) => {
                if (res && res.status === 200) {
                    let rst = [];
                    res.data.map((m) => {
                        let sourceDiff = [];
                        if (item.sourceText && m.source) {
                            sourceDiff = dmp.diff_main(
                                m.source,
                                item.sourceText
                            );
                        }
                        rst.push({
                            ...m,
                            sourceDiff: sourceDiff,
                        });
                    });
                    this.memories = rst;
                }
            }).finally(() => {
                this.memoriesLoading = false;
            });
		},      
        /**
		 * 获取编辑痕迹数据
		 */
        queryEditRecordData(item) {
            if(this.docInfo.splitMode  === 'PARAGRAPH') return;
			this.panel.editRecordData = [];
            let url = `${docApi.querySentenceEditTrace}?documentId=${this.docInfo.docId}&sentenceId=${item.sentId}`;
			this.$ajax.get(url).then((res) => {
                if (res.status === 200) {
                    if(res.data.length){
                        this.panel.editRecordData = res.data.map(item => {
                            return {
                                htmlString: item.htmlString, 
                                operationPhase: item.operationPhase,
                                operationTime: item.operationTime
                            }
                        });
                    }
                }
            }).finally(() => {
               
            });

        },

		queryRelationInfo(item) {
			this.jargons = [];
			this.translates = {};
			this.memories = [];
			this.queryRelationJargon(item);
			this.queryRelationMemory(item);
			this.queryRelationTranslate(item);
            //根据当前点击句子获取相应编辑痕迹
            if(this.panel.showPanel == 5){
                this.queryEditRecordData(item);
            }
            else if(this.panel.showPanel == 1){
                this.queryQaData(item);
            }
		},
		applyJargon(item) {
            let activeNode = $(".editer-main .item.active");
			let index = activeNode.data("docindex");
            if(this.docInfo.sentences[index].locked){
                this.$message.error(this.$t('workbench.editer_page_methods_applyJargon_error_message'));
                return;
            }
			document.execCommand("insertText", false, item.target);
		},
		applyTranslate(item) {
			let activeNode = $(".editer-main .item.active");
			let docNode = activeNode.find(".target-doc .doc");
			let index = activeNode.data("docindex");
             if(this.docInfo.sentences[index].locked){
                this.$message.error(this.$t('workbench.editer_page_methods_applyTranslate_error_message'));
                return;
            }
			docNode.html(item.target);
            this.docInfo.sentences[index].fillMode = 'MT';
			this.saveSentences(index, "non-confirm", true, 'AUTOMATIC');
		},
		applyMemory(item) {
			let activeNode = $(".editer-main .item.active");
			let docNode = activeNode.find(".target-doc .doc");
			let index = activeNode.data("docindex");
            if(this.docInfo.sentences[index].locked){
                this.$message.error(this.$t('workbench.editer_page_methods_applyMemory_error_message'));
                return;
            }
			docNode.html(item.target);
            this.docInfo.sentences[index].fillMode = 'TM';
			this.saveSentences(index, "non-confirm", true, 'AUTOMATIC');
		},
		/**
		 * 重译
		 */
		reTranslate(params) {
			let url = `${jargonApi.reTranslate}`;
			this.$refs.fullLoading.setShow();
			this.loadingText = this.$t('workbench.editer_page_methods_reTranslate_loadingText');
			this.$ajax.post(url, {
					docId: this.docInfo.docId,
					termDetailId: params.termDetailId,
				}).then((res) => {
					if (res.status === 200) {
						let data = res.data;
						if (data.length > 0) {
							this.$message.success(this.$t('workbench.editer_page_methods_reTranslate_success_message_1', {size: data.length}));
						} else {
							this.$message.success(this.$t('workbench.editer_page_methods_reTranslate_success_message_2'));
						}
						this.queryNotConfirm();
					}
				}).finally(() => {
					this.$refs.fullLoading.setHide();
				});
		},
		/**
		 * 设置输入框失去焦点时的光标位置
		 */
		setCursortPosition(position) {
			this.cursortPosition = position;
		},
		/**
		 * 显示QA面板
		 */
		showQAPanelEvent() {
			if (this.panel.showPanel !== 1) {
				this.showPanelEvent(1);
                if(this.currentSentId) {
                    this.queryQaData();
                }
			}
		},
		showFindReplacePanelEvent() {
			this.showPanelEvent(2);
		},
		showPanelEvent(type) {
			this.panel.showPanel = type;
			this.panel.showDefaultRight = false;
			if (!this.showRight) {
				this.showRight = true;
			}
		},
		/**
		 * 隐藏面板
		 */
		hidePanelEvent() {
			this.panel.showPanel = -1;
			this.panel.showDefaultRight = true;
		},

		/**
		 * 获取QA数据
		 */
		queryQaData(item) {
			//忽略的时候传sid
            if(!item){
                if(this.currentSentId){
                    let url = `${qaApi.triggerQA}?documentId=${this.docInfo.docId}&sentenceId=${this.currentSentId}`;
                // this.panel.qaPanelLoading = true;
                this.$ajax.get(url).then((res) => {
                    if (res.status === 200) {
                        this.panel.qaData = res.data.list.map(item => {
                            return {
                                ...item,
                                problemText: 
                                    item.template
                                        .replaceAll('{{abnormalText}}', `<span style="color: orange;">${item.abnormalText}</span>`)
                                            .replaceAll('{{suggestedText}}', `<span style="color: #05c212;">${item.suggestedText}</span>`),
                            }
                        });
                        this.$nextTick(() => {
                            this.panel.qaList = res.data.problemSentences;
                            this.setSentQaFlag(this.panel.qaList);
                        })
                    }
                }).finally(() => {
                    // this.panel.qaPanelLoading = false;
                });
                }    
            }
            else{
                let url = `${qaApi.triggerQA}?documentId=${this.docInfo.docId}&sentenceId=${item.sentId}`;
                // this.panel.qaPanelLoading = true;
                this.$ajax.get(url).then((res) => {
                    if (res.status === 200) {
                        this.panel.qaData = res.data.list.map(item => {
                            return {
                                ...item,
                                problemText: 
                                    item.template
                                        .replaceAll('{{abnormalText}}', `<span style="color: orange;">${item.abnormalText}</span>`)
                                            .replaceAll('{{suggestedText}}', `<span style="color: #05c212;">${item.suggestedText}</span>`),
                            }
                        });
                        this.$nextTick(() => {
                            this.panel.qaList = res.data.problemSentences;
                            this.setSentQaFlag(this.panel.qaList);
                        })
                    }
                }).finally(() => {
                    // this.panel.qaPanelLoading = false;
                });
            }
	
		},
        /**
         * 设置单句QA标注
         */
		setSentQaFlag(problems) {
			//sid只在忽略的时候传入
            const qaSentIds = new Set(problems);
            // problems.map(item => {
            //     qaSentIds.add(item.sentenceId);
            // });
			let sentenceList = this.docInfo.sentences;
            for(let i = 0; i < sentenceList.length; i++){
                this.docInfo.sentences[i].qa = "";
            }
            this.$nextTick(()=>{
                qaSentIds.forEach((id) => {
                    for (let i = 0; i < sentenceList.length; i++) {
                        let item = sentenceList[i];
                        if (id === item.sentId) {
                            this.docInfo.sentences[i].qa = "warn";
                            break;
                        }
                    }
                });
            });
		},
		/**
		 * 忽略QA
		 */
		ignoreQA(item) {
			let url = qaApi.ignoreQAProblem;
			this.$ajax.post(url, {
                problemIdList: [item.problemId],
                documentId: this.docInfo.docId,
            }).then((res) => {
                if (res.status === 200) {
                    this.queryQaData();
                }
            });
		},
		/**
		 * 设置QA定位句段
		 */
		setQALocate(item, type) {
            // console.log(item);
			let node = $(".editer-main .item[data-sentid=" + item.sentenceId + "]");
			let cnode = $(".editer-main");
			this.panel.locateSentId = item.sentenceId;
			this.currentSentId = item.sentenceId;
			if (node.length > 0) {
				node.click();
				this.$scrollTo(node[0], 30, {
					container: cnode[0],
					offset: -100,
				});
                if (type === 'ignore') {
                    return;
                }
				let index = node.data("docindex") * 1;
				if (!item.source) {
					this.setQAProblemFlag(index, item);
				}
			} else {
                let postData = {
					documentId: this.$route.query.id,
					sentenceId: item.sentenceId,
					excludePageNo: this.pagination.pageNumber,
					pageSize: this.pagination.pageSize,
				}; 
				let url = `${docApi.getSentencesPageNumber}?${qs.stringify(postData)}`;
				this.$ajax.get(url).then((res) => {
					if (res.status === 200) {
                        console.log('getSentencesPageNumber:::', res.data);
                        if(res.data.found){
                            this.pagination.pageNumber = res.data.pageNumber;
                            this.initPage('', ()=> {
                                this.setQALocate(item, type);
                            });
                        }
					}
				});
			}
		},
		/**
		 * 设置QA问题标注
		 */
		setQAProblemFlag(index, item) {
			let node = $(".editer-main .item[data-sentid=" + item.sentenceId + "]");
            this.clearQAProblemFlag(node);
			let rst = node.find(".target-doc .doc").html().replaceAll('&nbsp;',' ');
			if (item.start !== null && item.end !== null) {
				let postData = {
					start: item.start,
					end: item.end,
					openingTag: `<qaf class="warn">`,
					closingTag: "</qaf>",
					text: rst,
				};
				let url = docApi.querySentencesTagsQA;
				this.$ajax.post(url, postData).then((res) => {
					if (res.status === 200) {
						node.find(".target-doc .doc").html(res.data);
					}
				});
			}
		},
		clearQAProblemFlag(node) {
            try {
                let html = node.html().replace(/<qaf( [^>]*)?>/g, "").replace(/<\/qaf>/g, "");
                node.html(html);
            }catch (e) {
                // console.log('[INFO]', e)
            }
		},
		/**清除临时追加tag，比如qa位置标记tag<qaf> 、术语tag<termf>*/
		clearTempTagFlage(html) {
            // let node = $(`<span>${html}</span>`);
            // node.find('span[zerowidthspace="true"]').remove();
			return html
                .replaceAll('&ZeroWidthSpace;', '').replace(/<span zerowidthspace="true">(.*?)<\/span>/g, '$1')
                    .replace(/<qaf( [^>]*)?>/g, "")
                        .replace(/<\/qaf>/g, "") //qa标记tag
                            .replace(/<termf>/g, "")
                                .replace(/<\/termf>/g, ""); //术语标记tag
		},
		/**
		 * 查找替换接口
		 */
		queryFindReplaceData(params) {
			let url = `${findReplaceApi.findAll}?documentId=${this.docInfo.docId}&caseSensitive=${params.caseSensitive}&findFromSource=true&findFromTarget=true`;
            url = `${url}&findContent=${encodeURI(params.searchInputValue)}&pageSize=${this.pagination.pageSize}`
			/* let postData = {
				documentId: this.docInfo.docId,
				caseSensitive: params.caseSensitive,
				findFromSource: true,
				findFromTarget: true,
				findContent: params.searchInputValue,
				pageSize: this.pagination.pageSize,
			}; */
			this.panel.frLoading = true;
			this.$ajax.get(url).then((res) => {
                if (res.status === 200) {
                    this.panel.frList = res.data.results;
                    this.panel.canReplaceAll = res.data.allowReplaceRows > 0;
                }
            }).finally(() => {
                this.panel.frLoading = false;
            });
		},
		clearFindReplaceData() {
			this.panel.frList = [];
		},
		/**
		 * 单个替换
		 */
		replaceData(params) {
			let url = findReplaceApi.replaceOne;
			let postData = {
				documentId: this.docInfo.docId,
				sentenceId: params.sentenceId,
				caseSensitive: !params.caseSensitive,
				findContent: params.searchInputValue,
				replaceContent: params.replaceInputValue,
				startIndex: params.startIndex,
				endIndex: params.endIndex,
				pageSize: this.pagination.pageSize,
			};
			this.panel.replaceLoading = true;
			this.$ajax.post(url, postData).then((res) => {
                if (res.status === 200) {
                    let node = $(".editer-main .item[data-sentid=" + params.sentenceId + "]");
                    if (node.length > 0) {
                        let index = node.data("docindex") * 1;
                        let afterText = res.data.sentence.afterReplace;
                        if (!isNaN(index)) {
                            this.docInfo.sentences[index].targetText = afterText;
                            this.docInfo.sentences[index].targetTaggedText = afterText;
                            this.docInfo.sentences[index].targetTextProvider = null;
                        }
                    }
                    this.panel.frList[params.index].target.splice(params.tIndex, 1, {
                        text: params.replaceInputValue,
                        active: false,
                        start: params.startIndex,
                        end: params.endIndex,
                    });
                    // if (this.docInfo.canPreview) {
                        this.initDocumentPreview();
                    // }
                }
            }).finally(() => {
                this.panel.replaceLoading = false;
            });
		},
		/**
		 * 全部替换
		 */
		replaceAllData(params) {
			let url = findReplaceApi.replaceAll;
			let postData = {
				documentId: this.docInfo.docId,
				caseSensitive: params.caseSensitive,
				findContent: params.searchInputValue,
				replaceContent: params.replaceInputValue,
				pageSize: this.pagination.pageSize,
			};
			this.panel.replaceAllLoading = true;
			this.$ajax.post(url, postData).then((res) => {
                if (res.status === 200) {
                    this.panel.frList = [];
                    this.panel.showRepealReplaceAllBtn = true;
                    this.queryNotConfirm();
                }
            }).finally(() => {
                this.panel.replaceAllLoading = false;
            });
		},
		/**
		 * 撤销全部替换
		 */
		repealReplaceAllEvent() {
			this.repealOrRecoverBtnEvent("repeal");
			setTimeout(() => {
				this.panel.showRepealReplaceAllBtn = false;
			}, 1000);
		},
		/**
		 * 查找替换定位
		 */
		setFindReplaceLocate(params) {
			this.setQALocate(params);
		},
		/**设置术语标记 */
		setTermFlag(index, item) {
			let self = this;
			let node = $(".editer-main .item.active .original-doc");
			let _sourceText = node.html().replaceAll(/<termf>/g, "").replaceAll(/<\/termf>/g, "");
			let _targetText = $("<span>" + item.customText + "</span>").text();
			this.jargons.map((item) => {
				_sourceText = _sourceText.replaceAll(item.source, "<termf>" + item.source + "</termf>");
				_targetText = _targetText.replaceAll(item.target, "<termf>" + item.target + "</termf>");
			});
			let docNode = $(".editer-main .item.active");
			docNode.find(".original-doc").html(_sourceText);
			setTimeout(() => {
				tools.setCursorAtTheEnd(docNode.find(".target-doc .doc")[0]);
			});
		},
		clearTermFlag(index, item) {
			this.docInfo.sentences.splice(index, 1, {
				...item,
				sourceText: $("<span>" + item.sourceText + "</span>").text(),
				targetText: $("<span>" + item.targetText + "</span>").text(),
			});
		},
		/**鼠标失去焦点清理当前句段已标记的状态的状态 */
		clearFlagStatus(e, index, item) {
            // console.log("yes", $(e.target), e.target);
			this.clearQAProblemFlag($(e.target));
		},
		/**撤销和恢复按钮事件 */
		repealOrRecoverBtnEvent(type) {
			let url = docApi.cancelLastStep;
            this.actionLoadingkey = 'repeal';
			if (type === "recover") {
				url = docApi.recoverStep;
                this.actionLoadingkey = 'recover';
			}
			this.$ajax.post(url, {
                module: "EDITOR",
                documentId: this.docInfo.docId,
            }).then((res) => {
                if (res.status === 200) {
                    let details = res.data.affectedSentences || [];
                    if(res.data.progress) {
                        this.docInfo.progress = res.data.progress;
                    }
                    this.queryNotConfirm('repeal');
                    /* details.map((item) => {
                        let node = $(".editer-main .item[data-sentid=" + item.sentId + "]");
                        let index = node.data("docindex") * 1;
                        if (!isNaN(index)) {
                            this.docInfo.sentences.splice(index, 1, {...item, targetTaggedText: item.targetTaggedText || '<span></span>', customText: item.targetText || ''});
                        }
                    }); */
                    this.$forceUpdate();
                    let msg = type === "repeal" ? this.$t('workbench.editer_page_methods_repealOrRecoverBtnEvent_success_message_1') : type === "repeal" ? this.$t('workbench.editer_page_methods_repealOrRecoverBtnEvent_success_message_2') : "";
                    if (msg) {
                        this.$message.success(msg);
                    }
                }
            }).finally(()=>{
                this.actionLoadingkey = '';
            });
		},
		/**
		 * 设置句段锁状态
		 */
		setSentencesLockStatus(index, locked) {
			let data = this.docInfo.sentences[index];
			let postData = {
                docId: this.docInfo.docId,
				sentId: data.sentId,
				locked: locked,
			};
			let url = docApi.setSentencesLock;
			this.$ajax.post(url, postData).then((res) => {
				if (res.status === 200) {
					this.docInfo.sentences[index].locked = locked;
					let node = $(".editer-main .item[data-sentid=" + data.sentId + "]");
                    this.$nextTick(() => {
                        if (locked) {
                            let _index = index + 1;
                            while(true){
                                if (_index > (this.docInfo.sentences.length - 1)){
                                    this.$refs.editerDom.setActiveItemIndex(-1);
                                    this.currentSentId = null;
                                    this.panel.locateSentId = null;
                                    break;
                                }
                                let item = this.docInfo.sentences[_index];
                                if(!item.locked && item.editorStatus !== 'confirm'){
                                    $(".editer-main .item[data-sentid=" + item.sentId + "]").click();
                                    break;
                                }
                                _index ++;
                            }
							// node.next().click();
						} else {
							node.click();
						}
                    })
				}
			});
		},
		/**
		 * 设置句段样式
		 */
		setSentencesStyle(key) {
            let types = ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf"];
			if (types.indexOf(this.docInfo.fileType) < 0) {
				this.$message.error(this.$t('workbench.editer_page_methods_setSentencesStyle_error_message', {types: types.join('，')}));
				return;
			}
			document.execCommand(key, false, null);
			// this.docInfo.sentences[index].targetSegments = data;
		},
		setSplitMode() {
			this.sourceMode = 1;
			this.$message.info(this.$t('workbench.editer_page_methods_setSplitMode_info_message'));
			setTimeout(() => {
				$(".editer-main .item.active .split-content .left-input").focus();
			}, 100);
		},
		submitSplit(param) {
			let pos = window.getSelection();

			let left = param.sourceText.substring(0, pos.anchorOffset);
			let right = param.sourceText.substring(
				pos.anchorOffset,
				param.sourceText.length
			);
			if (left.length === 0 || right.length === 0) {
				this.$message.error(this.$t('workbench.editer_page_methods_submitSplit_error_message'));
				return;
			}
            this.actionLoadingkey = 'split';
			let url = docApi.splittingSentences;
			this.$ajax.post(url, {
                docId: this.docInfo.docId,
                sentId: param.sentId,
                engine: this.docInfo.engine,
                domain: this.docInfo.domain,
                sourceLang: this.docInfo.sourceLang,
                targetLang: this.docInfo.targetLang,
                sentences: [{ sourceText: left }, { sourceText: right }],
            }).then((res) => {
                if (res.status === 200) {
                    this.$message.success(this.$t('workbench.editer_page_methods_submitSplit_success_message'));
                    this.sourceMode = -1;
                    this.queryNotConfirm();
                }
            }).finally(() => {
                this.actionLoadingkey = '';
            });
		},
		submitMarge() {
			let currentId = $(".editer-main .item.active").data("sentid");
			let currentIndex = $(".editer-main .item.active").data("docindex");
			
            if(currentIndex === this.docInfo.sentences.length - 1){
                this.$message.error(this.$t('workbench.editer_page_methods_submitMarge_error_message'));
                return ;
            }
            let nextIndex = currentIndex * 1 + 1;
            let doc = this.docInfo.sentences[nextIndex];
			let nextId = doc.sentId;
            this.actionLoadingkey = 'merge';
			let url = docApi.mergingSentences;
			this.$ajax.post(url, {
                docId: this.docInfo.docId,
                sentIds: [currentId, nextId],
                engine: this.docInfo.engine,
                domain: this.docInfo.domain,
                sourceLang: this.docInfo.sourceLang,
                targetLang: this.docInfo.targetLang,
                sentences: [{
                    sourceText: this.docInfo.sentences[currentIndex].sourceText + doc.sourceText,
                }],
            }).then((res) => {
                if (res.status === 200) {
                    this.$message.success(this.$t('workbench.editer_page_methods_submitMarge_success_message'));
                    // this.initPage("marge");
                    this.queryNotConfirm();
                }
            }).finally(() => {
                this.actionLoadingkey = '';
            });
		},
		saveEditSource(param) {
			let node = $(param.e.target).parents(".edit-source-content").find(".left-input");
			let text = node.text().replace(/\s+/g, " ");
			let html = node.html();
            this.actionLoadingkey = 'editSource';
			let url = docApi.saveSourceSentences;
			this.$ajax.put(url, {
                docId: this.docInfo.docId,
                sentId: param.item.sentId,
                sourceText: text,
                sourceTaggedText: html,
            }).then((res) => {
                if (res.status === 200) {
                    this.$message.success(this.$t('workbench.editer_page_methods_saveEditSource_success_message'));
                    this.docInfo.sentences[param.index].sourceText = text;
                    this.docInfo.sentences[param.index].sourceTaggedText = html;
                    this.sourceMode = -1;
                }
            }).finally(() => {
                this.actionLoadingkey = '';
            });
		},
		copySourceToTarget() {
			let node = $(".editer-main .item.active");
			let index = node.data("docindex") * 1;
			let sentData = this.docInfo.sentences[index];
			// this.docInfo.sentences.splice(index,1,{...sentData, targetText:sentData.sourceText, targetTaggedText:sentData.sourceTaggedText})
			this.docInfo.sentences[index].targetText = sentData.sourceText;
			this.docInfo.sentences[index].targetTaggedText = sentData.sourceTaggedText;
            this.docInfo.sentences[index].targetTextProvider = null;
            this.$nextTick(() =>{
                tools.tagPositionChecking(node.find('.target-doc .doc'));
                this.saveSentences(index, "non-confirm", true, 'AUTOMATIC');
            })
		},
		/**设置编辑器编辑模式 */
		setEditorMode(mode) {
			let url = docApi.setEditorMode;
			let postData = {
				documentId: this.docInfo.docId,
				interactiveMode: mode,
			};
			this.$ajax.post(url, postData).then((res) => {
				if (res.status === 200) {
					location.reload(true);
				}
			});
		},
        /** QA应用建议 */
        qaApplyReferenceEvent(param){
            let self = this;
            if(this.docInfo.permission.editable) {
                this.$message.error(this.$t('workbench.editer_page_methods_qaApplyReferenceEvent_error_message'));
                return;
            }
            let node = $('.editer-main .item[data-sentid='+param.sentenceId+']');
			let index = node.data("docindex") * 1;
			let sentData = this.docInfo.sentences[index];
            if(param.referenceText.indexOf('在后方插入')>-1){
                return false;
            }
            let rst = sentData.targetText.substring(0,param.startIndex) + param.referenceText + sentData.targetText.substring(param.endIndex);
            this.docInfo.sentences[index].targetTaggedText = rst;
            this.docInfo.sentences[index].targetText = rst;
            this.docInfo.sentences[index].targetTextProvider = null;
            this.$nextTick(() => {
                this.saveSentences(index, 'confirm', false, 'AUTOMATIC');
            });
        },
        /** 关闭筛选弹窗 */
        closeFilterDialog(){
            this.showFilter = false;
        },
        filterOptionChangeEvent(type){
            let self = this;
            if(type === 'keyword'){
                if(this.filterTimer){
                    clearTimeout(this.filterTimer);
                }
                this.filterTimer = setTimeout(() =>{
                    self.initPage();
                }, 500);
            }else{
                this.initPage();
            }
            this.getFilterSize();
            
        },
        clearFilterOptionsEvent(){
            this.filterOptions = {
                keyword: '',
                confirmed: '',
                locked: '',
                targetTextProvider: '',
                hasProblem: '',
                hasComment: '',
            }
            this.getFilterSize();
            this.initPage();
        },
        getFilterSize(){
            let size = 0;
            let keys = Object.keys(this.filterOptions);
            keys.map(key =>{
                if(this.filterOptions[key] !== ''){
                    size = size + 1;
                }
            });
            this.filterSize = size;
        },
        startFillTags(){
            let url = this.$ajax.format(docApi.fillTags, {id: this.docInfo.docId});
            this.$refs.fullLoading.setShow();
            this.loadingText = this.$t('workbench.editer_page_methods_startFillTags_loadingText_1');
            this.$ajax.post(url, {}).then(async res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('workbench.editer_page_methods_startFillTags_success_message', {size: res.data}));
                    await tools.sleep(1000);
                    this.$router.go(0);
                }
            }).catch(e=>{
                this.loadingText = this.$t('workbench.editer_page_data_loadingText');
                this.$refs.fullLoading.setHide();
            }).finally(()=>{
                this.loadingText = this.$t('workbench.editer_page_data_loadingText');
            });
        },
        startFillSentenceTags() {
            let node = $(".editer-main .item.active");
            let text = node.find('.target-doc .doc').text();
			let index = node.data("docindex") * 1;
			let sentData = this.docInfo.sentences[index];
            if(text.trim().length === 0) {
                this.$message.error(this.$t('workbench.editer_page_methods_startFillSentenceTags_error_message'));
                return;
            }
            let url = `${docApi.fillSentenceTags}?sentId=${sentData.sentId}`;
            this.actionLoadingkey = 'copySourceTagToTarget';
            if(!this.copySourceTagMsg) {
                this.copySourceTagMsg = this.$message({
                    dangerouslyUseHTMLString: true,
                    message: `&nbsp;&nbsp;${this.$t('workbench.editer_page_methods_startFillSentenceTags_info_message')}`,
                    duration: 0,
                    iconClass: 'el-icon-loading',
                });
            }
            this.$ajax.post(url, {}).then(async res => {
                if(res.status === 200){
                    this.docInfo.sentences[index].targetTaggedText = res.data || '';
                    this.docInfo.sentences[index].targetTextProvider = null;
                    this.$nextTick(() =>{
                        tools.tagPositionChecking(node.find('.target-doc .doc'));
                        this.saveSentences(index, sentData.editorStatus, true, 'AUTOMATIC');
                    });
                }
            }).finally(() => {
                this.actionLoadingkey = '';
                this.copySourceTagMsg.close();
                this.copySourceTagMsg = null;
            });
			
        },
        setTaskStatus(){
            let url = docApi.setTaskStatus;
            this.$refs.fullLoading.setShow();
            this.loadingText = this.$t('workbench.editer_page_methods_setTaskStatus_loadingText');
            this.$ajax.post(url, {assignmentId: this.docInfo.myTask.assignmentId, documentId: this.docInfo.docId}).then(async res => {
                if(res.status === 200) {
                    this.$message.success(this.$t('workbench.editer_page_methods_setTaskStatus_success_message'));
                    await tools.sleep(1000);
                    this.$router.go(0);
                }
            }).finally(() => {
                this.loadingText = this.$t('workbench.editer_page_data_loadingText');
                this.$refs.fullLoading.setHide();
            })
        },
        quickSubmit(){
            this.$refs.fullLoading.setShow();
            this.loadingText = this.$t('workbench.editer_page_methods_quickSubmit_loadingText');
            this.$ajax.post(docApi.quickSubmit, {documentId: this.docInfo.docId}).then(async res => {
                if(res.status === 200) {
                    this.$message.success(this.$t('workbench.editer_page_methods_quickSubmit_success_message'));
                    await tools.sleep(1000);
                    this.$router.go(0);
                }
            }).finally(() => {
                this.loadingText = this.$t('workbench.editer_page_data_loadingText');
                this.$refs.fullLoading.setHide();
            })
        },

        workflowErrorCancelEvent () {
            this.workflowErrorShow = false;
            this.$nextTick(() => {
                this.$router.go(-1);
            });
        },
        workflowErrorConfirmEvent () {
            this.workflowErrorShow = false;
            this.$nextTick(() => {
                location.replace(`/workbench/editer?id=${this.$route.query.id}&taskId=${this.workflowTask.taskId}`);
            });
        },
        async downloadStyleGuide() {
            try {
                const checkRes = await this.$ajax.get(`${docApi.checkStyleGuideFiles}?projectId=${this.docInfo.projectId}`);
                let url = commonApi.batchCreateDownloadTask;
                this.$ajax.post(url, {
                    title: `${this.docInfo.originalFilename}${this.$t('workbench.editer_page_methods_downloadStyleGuide_file_title')}`,
                    metadataIdList: [this.docInfo.projectId],
                    metadataType:'TMS_PROJECT_STYLE_GUIDES',
                }).then(res=>{
                    if(res.status === 200){
                        this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                    }
                });
            }catch (e) {

            }
        },

        /** 动作分发器 */
		executeAction(key, param) {
			let self = this;
			switch (key) {
				case "splitSource":
					this.setSplitMode();
					break;
				case "submitSplit":
					this.submitSplit(param);
					break;
				case "exitSplitMode":
					this.sourceMode = -1;
					break;
				case "submitMarge":
					this.submitMarge();
					break;
				case "qaSetting":
					this.$refs.qaSetting.setShow();
					break;
                case "qaApplyReference":
                    this.qaApplyReferenceEvent(param);
                    break;
				case "editSource":
                    if($(".editer-content .item.active").length === 0){
                        this.$message.error(this.$t('workbench.editer_page_methods_executeAction_message_1'));
                        return;
                    }
					this.sourceMode = 2;
					this.$message.info(this.$t('workbench.editer_page_methods_executeAction_message_2'));
                    this.$nextTick(()=>{
                        $(".editer-main .item.active .edit-source-content .left-input").focus();
                    });
					break;
				case "exitEditSource":
					this.sourceMode = -1;
					break;
				case "saveEditSource":
					this.saveEditSource(param);
					break;
				case "copySourceToTarget":
					this.copySourceToTarget();
					break;
				case "searchTMDb":
					this.showPanelEvent(3);
					break;
				case "searchMemory":
					this.showPanelEvent(3);
					setTimeout(() => {
						self.$refs.searchMemoryRight.setKeyword(param);
						self.$refs.searchMemoryRight.queryResult();
					}, 10);
					break;
				case "setRightShow":
					this.showRight = !this.showRight;
					break;
				case "addComment":
                    if (this.panel.showPanel != 4) {
					this.showPanelEvent(4);
                    }
                    setTimeout(() => {
							self.$refs.addCommentRight.initList(param);
						}, 100);
					break;
				case "loadComment":
					if (this.panel.showPanel === 4) {
						setTimeout(() => {
							self.$refs.addCommentRight.initList(param);
						}, 100);
					}
					break;
                case "editRecordShow":
                    this.showPanelEvent(5);
                    break;
				case "updateSentCommentCount":
					let index = $(`.editer-content .item[data-sentid="${param.id}"]`).data("docindex") * 1;
					this.docInfo.sentences[index].commentCount = param.count;
					break;
				case "changeEditorMode":
					this.docInfo.interactiveMode = param;
					this.setEditorMode(param);
					break;
                case 'showFilter':
                    this.showFilter = true;
                    break;
                case 'updateTargetTextProvider':
                    this.docInfo.sentences[param.index].fillMode = param.value;
                    break;
                case 'lock':
                    let index1 = $(".editer-content .item.active").data("docindex") * 1;
                    this.setSentencesLockStatus(index1, true);
                    break;
                case 'fillTag':
                    if (['personal', 'pro-limited'].indexOf(this.userInfo.accountType) > -1){
                        this.$message({
                            // showClose: true,
                            type: 'warning',
                            dangerouslyUseHTMLString: true,
                            message: `${this.$t('workbench.editer_page_methods_executeAction_message_3')}<a href="/account/vip">${this.$t('workbench.editer_page_methods_executeAction_message_3_1')}</a>`,
                        });
                        return false;
                    }
                    if (["csv", "txt", "srt"].indexOf(this.docInfo.fileType) > -1){
                        this.$message.error(this.$t('workbench.editer_page_methods_executeAction_message_4'));
                        return false;
                    }
                    this.startFillTags();
                    break;
                case 'copySourceTagToTarget':
                    this.startFillSentenceTags();
                    break;
                case 'quickSubmit':
                    this.quickSubmit();
                    break;
                case 'submitTask':
                    this.setTaskStatus();
                    break;
                case 'downloadStyleGuide':
                    this.downloadStyleGuide();
                    break;
                case 'showReferenceEngine':
                    // 显示参考引擎
                    this.$refs.referenceEngineDom.setShow(this.docInfo);
                    break;
                case 'setEditorFontSize':
                    console.log(param);
                    this.editorFontSize = param;
                    break;
                case 'showSpace':
                    this.showSpace = !this.showSpace;
                    break;
			}
		},
        /**浏览器关闭刷新事件 */
        handleBeforeunload(event){
            if(this.showAutoSave) {
                event.preventDefault();
                event.returnValue = '';
                return '';
            }
        },
        /**初始化句段权限 */
        async initSentPermission(sentId) { 
            let permission = false;
            if (sentId) {
                await tools.sleep(100);
                let node = $('.editer-main .item[data-sentid='+sentId+']');
			    let index = node.data("docindex") * 1;
                if (node.length > 0) {
                    let item = this.docInfo.sentences[index];
                    if (item.permission.editable) {
                        permission = true;
                    }
                }
                if(permission) {
                    this.unbindEditerTagEvent(node);
                    this.bindEditerTagEvent(node);
                }
            }
            this.sentPermission = permission;
        },
        bindEditerTagEvent(parentNode) {
            let editer = $('.target-doc .doc[contenteditable="true"]');
            if (parentNode) {
                editer = parentNode.find('.target-doc .doc[contenteditable="true"]');
            }
            // 处理按下删除键或后退键时的逻辑
            editer.on('keydown', async function(e) {
                // console.log('[INFO]', "keydown", e.key);
                if (e.key === 'Backspace' || e.key === 'Delete') {
                    const selection = window.getSelection();
                    if (!selection.isCollapsed) {
                        return;
                    }
                    const currentNode = selection.anchorNode;
                    const offset = selection.anchorOffset;

                    let checkNode = null;
                    let direction = e.key === 'Backspace' ? 'backward' : 'forward';

                    if (direction === 'backward') {
                        if (currentNode.nodeType === Node.TEXT_NODE && offset === 0) {
                            checkNode = currentNode.previousSibling;
                        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                            checkNode = currentNode;
                        }
                    } else { // 'forward'
                        if (currentNode.nodeType === Node.TEXT_NODE && offset === currentNode.length) {
                            checkNode = currentNode.nextSibling;
                        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                            checkNode = currentNode;
                        }
                    }
                    if (checkNode && checkNode.nodeType === Node.ELEMENT_NODE && checkNode.getAttribute('tag') === 'true') {
                        let range = document.createRange();
                        range.setStartBefore(checkNode);
                        range.setEndAfter(checkNode);

                        let content = range.toString();
                        if (content === '') {
                            $(checkNode).remove();
                            // e.preventDefault();
                        }
                    }else {
                        if ($(checkNode).hasClass('doc')) {
                            await tools.sleep(10)
                            let tagNodes = checkNode.querySelectorAll('div[tag="true"]');
                            tagNodes.forEach(node => {
                                if(node.children.length === 0) {
                                    $(node).remove();
                                    // e.preventDefault();
                                }
                            })
                        }
                    }
                }
            });

            // 处理键盘箭头移动光标时的逻辑
            editer.on('keyup', function(e) {
                console.log('[INFO]', "keyup", e.key);
                const selection = window.getSelection();
                if (!selection.rangeCount) return;
                let range = document.createRange();
                let targetNode = selection.anchorNode;
                if(targetNode.nodeName === 'SPAN'){
                    targetNode = targetNode.parentNode;
                }
                if(['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].indexOf(e.key) > -1){
                    let moveForward = ['ArrowUp', 'ArrowRight'].indexOf(e.key) > -1;
                    let moveBackward = ['ArrowDown', 'ArrowLeft'].indexOf(e.key) > -1;
                    if (targetNode.nodeType === Node.ELEMENT_NODE && $(targetNode).attr('tag') === 'true') {
                        if (moveForward) {
                            range.setStartAfter(targetNode);
                        } else if (moveBackward) {
                            range.setStartBefore(targetNode);
                        }
                        range.collapse(false);
                        selection.removeAllRanges();
                        selection.addRange(range);
                        e.preventDefault();
                    }
                }
            });

            // 处理点击标签时移动光标的逻辑
            editer.on('click', function(event) {
                var selection = window.getSelection();
                var range = document.createRange();
                var targetNode = event.target;
                if(targetNode.nodeName === 'SPAN'){
                    targetNode = targetNode.parentNode;
                }
                if ($(targetNode).attr('tag') === 'true') {
                    if ($(targetNode).attr('location') === 'start'){
                        range.setStartAfter(targetNode);
                    }else if($(targetNode).attr('location') === 'end'){
                        range.setStartBefore(targetNode);
                    }
                    range.collapse(false);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    event.preventDefault();
                }
            });

            // 处理拖拽逻辑
            var isDragging = false;
            var draggedElement = null;
            var lastKnownPosition = null;

            editer.on('mousedown', function(e) {
                if(isWithinTagTrue($(e.target))){
                    isDragging = true;
                    draggedElement = e.taget;
                    if(!$(e.target).is('div[tag="true"]')){
                        draggedElement = $(e.target).parents('div[tag="true"]')[0];
                    }
                    e.preventDefault();
                }else {
                    const newRange = document.caretRangeFromPoint(e.clientX, e.clientY);
                    if (newRange) {
                        const selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(newRange);
                    }
                }
            });

            editer.on('mousemove', function(e) {
                if (isDragging && draggedElement) {
                    const newRange = document.caretRangeFromPoint(e.clientX, e.clientY);
                    if (newRange) {
                        lastKnownPosition = newRange;
                        const selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(newRange);
                    }
                }
            });

            editer.on('mouseup', function(e) {
                if (isDragging) {
                    isDragging = false;
                    if (lastKnownPosition && !isWithinTagTrue($(e.target))) {
                        const selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(lastKnownPosition);

                        const range = selection.getRangeAt(0);
                        let rangeNode = range.commonAncestorContainer;
                        if($(rangeNode).is('div[tag="true"]')) {
                            console.log('[INFO]', "tag被拖拽入了其他tag标签上，移动不生效", rangeNode);
                            return;
                        }

                        range.insertNode(draggedElement);
                        range.collapse(false);

                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                    setTimeout(() => {
                        tools.tagPositionChecking(editer);
                    }, 50)
                    draggedElement = null;
                }
            });

            // 功能函数来检查元素是否位于具有tag="true"属性的<div>内或是其子元素
            function isWithinTagTrue($element) {
                console.log('[INFO]', "isWithinTagTrue", $element);
                while ($element.length && $element[0] !== document) {
                    if ($element.is('div[tag="true"]')) {
                        return true;
                    }
                    $element = $element.parent();
                }
                return false;
            }
        },
        unbindEditerTagEvent(parentNode) {
            let editer = $('.target-doc .doc[contenteditable="true"]');
            if (parentNode) {
                editer = $(parentNode).find('.target-doc .doc[contenteditable="true"]');
            }
            editer.off('keydown');
            editer.off('keyup');
            editer.off('click');
            editer.off('mousedown');
            editer.off('mousemove');
            editer.off('mouseup');
        },
        
        
	},
    beforeRouteLeave(to, from, next){
        if((location.pathname+location.search) == to.fullPath){
            if(this.showAutoSave) {
                this.$message.warning(this.$t('workbench.editer_page_methods_beforeRouteLeave_warning_message'));
                history.pushState(null,null,location.href);
                return false;
            }else{
                next();
            }
        } else {
            next();
        }
    },

    beforeUnmount(){
       window.removeEventListener('beforeunload', this.handleBeforeunload);
    },
	mounted() {
		this.queryNotConfirm();
		this.initEvent();
        window.addEventListener('beforeunload', this.handleBeforeunload);
	},
    beforeDestroy(){
        if(this.editorPermissionMessage) {
            this.editorPermissionMessage.close();
        }
    },
    watch:{
        currentSentId (newVal, oldVal) {
            this.initSentPermission(newVal);
        } 
    }
};
</script>
<style lang="scss" scoped>
.editer-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	> .edit {
		flex: 1;
		display: flex;
		> .left {
			flex: 1;
			height: 0;
			min-height: 100%;
			overflow-y: hidden; //scroll
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			// transition:width .5s linear;
		}
		> .center {
			display: flex;
			height: 100%;
			width: 20px;
			justify-content: flex-end;
			align-items: center;
			> .control-stick {
				width: 15px;
				height: 60px;
				font-size: 12px;
				background-color: #f5f5f7;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50px 0 0 50px;
				cursor: pointer;
				transition: right 0.5s;
				> i {
					transition: transform 0.5s linear;
				}
				&.hidden {
					right: 0px;
					border-radius: 50px 0 0 50px;
					> i {
						transform: rotateZ(180deg);
					}
				}
			}
		}
		> .right {
			// height: 0;
			min-height: 100%;
			border-left: 1px solid #f1f1f1;
			box-sizing: border-box;
			// overflow-y:scroll;
			position: relative;
		}
	}
    .filter-main{
        padding: 0 20px;
        position: relative;
        > .clear-all{
            position: absolute;
            right: 20px;
            top: 0;
            font-size: 12px;
            color: #2979FF;
            cursor: pointer;
        }
        .option-group{
            ::v-deep .el-radio-group{
                display: initial;
            }
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            .group-title{
                font-size: 12px;
                margin-bottom: 10px;
                font-weight: bold;
            }
            .option-list{
                display: grid;
                grid-gap: 10px;
                grid-template-columns: repeat(auto-fill,minmax(70px,1fr));
                .item{
                    ::v-deep .el-radio__label{
                        color: #000;
                        font-size: 12px;
                    }
                }
            }
            .option-keyword{
                ::v-deep .el-input__inner{
                    border-radius: 50px;
                }
            }
        }
    }
}
</style>
