<template>
    <div class="project-list-page">
        <full-loading ref="fullLoading"></full-loading>
        <page-title :title="info.name||''" :showRight="false" style="margin-bottom: 0px;" v-if="info">
            <template slot="info">
                <div class="margin-l-10 fonts-14 text-weight-600" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    {{setting.detail && setting.detail.translationProcess.description}}
                </div>
                <el-tooltip content="" placement="top-start">
                    <template slot="content">
                        <div class="line-height-1.5" style="max-width: 400px;">{{info.sourceLang.name}} <i class="el-icon-arrow-right"></i> {{info.targetLangs.map(item=> item.name).join('/')}}</div>
                    </template>
                    <div class="margin-l-10 fonts-14 text-omission" style="max-width: 140px">
                        {{info.sourceLang.name}} <i class="el-icon-arrow-right"></i> {{info.targetLangs.map(item=> item.name).join('/')}}
                    </div>
                </el-tooltip>
                <engine-group :list="info.engines" :size="28" class="margin-l-10"></engine-group>
            </template>
            <template slot="tools">
                
                <!-- <el-button type="primary" size="small" round icon="el-icon-setting" v-permission="`project:detailRouter:config`" @click="showDrawerEvent" style="margin-left:10px;">项目设置</el-button> -->
            </template>
        </page-title>
        <el-tabs class="filter-tabs setting-drawer-content" v-model="activeTab" @tab-click="settingTabChange" v-if="setting.detail">
            <el-tab-pane label="项目文件" name="files">
                <div class="page-tools-content" style="margin-bottom: 0px; border: none;">
                    <div class="left flex flex-align-center">
                        <el-button type="primary" size="small" round icon="el-icon-plus" v-permission="`project:detailRouter:add`" @click="upload.show = true">添加文件</el-button>
                        <el-button size="small" plain round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage('reload')">刷新</el-button>
                        <div class="margin-l-10 fonts-14">总共{{this.total}}个，选中{{this.selectedItem.length}}个</div>
                    </div>
                    <div class="right">
                        <div style="width:202px;">
                            <el-input placeholder="输入文件名称" 
                                size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                                v-model="kw" @input="inputSearchEvent"></el-input>
                        </div>
                        <!-- <template v-if="userInfo.organizationType === 'ENTERPRISE'"> -->
                            <div class="margin-l-10">
                                <el-dropdown @command="handleBatchDownloadCommand">
                                    <el-button plain round size="small" style="padding: 6.4px; font-size: 18px;">
                                        <svg-icon name="ib-download-1"></svg-icon>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-permission="`project:detailRouter:downloadTranslations`" command="target">下载译文</el-dropdown-item>
                                        <el-dropdown-item v-permission="`project:detailRouter:downloadBilingual`" command="bilingual">
                                            下载双语 <pro-icon :size="14" style="margin-bottom:-2px;margin-left:5px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></pro-icon>
                                        </el-dropdown-item>
                                        <el-dropdown-item v-permission="`project:detailRouter:downloadOriginal`" command="source">下载原文</el-dropdown-item>
                                        <el-dropdown-item v-permission="`project:detailRouter:downloadTMX`" command="tmx">下载TMX</el-dropdown-item>
                                        <el-dropdown-item v-permission="`project:detailRouter:downloadEditTrace`" command="trace">下载编辑痕迹</el-dropdown-item>
                                        <el-dropdown-item v-permission="`project:detailRouter:taskStatistics`" command="batchOffer">下载合并报价单</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="margin-l-10" v-permission="`project:detailRouter:delete`">
                                <el-tooltip content="批量删除" placement="top">
                                    <el-button plain round size="small" @click="batchDeleteBtnEvent" style="padding: 6.4px; font-size: 18px;">
                                        <svg-icon name="ib-trash-o-1"></svg-icon>
                                    </el-button>
                                </el-tooltip>
                            </div>
                        <!-- </template> -->
                    </div>
                </div>
                <template v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <file-list-inside :list="list" :doc="info" :reset="resetFileList" :listInit="initFileList" @updateTasks="updateFileTaskList" @action="executeAction"></file-list-inside>
                </template>
                <template v-else>
                    <file-list :list="list" @action="executeAction" :reset="resetFileList" style="margin-top: 20px;" v-if="list.length > 0"></file-list>
                </template>
                <div class="pagination-content" v-if="list.length > 0">
                    <el-pagination 
                        background 
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next"
                        :page-sizes="[20, 50, 100, 500]"
                        :page-size="pageSize"
                        :current-page="pageNumber"
                        :total="total">
                    </el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="任务规划" name="taskPlan" v-if="$checkPermission('project:detailRouter:tasksPlanning')">
                <task-plan-list ref="taskPlanListNode" :doc="info"></task-plan-list>
            </el-tab-pane>
            <el-tab-pane label="基础设置" name="basic">
                <el-card shadow="never" class="project-info none-boder radius-0" :body-style="{padding:'15px 30px', borderBottom: '1px solid #e5e5e5'}">
                    <div class="margin-b-10 fonts-12 flex flex-align-center">
                        <span class="margin-r-10" style="line-height: 16px;">项目名称 </span>
                        <span class="cursor-pointer fonts-16" @click="setProjectNameEdit" v-if="!setting.isEditName">
                            <svg-icon name="ib-edit"></svg-icon>
                        </span>
                        <span class="fonts-16" v-if="setting.saveEditNameLoading"><i class="el-icon-loading"></i></span>
                    </div>
                    <div>
                        <div class="project-name" @click="setProjectNameEdit" v-if="!setting.isEditName">{{setting.defaultProjectName}}</div>
                        <el-input ref="defaultProjectName" 
                            v-model="setting.defaultProjectName" 
                            :disabled="setting.saveEditNameLoading" 
                            placeholder="输入项目名称" 
                            @blur="saveProjectName" 
                            style="margin-top: 1px;"
                            v-else></el-input>
                    </div>
                </el-card>
                <el-card shadow="never" v-if="userInfo.organizationType === 'ENTERPRISE'" 
                    class="none-boder radius-0" :body-style="{padding:'15px 30px', borderBottom: '1px solid #e5e5e5'}">
                    <div class="margin-b-10 fonts-12">所属团队</div>
                    <div class="fonts-15 text-weight-600">{{info.team ? info.team.teamName : '--'}}</div>
                </el-card>
                <el-card shadow="never" v-if="userInfo.organizationType === 'ENTERPRISE'"
                    class="none-boder radius-0" :body-style="{padding:'15px 30px', borderBottom: '1px solid #e5e5e5'}">
                    <div class="margin-b-10 fonts-12">创建人</div>
                    <div class="fonts-15 text-weight-600">{{info.createBy}}</div>
                </el-card>
                <el-card shadow="never" 
                    class="none-boder radius-0" :body-style="{padding:'15px 30px', borderBottom: '1px solid #e5e5e5'}">
                    <div class="margin-b-10 fonts-12">创建时间</div>
                    <div class="fonts-15 text-weight-600">{{info.createTime}}</div>
                </el-card>
                <el-card shadow="never" v-if="userInfo.organizationType === 'ENTERPRISE'" 
                    class="none-boder radius-0" :body-style="{padding:'15px 30px', borderBottom: '1px solid #e5e5e5'}">
                    <div class="margin-b-10 fonts-12 flex flex-align-center">
                        <span class="margin-r-10" style="line-height: 16px;">项目流程 </span>
                        <span class="cursor-pointer fonts-16" @click="setProjectWorkflowEdit" v-if="!setting.isEditWorkflow">
                            <svg-icon name="ib-edit"></svg-icon>
                        </span>
                    </div>
                    <div>
                        <div class="fonts-15 text-weight-600" @click="setProjectWorkflowEdit" v-if="!setting.isEditWorkflow">{{setting.detail.translationProcess.description}}</div>
                        <div class="flex" v-else>
                            <el-select v-model="setting.defaultProjectWorkflow" placeholder="请选择" style="width: 167px;">
                                <template v-for="item in workflowOptions">
                                    <el-option :label="item.description" :value="item.name" :key="item.name"></el-option>
                                </template>
                            </el-select>
                            <el-button plain round :loading="setting.saveEditWorkflowLoading" @click="saveProjectWorkflowEdit" class="margin-l-10">确认修改</el-button>
                        </div>
                    </div>
                </el-card>
                <!-- 语言引擎列表 -->
                <el-card shadow="never" class="none-boder radius-0" :body-style="{padding:'15px 30px', borderBottom: '1px solid #e5e5e5'}">
                    <table class="setting-engine-table">
                        <thead>
                            <th width="256">语言方向</th><th >预翻译引擎</th><th>参考翻译引擎</th><!-- <span class="color-999">(可以在编辑器切换)</span> -->
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in setting.detail.multilingualSetting" :key="index">
                                <td>
                                    <language-direction :source="item.sourceLang.key" :target="item.targetLang.key" type="half" class="text-weight-600"></language-direction>
                                </td>
                                <td>
                                    <template v-if="!item.enableAiTrans"><span class="text-weight-600">人工翻译</span></template>
                                    <template v-else>
                                        <svg-icon :name="`ie-${item.engine.icon}`" className="fonts-18"></svg-icon><span class="text-weight-600 margin-l-10">{{item.engine.name}}</span>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="item.randomEngine">
                                        <svg-icon :name="`ie-${item.randomEngine.icon}`" className="fonts-18"></svg-icon><span class="text-weight-600 margin-l-10">{{item.randomEngine.name}}</span>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-card>
                <el-card shadow="never" v-if="userInfo.organizationType === 'ENTERPRISE'"
                    class="none-boder radius-0" :body-style="{padding:'15px 30px', borderBottom: '1px solid #e5e5e5'}">
                    <div class="margin-b-10 fonts-12">高级设置
                        <el-tooltip placement="top-start">
                            <div class="line-height-1.5" slot="content">
                                <div>1、锁定项在编辑器优先次序为：非译元素>内部重复>跨文件重复>100%匹配>101%匹配>102%匹配</div>
                                <div>2、计价字数设置对所有文件生效；锁定设置仅对追加文件生效。</div>
                            </div>
                            <svg-icon name="ib-question" class="fonts-14"></svg-icon>
                        </el-tooltip>
                    </div>
                    <!-- <div class="margin-b-10 fonts-12">锁定设置</div> -->
                    <div class="qa-setting-panel">
                        <div class="option-wrapper flex flex-align-center">
                            <div class="fonts-12" style="width: 90px;">锁定设置</div>
                            <div class="option-list flex-1" style="padding: 0;">
                                <template v-for="(item,index) in fileAnalysisSettingDialog.fileAnalysisSettingOpts">
                                    <div class="item" :key="index">
                                        <el-checkbox v-model="item.lock" :label="item.name"></el-checkbox> <!-- @change="submitFileAnalysisSettingDialog" -->
                                    </div>
                                </template>
                                <div class="item">
                                    <el-button plain round @click="submitFileAnalysisSettingDialog" size="mini">保存</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="margin-b-10 fonts-12">计价字数</div> -->
                    <div class="qa-setting-panel">
                        <div class="option-wrapper flex flex-align-center">
                            <div class="fonts-12" style="width: 90px;">计价字数</div>
                            <div class="option-list flex-1" style="padding: 0;">
                                <template v-for="(item,index) in fileAnalysisSettingDialog.fileAnalysisSettingOpts">
                                    <div class="item" :key="index">
                                        <el-checkbox v-model="item.calculate" :label="item.name"></el-checkbox> <!-- @change="submitFileAnalysisSettingDialog" -->
                                    </div>
                                </template>
                                <div class="item">
                                    <el-button plain round @click="submitFileAnalysisSettingDialog" size="mini">保存</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="记忆库" name="memory">
                <div class="flex flex-justify-between margin-y-15">
                    <div>
                        <el-button size="mini" plain round @click="gotoMemoryPage" icon="el-icon-plus">新建</el-button>
                        <el-button size="mini" plain round @click="initMemoryList" icon="el-icon-refresh-right">刷新</el-button>
                    </div>
                    <div class="fonts-13 mem-matching-setting">
                        <span class="margin-r-10"><span class="color-error fonts-16" style="margin-right:1px;">*</span>匹配率</span>
                        <el-select placeholder="匹配率" v-model="setting.detail.memMatchingRate" @change="handleMemoryMatchingChange" size="mini" style="width:78px;">
                            <template v-for="item in memMatchingOptions">
                                <el-option :label="item" :value="item" :key="item"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
                <el-table ref="memoryTable" :data="setting.memoryList" row-key="memId" style="width: 100%" @selection-change="handleMemoryTableSelectionChange">
                    <el-table-column type="selection" :reserve-selection="true" width="50"></el-table-column>
                    <el-table-column label="写入" width="80">
                        <template slot-scope="scope">
                            <el-switch :value="setting.detail.writeableMemId.includes(scope.row.memId)" active-color="#1AC49C" @change="val => handleMemoryWriteChange(val, scope.row)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="名称">
                        <template slot-scope="scope">
                            <router-link :to="{path:'/asset/memory/detail',query:{id:scope.row.memId}}" target="_blank">{{scope.row.name}}</router-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="语言方向">
                        <template slot-scope="scope">
                            <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                        </template>
                    </el-table-column>
                    <el-table-column label="条数" prop="nums" min-width="25%">
                        <template slot-scope="scope">
                            <span>{{ scope.row.nums }}条</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="罚分" width="180">
                        <template slot-scope="scope">
                            <el-input-number size="mini" :disabled="getMemoryPenaltyPointsInputStatus(scope.row)" v-model="scope.row.setting[0].penaltyPoints" @change="val => handleMemoryPenaltyPointsChange( val, scope.row)" controls-position="right" :step="1" :precision="0" :min="0" :max="15" style="width: 100px;"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" prop="createTime" min-width="25%"></el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="术语库" name="term">
                <el-alert type="info" effect="dark" :closable="false">
                    <template slot="title">
                        <div class="flex flex-align-center" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">
                            <svg-icon name="ic-free" style="fons-size:22px" class="margin-r-10"></svg-icon>项目术语会在编辑器提示，但当前版本不支持术语干预，<a href="/account/vip" target="_blank">升级到PRO解锁本功能</a>
                        </div>
                        <div class="flex flex-align-center" v-else>
                            <svg-icon name="ic-pro" style="font-size:22px" class="margin-r-10"></svg-icon>{{userInfo.accountTypeDesc}}已支持术语干预
                        </div>
                    </template>
                </el-alert>
                <div class="margin-y-15">
                    <el-button size="mini" plain round @click="gotoTermPage" icon="el-icon-plus">新建</el-button>
                    <el-button size="mini" plain round @click="initTermsList" icon="el-icon-refresh-right">刷新</el-button>
                </div>
                <el-table ref="termsTable" :data="setting.termsList" row-key="termId" max-height="480" style="width: 100%" @selection-change="handleTermsTableSelectionChange">
                    <el-table-column type="selection" :reserve-selection="true" width="50"></el-table-column>
                    <el-table-column label="写入" width="80">
                        <template slot-scope="scope">
                            <el-switch :value="setting.detail.writeableTermId.includes(scope.row.termId)" active-color="#1AC49C" @change="val => handleTermWriteChange(val, scope.row)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="名称">
                        <template slot-scope="scope">
                            <router-link :to="{path:'/asset/term/detail',query:{id:scope.row.termId}}" target="_blank">{{scope.row.name}}</router-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="语言方向">
                        <template slot-scope="scope">
                            <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                        </template>
                    </el-table-column>
                    <el-table-column label="条数" prop="nums" min-width="25%">
                        <template slot-scope="scope">
                            <span>{{ scope.row.nums }}条</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" prop="createTime" min-width="25%"></el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="文档设置" name="docSetting" v-if="setting.detail.docSetting">
                <template v-for="(item, index) in setting.docSettingOpts">
                    <div class="doc-config-card" :key="index">
                        <div class="flex flex-align-center" v-if="setting.detail.docSetting">
                            <el-tooltip :content="item.name" placement="top">
                                <div class="doc-icon file-word flex flex-justify-center flex-align-center">
                                    <svg-icon :name="'ic-' + item.icon" style="width: 17px;height: 17px;"></svg-icon>
                                </div>
                            </el-tooltip>
                            <div class="check-all margin-l-30" style="width: 87px;">
                                <el-checkbox v-model="item.checkAll" :indeterminate="item.indeterminate" @change="val=>docSettingCheckAllChange(val,index)">全选</el-checkbox>
                            </div>
                            <div class="flex-1">
                                <div class="options-list">
                                    <template v-for="(opt, oIndex) in item.options">
                                        <div class="margin-r-30"  :key="oIndex">
                                            <el-checkbox v-model="setting.detail.docSetting[opt.key]" :label="opt.value" @change="val=>docSettingCheckChange(val,index,oIndex)">{{opt.label}}</el-checkbox>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-tab-pane>
            <el-tab-pane label="QA设置" name="qa">
                <!-- <el-card class="project-card no-border radius-12" style="width: 840px; margin: 20px auto;"> -->
                <div class="qa-setting-panel" v-if="setting.detail.qaSetting">
                    <div class="option-wrapper" style="border-bottom: 1px solid #e5e5e5;">
                        <div class="block-title">规则QA设置</div>
                        <div class="option-list">
                            <template v-for="(item,index) in setting.detail.qaSetting.options">
                                <div class="item" :key="index" v-if="item.type === 'BASIC'">
                                    <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description" @change="qaSettingItemChange"></el-checkbox>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="option-wrapper">
                        <div class="block-title">
                            智能QA设置
                            <!-- <el-tooltip content="智能QA只对Pro版开放，Free用户QA只提供规则QA" placement="top">
                                <span style="color:#000;">
                                    <i class="el-icon-info"></i>
                                </span>
                            </el-tooltip> -->
                        </div>
                        <div class="option-list">
                            <template v-for="(item,index) in setting.detail.qaSetting.options">
                                <div class="item" :key="index" v-if="item.type === 'AI'">
                                    <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description" @change="qaSettingItemChange"></el-checkbox>
                                </div>
                            </template>
                        </div>
                    </div>
                    <!-- <div style="font-size:12px; padding:0 0 15px 30px;">
                        <svg-icon name="ib-warning" style="width: 14px;height: 14px;fill:#FDB500;"></svg-icon> 英文流利度（包括用词搭配、时态、单复数、句首大小、代词/冠词、英文流利度检查）
                    </div> -->
                </div>
                <!-- </el-card> -->
            </el-tab-pane>
            <el-tab-pane label="风格指南" name="styleGuide" v-if="userInfo.organizationType === 'ENTERPRISE'">
                <div class="padding-x-30 padding-y-15 bg-white">
                    <div class="margin-b-10 fonts-12 flex flex-align-center"><span class="margin-r-10" style="line-height: 16px;">备注说明 </span>
                        <span class="cursor-pointer fonts-16" @click="setProjectRemarkEdit" v-if="!setting.isEditRemark">
                            <svg-icon name="ib-edit"></svg-icon>
                        </span>
                        <span class="fonts-16" v-if="setting.saveEditRemarkLoading"><i class="el-icon-loading"></i></span>
                    </div>
                    <div>
                        <div class="project-name" @click="setProjectRemarkEdit" v-if="!setting.isEditRemark">
                            <template v-if="setting.defaultProjectRemark">{{setting.defaultProjectRemark}}</template>
                            <span class="text-weight-400 color-999" v-else>暂无备注说明</span>
                        </div>
                        <el-input ref="defaultProjectRemark" 
                            v-model="setting.defaultProjectRemark" 
                            :disabled="setting.saveEditRemarkLoading" 
                            maxlength="500" show-word-limit 
                            placeholder="输入项目备注" 
                            @blur="saveProjectRemark" 
                            style="margin-top: 1px;"
                            v-else></el-input>
                    </div>
                </div>
                <div class="padding-y-15 border-b">
                    <el-button plain round size="mini" icon="el-icon-plus" @click="remarkFileUpload.show = true">添加文件</el-button>
                </div>
                <el-table :data="remarkFileList" style="width: 100%;">
                    <el-table-column label="序号" type="index"></el-table-column>
                    <el-table-column label="文件名" prop="fileName"></el-table-column>
                    <el-table-column label="创建时间" prop="time"></el-table-column>
                    <el-table-column label="操作" width="100px">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="deleteRemarkFile(scope)">
                            <svg-icon name="ib-trash-o" className="fonts-16"></svg-icon>
                        </el-button>
                    </template>
                    </el-table-column>
                </el-table>
                <el-dialog title="上传风格指南文件" :visible.sync="remarkFileUpload.show" destroy-on-close :width="'520px'" top="20px">
                    <div class="fonts-12 margin-b-15 text-center">当前项目最多可上传 <span class="color-link">10</span> 个文件，如有多个文件建议上传压缩包</div>
                    <!-- <div class="fonts-12 margin-b-15 text-center">{{remarkFileUpload.supportFiles}}</div> -->
                    <upload-oss ref="remarkFileUploadDom"
                        :multiple="true"
                        :limit="10 - remarkFileList.length"
                        :size="50"
                        :accept="remarkFileUpload.accept"
                        :params="{}"
                        :type="`STYLE_GUIDE`"
                    ></upload-oss>
                    <div slot="footer" class="text-center">
                        <el-button round plain size="small" @click="remarkFileUpload.show = false">取消</el-button>
                        <el-button round type="primary" size="small" :loading="remarkFileUpload.loading" @click="submitUploadRemarkFile">确定</el-button>
                    </div>
                </el-dialog>
            </el-tab-pane>
            
           <el-tab-pane label="文件分析" name="analysis">
                <file-analysis ref="fileAnalysis" :project="{...info, ...setting}"></file-analysis>
            </el-tab-pane>
            <el-tab-pane label="任务统计" name="taskStatistics" v-if="userInfo.organizationType === 'ENTERPRISE'">
                <project-task-statistics-table-list ref="ProjectTaskStatisticsTable" :project="info"></project-task-statistics-table-list>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="添加文件" :visible.sync="upload.show" destroy-on-close :width="'560px'" top="20px">
            <div class="fonts-12 margin-b-8 text-center">当前项目最多可上传 <span class="color-link">{{userInfo&&userInfo.featureConfig.project.fileCount}}</span> 个文件，还可上传<span class="color-warning">{{userInfo?(userInfo.featureConfig.project.fileCount - total):0}}</span>个文件</div>
            <div class="fonts-12 margin-b-15 text-center line-height-1.25">{{upload.supportFiles}}</div>
            <upload-oss ref="appendUploadFileDom"
                :multiple="true"
                :limit="userInfo ? userInfo.featureConfig.project.fileCount - total:0"
                :size="userInfo ? userInfo.featureConfig.project.singleFileSize : 0"
                :accept="upload.accept"
                :params="{}"
            ></upload-oss>
            <div slot="footer" class="text-center">
                <el-button round plain size="small" @click="upload.show = false">取消</el-button>
                <el-button round type="primary" size="small" :loading="upload.loading" @click="submitAppendFile">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="editWorkflowDialog.show" destroy-on-close :width="'400px'" top="20px">
            <div>
                <div class="fonts-12 color-FF9900 line-height-1.5">注意：项目流程修改仅支持对项目中未启动的文档进行重置，后续追加的文档会使用最新的流程。若您需要修改项目中已启动的文档，请在项目文件中对单个文档流程进行修改。</div>
                <div class="fonts-14 text-center margin-t-15">是否确认修改项目流程？</div>
            </div>
            <div slot="footer" class="text-center">
                <el-button round plain size="small" @click="editWorkflowDialog.show = false">取消</el-button>
                <el-button round type="primary" size="small" @click="setting.isEditWorkflow = true; editWorkflowDialog.show = false;">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="editFileWorkflowAlert.show" destroy-on-close :width="'400px'" top="20px">
            <div>
                <div class="fonts-12 color-FF9900 line-height-1.5">注意：对已启动的文件修改流程会导致所有内容（负责人、进度、编辑内容等）重置，请谨慎操作！</div>
                <div class="fonts-14 text-center margin-t-15">是否确定修改当前文件的翻译流程？</div>
            </div>
            <div slot="footer" class="text-center">
                <el-button round plain size="small" @click="editFileWorkflowAlert.show = false">取消</el-button>
                <el-button round type="primary" size="small" @click="editFileWorkflowAlertSubmitEvent">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改文件流程" :visible.sync="editFileWorkflowDialog.show" destroy-on-close :width="'430px'" top="20px">
            <el-form style="padding: 0 40px;">
                <el-form-item label="文件流程">
                    <el-select v-model="editFileWorkflowDialog.value" placeholder="">
                        <template v-for="item in workflowOptions">
                            <el-option :label="item.description" :value="item.name" :key="item.name"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="text-center">
                <el-button round plain size="small" @click="editFileWorkflowDialog.show = false">取消</el-button>
                <el-button round type="primary" size="small" :loading="editFileWorkflowDialog.loading" @click="saveFlieWorkflowEdit">确认修改</el-button>
            </div>
        </el-dialog>
        <create-memory ref="createMemoryDialog" @success="initMemoryList"></create-memory>
        <create-term ref="createTermDialog" @success="initTermsList"></create-term>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteDocumentEvent">
        </alert-dialog>
    </div>
</template>
<script>
import moment from'moment';
import cookie from "vue-cookies";
import FullLoading from '@/components/FullLoading';
import { mapState, mapGetters } from 'vuex';
import { docApi, projectApi, memoryApi, termApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
import tools from '@/utils/tools';
import PageTitle from '@/components/PageTitle';
import CardList from './components/FileCardListV2.vue';
import FileListInside from './components/FileListInside.vue';
import AlertDialog from '@/components/AlertDialog';
import NextDrawer from '@/components/NextDrawer';
import CreateTerm from '@/views/project/components/CreateTermMultiLanguage';
import CreateMemory from '@/views/project/components/CreateMemoryMultiLanguage';
import UploadOss from '@/components/UploadOss';
import TaskPlanList from './components/TaskPlanList.vue';
import FileList from './components/FileList.vue';
import EngineGroup from '@/components/EngineGroup';
import LanguageDirection from '@/components/LanguageDirection';
import ProIcon from "@/components/icon/ProIcon";
import FileAnalysis from './components/FileAnalysis.vue';
import ProjectTaskStatisticsTableList from './components/ProjectTaskStatisticsTableList.vue'
export default {
    components:{AlertDialog, CardList, FileListInside, PageTitle, FullLoading, NextDrawer, CreateTerm, CreateMemory, UploadOss, TaskPlanList, FileList, EngineGroup, LanguageDirection, ProIcon, FileAnalysis, NextDrawer, ProjectTaskStatisticsTableList, },
    data(){
        return {
            moment,
            config,
            activeTab: 'files',
            hasPdf: false,
            upload:{
                limit: 20,
                show: false,
                accept: '.docx,.doc,.rtf,.xlsx,.xls,.ppt,.pptx,.srt,.txt,.csv,.xliff,.xlf,.sdlxliff,.sdlxlf,.zip,.rar,.7z',
                supportFiles:`支持docx/doc/rtf/xlsx/xls/ppt/pptx/srt/txt/csv/xliff/xlf/sdlxliff/sdlxlf/zip/rar/7z格式的文件`,
                loading:false,
                files:[],
            },
            refreshLoading:false,
            info: null,
            kw:'',
            filterValue:1,
            sortBy: '',
            sortMode: '',
            list:[],
            resetFileList: '',
            initFileList: '',
            pageSize: 20, // 10
            pageNumber:1,
            total:0,
            // engineDist:tools.getEngineDist(config.DOMAIN_AND_ENGINE_LIST),
            fileTypeMatch:config.FILE_TYPE_MATCH,
            documentStatusDist:config.DOCUMENT_STATUS_DIST,
            listTimer:null,
            searchTimer:null,
            queryType:'init',
            selectedItem: [],
            alertInfo:{
                message:'确定要删除文件吗？',
                type:'confirm', //alert ,confirm
                buttonName:'确认删除',
                buttonType:'danger',
            },
            showDrawer: false,
            // activeSettingTabName: 'basic',
            setting: {
                showSave: false,
                showSaveStatus: '',
                saveMessage: null,
                // saveTimer: null,
                detail: null,
                isEditName: false,
                defaultProjectName: '',
                saveEditNameLoading: false,

                isEditRemark: false,
                defaultProjectRemark: '',
                saveEditRemarkLoading: false,

                memoryTableSelection: [],
                memoryList: [],
                termsTableSelection: [],
                termsList: [],
                docSettingOpts: config.DOC_SETTING_OPTS,
                // memMatchingRate: 70,

                defaultProjectWorkflow: '',
                isEditWorkflow: false,
                saveEditWorkflowLoading: false,

            },
            editWorkflowDialog: {
                show: false,
            },
            editFileWorkflowAlert: {
                show: false,
            },
            editFileWorkflowDialog: {
                show: false,
                loading: false,
                value: '',
                id: '',
            },
            workflowOptions: [],
            memorySelectionOrigin: 'init',
            termSelectionOrigin: 'init',
            memMatchingOptions: [50, 60, 70, 80, 90, 100,],
            
            remarkFileUpload: {
                show: false,
                loading: false,
                accept: '', //'.txt,.docx,.doc,.pdf,.rtf,.xlsx,.xls,.ppt,.pptx,.csv,.srt,.sdlxliff,.xliff,.cad,.dita,.ai,.indd,.mpp',
                supportFiles: 'txt/docx/doc/pdf/rtf/xlsx/xls/ppt/pptx/csv/srt/sdlxliff/xliff/cad/dita/ai/indd/mpp',
            },
            remarkFileList: [],
            nextDrawer: {
                visible: false,
            },
            fileAnalysisSettingDialog: {
                show: false,
                loading: false,
                fileAnalysisSettingOpts: [],
            }
        }
    },
    computed:{
        ...mapState({
            engineDist: state => state.common.engineConfig,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
            userInfo: state => state.user.userData,
        }),
    },
    methods:{
        initPage(type){
            let self = this;
            this.list = [];
            if(type === 'init'){
                this.$refs.fullLoading.setShow();
            }
            if(type === 'reload'){
                this.refreshLoading = true;
            }
            let url=`${docApi.queryDocumentsList}`+ 
                    `?projectId=${this.$route.query.id}`+
                    `&keyword=${encodeURI(this.kw)}` +
                    `&pageSize=${this.pageSize}` +
                    `&pageNumber=${this.pageNumber}`;
            if(this.sortBy && this.sortMode) {
                url = `${url}&sortBy=${this.sortBy}&sortMode=${this.sortMode}`;
            }

            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.documents.records;
                    this.total = res.data.documents.total;
                    this.info = res.data.project;
                    if(this.userInfo && this.userInfo.featureConfig) {
                        if(this.userInfo.featureConfig.project.fileCount - this.total < 20 && this.userInfo.featureConfig.project.fileCount - this.total >=0){
                            this.upload.limit = this.userInfo.featureConfig.project.fileCount - this.total;
                        }else if(this.userInfo.featureConfig.project.fileCount - this.total >= 20){
                            this.upload.limit = 20;
                        }else {
                            this.upload.limit = 0;
                        }
                    }
                    if(type === 'init') {
                        setTimeout(() =>{
                            self.initFileList = new Date().getTime();
                        }, 1000);

                        this.initProjectSetting();
                        this.initMemoryList();
                        this.initTermsList();
                    }
                    if(this.listTimer){
                        clearInterval(this.listTimer);
                    }
                    this.listTimer = setInterval(()=>{
                        self.pollingDocumentsStatus();
                    },5000);
                }
            }).finally(e=>{
                this.$refs.fullLoading.setHide();
                this.refreshLoading = false;
            })
        },
        updateFileTaskList(index, tasks, plan) {
            let processNodes = [];
            tasks.map(item => {
                processNodes.push({
                    state: item.state,
                    type: item.taskType,
                    typeDesc: item.taskTypeDesc,
                })
            });
            this.list.splice(index, 1, {...this.list[index], tasks, processNodes, progress:plan.progress});
        },
        pollingDocumentsStatus(){
            let ids = [];
            this.list.map(item => {
                if (!(item.docStatus === "pre-translated" || item.docStatus === "limited" || item.docStatus.indexOf('error') > -1)) {
                    ids.push(item.docId);
                }
            });
            if(ids.length > 0) {
                let url = docApi.pollingDocumentsStatus;
                this.$ajax.post(url, {docIds: ids}).then(res => {
                    if(res.status === 200) {
                        let statusData = {};
                        res.data.map(item=>{
                            statusData[item.docId] = { ...item };
                        });
                        let _list = this.list;
                        _list.map((item, index) => {
                            if(statusData[item.docId]){
                                this.list.splice(index, 1, {...item, ...statusData[item.docId]})
                            }
                        });
                    }
                });
            }
        },
        initProjectSetting(){
            let url = `${projectApi.queryProjectSetting}?projectId=${this.info.projectId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    let params = {...res.data, ...res.data.baseSetting};
                    if (!params.multilingualSetting[0].memMatchingRate){
                        params.memMatchingRate = 80;
                    } 
                    this.setting.detail = { writeableMemId: [], writeableTermId: [], ...params };
                    // console.log('1111...params::::', params);
                    this.setting.defaultProjectName = params.projectName;
                    this.setting.defaultProjectRemark = params.remark;
                    this.remarkFileList = params.styleGuideDocs || [];
                    this.setting.defaultProjectWorkflow = params.translationProcess.name;
                    this.initDocSettingOpts();
                    this.initFileAnalysisSettingDialog();
                }
            })
        },
        executeAction(key,item){
            switch (key) {
                case 'goto':
                    if (item.docStatus === 'pre-translated'){
                        this.$router.push({name:'Editer',query:{id:item.docId}});
                    }
                    break;
                case 'source':
                case 'target':
                case 'bilingual':
                case 'tmx':
                case 'trace':
                    this.handleDownloadCommand(key,item);
                    break;
                case 'delete':
                    this.deleteBtnEvent(item);
                    break;
                case 'retranslate':
                    this.retranslateDoc(item);
                    break;
                case 'reload':
                    this.initPage('reload');
                    break;
                case 'updateSelected':
                    this.selectedItem = item;
                    break;
                case 'updateSort':
                    this.sortBy = item.sortBy;
                    this.sortMode = item.sortMode
                    this.initPage('reload');
                    break;
                case 'editWorkflow':
                    this.selectedItem = item;
                    this.setFileWorkflowEdit();
                    // this.saveFlieWorkflowEdit();
                    break;
            }
        },
        gotoNewPage(){
            this.$router.push('/new');
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initPage('reload');
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNumber = 1;
            this.initPage('reload');
        },
        retranslateDoc(item){
            let url = `${docApi.retranslateDoc}?docId=${item.docId}`;
            this.$ajax.post(url,{}).then(res=>{
                if(res.status == 200){
                    this.initPage('reload');
                }
            })
        },
        deleteDocumentEvent(){
            let ids = [];
            this.selectedItem.map(item => {
                ids.push(item.docId);
            });
            let url = docApi.deleteDocument;
            this.$ajax.post(url, {projectId: this.info.projectId, documentIdList: ids}).then(res=>{
                if(res.status === 200){
                    this.$message.success('已删除');
                    this.initPage('reload');
                    this.selectedItem = [];
                    this.resetFileList = new Date().getTime();
                }
            })
        },
        batchDeleteBtnEvent(){
            if(this.selectedItem.length == 0) {
                this.$message.warning('请选择要删除的文档');
                return;
            }
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteBtnEvent(item){
            this.selectedItem = [item];
            this.$refs.alertDialogDom.setShow(true);
        },
        handleBatchDownloadCommand(command){
            switch (command) {
                case 'batchOffer':
                    this.downloadBatchOfferEvent();
                    break;
                default:
                    if(command === 'bilingual'){
                        if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType)>-1){
                            this.$router.push('/account/vip');
                            return;
                        }
                    }
                    if(this.selectedItem.length == 0) {
                        this.$message.warning('请选择要下载的文档');
                        return;
                    }
                    let url = commonApi.batchCreateDownloadTask;
                    let ids = [];
                    this.selectedItem.map(item => {
                        ids.push(item.docId);
                    });
                    let postData = {
                        title: ids.length === 1 ? this.selectedItem[0].originalFilename : `项目文件导出${ids.length}个`,
                        metadataIdList: [this.info.projectId],
                        metadataType: "TMS_PROJECT",
                        moreOptions: {
                            exportFileType: command,
                            documentIdList: ids,
                        }
                    };
                    this.$ajax.post(url, postData).then(res=>{
                        if(res.status === 200){
                            this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                            this.selectedItem = [];
                            this.resetFileList = new Date().getTime();
                        }
                    }).finally(()=>{
                        
                    });
                    break;
            }
            
        },
        handleDownloadCommand(command, item){
            let url = commonApi.batchCreateDownloadTask;
            let postData;
            if(command === 'trace'){
                postData = {
                title: item.originalFilename, 
                metadataIdList: [this.info.projectId], 
                metadataType: "TMS_PROJECT", 
                moreOptions: {
                    exportFileType: "trace", 
                    documentIdList:  [item.docId],
                }
            };
            }
            else{
                postData = {
                title: item.originalFilename,
                metadataIdList: [item.docId],
                metadataType: "TMS_DOCUMENT",
                moreOptions: {
                    exportFileType: command,
                }
            };
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                    this.selectedItem = [];
                    this.resetFileList = new Date().getTime();
                }
            }).finally(()=>{
                
            });
        },
        inputSearchEvent(value){
            let self = this;
            if(value.length > 0){
                this.queryType = 'search';
            }else{
                this.queryType = 'init';
            }
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                self.initPage('reload');
            },500);
        },
        submitAppendFile(){
            let fileData = this.$refs.appendUploadFileDom.getFiles();
            // console.log(fileData);
            let files = fileData.files;
            let keys = Object.keys(files);
            let fileList = [];
            keys.map(key => {
                fileList.push(files[key]);
            })
            // return;
            let self = this;
            let url = docApi.appendProjectFiles;
            let postData = {
                projectId: this.$route.query.id,
                docs: fileList,
            };
            this.upload.loading = true;
            this.$ajax.post(url,postData).then(res=>{
                if (res.status === 200) {
                    this.upload.show = false;
                    if(res.data.duplicateCount) {
                        this.$message.warning(`当新上传的文件中有 ${res.data.duplicateCount} 个同名文件，请修改文件名后上传`);
                    }
                    setTimeout(() => {
                        self.$router.go(0);
                    }, 1000);
                }
            }).finally(() => {
                this.upload.loading = false;
            });
        },
        showDrawerEvent(){
            this.showDrawer = !this.showDrawer;
            if(this.showDrawer){
                
            }
        },
        settingTabChange(event){
            switch (event.name){
                case 'files':
                    this.initPage('reload');
                    break;
                case 'memory':
                    this.setMemorySelection();
                    break;
                case 'term':
                    this.setTermSelection();
                    break;
                case 'taskPlan':
                    this.$refs.taskPlanListNode.initList();
                    break;
                case 'analysis':
                    this.$refs.fileAnalysis.initAnalysis();
                    break;
                case 'taskStatistics':
                    this.$refs.ProjectTaskStatisticsTable.initTaskStatistics(this.info);
                    break;
            }
        },
        setProjectNameEdit(){
            this.setting.isEditName = !this.setting.isEditName;
            this.$nextTick(()=>{
                this.$refs.defaultProjectName.focus();
            });
        },
        saveProjectName(e){
            let self = this;
            if(self.info.name === self.setting.defaultProjectName){
                self.setting.isEditName = false;
                return;
            }
            this.setting.saveEditNameLoading = true;
            let url = projectApi.updateProjectsBasicInfo;
            this.$ajax.post(url, {
                projectName: self.setting.defaultProjectName,
                projectId: this.info.projectId,
            }).then(res => {
                if(res.status == 200){
                    this.$message.success('项目名称修改成功');
                    self.info.name = self.setting.defaultProjectName;
                    self.setting.isEditName = false;
                }
            }).finally(() => {
                self.setting.saveEditNameLoading = false;
            })
        },

        setProjectRemarkEdit(){
            this.setting.isEditRemark = !this.setting.isEditRemark;
            this.$nextTick(()=>{
                this.$refs.defaultProjectRemark.focus();
            });
        },
        saveProjectRemark(e){
            let self = this;
            if(self.info.remark === self.setting.defaultProjectRemark){
                self.setting.isEditRemark = false;
                return;
            }
            this.setting.saveEditRemarkLoading = true;
            let url = projectApi.updateProjectsBasicInfo;
            this.$ajax.post(url, {
                remark: self.setting.defaultProjectRemark,
                projectId: this.info.projectId,
            }).then(res => {
                if(res.status == 200){
                    this.$message.success('项目备注修改成功');
                    self.info.remark = self.setting.defaultProjectRemark;
                    self.setting.isEditRemark = false;
                }
            }).finally(() => {
                self.setting.saveEditRemarkLoading = false;
            })
        },
        setProjectWorkflowEdit() {
            this.queryWorkflowOptions();
            this.editWorkflowDialog.show = true;
        },
        saveProjectWorkflowEdit(){
            this.setting.saveEditWorkflowLoading = true;
            let url = this.$ajax.format(projectApi.updateProjectsSettingInfo, {id: this.info.projectId});
            this.$ajax.put(url, {
                translationProcess: this.setting.defaultProjectWorkflow,
            }).then(res => {
                if(res.status == 200){
                    this.$message.success('项目流程修改成功');
                    this.setting.detail.translationProcess = this.workflowOptions.find(item => item.name == this.setting.defaultProjectWorkflow);
                    this.setting.isEditWorkflow = false;
                    this.initPage('reload');
                }
            }).finally(() => {
                this.setting.saveEditWorkflowLoading = false;
            })
        },
        setFileWorkflowEdit(){
            this.queryWorkflowOptions();
            this.editFileWorkflowAlert.show = true;
        },
        async editFileWorkflowAlertSubmitEvent() {
            this.editFileWorkflowAlert.show = false;
            await tools.sleep(300);
            this.editFileWorkflowDialog = {
                ...this.editFileWorkflowDialog,
                show: true,
                value: this.selectedItem.translationProcess,
                id: this.selectedItem.docId,
            };
        },
        saveFlieWorkflowEdit(){
            this.editFileWorkflowDialog.loading = true;
            let url = projectApi.changeDocumentProcessMode;
            this.$ajax.post(url, {
                documentId: this.editFileWorkflowDialog.id,
                translationProcess: this.editFileWorkflowDialog.value,
            }).then(res => {
                if(res.status == 200){
                    this.$message.success('文件流程修改成功');
                    this.editFileWorkflowDialog = false;
                    this.initPage('reload');
                }
            }).finally(() => {
                this.editFileWorkflowDialog.loading = false;
            });
        },
        queryWorkflowOptions() {
            let url = projectApi.queryWorkflowList;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.workflowOptions = res.data;
                }
            });
        },
        initTermsList() {
			this.setting.termsTableSelection = [];
			let url = `${termApi.termDB}?pageNumber=1&pageSize=1000` +
				`&sourceLang=${this.info.sourceLang.key}&targetLang=${this.info.targetLangs.map(item => item.key).join(',')}&teamId=${this.info.team ? this.info.team.teamId : ''}`;
			this.$ajax.get(url).then((res) => {
				if (res.status === 200) {
					this.setting.termsList = res.data.records;
                    this.$nextTick(() =>{
                        if(arguments.length){
                            let termId = arguments[0].termId;
                            this.setting.detail.termIds.push(termId);
                            for(let item of this.setting.termsList){
                                if(item.termId === termId){
                                    this.$refs.termsTable.toggleRowSelection(item, true);
                                    break;
                                }
                            }
                        }
                        this.setTermSelection();
                     });
				}
			});
		},
		initMemoryList() {
			this.setting.memoryTableSelection = [];
			let url = `${memoryApi.memoryDB}?pageNumber=1&pageSize=1000&type=new&projectId=${this.info.projectId}` +
				`&sourceLang=${this.info.sourceLang.key}&targetLang=${this.info.targetLangs.map(item => item.key).join(',')}&teamId=${this.info.team ? this.info.team.teamId : ''}`;
			this.$ajax.get(url).then((res) => {
				if (res.status === 200) {
					this.setting.memoryList = res.data.records;
                    this.$nextTick(() =>{
                        if(arguments.length){
                            let memId = arguments[0].memId;
                            this.setting.detail.memIds.push(memId);
                            for(let item of this.setting.memoryList){
                                if(item.memId === memId){
                                    this.$refs.memoryTable.toggleRowSelection(item, true);
                                    break;
                                }
                            }
                        }
                        this.setMemorySelection();
                    });
				}
			});
		},
        setMemorySelection(){
            let self = this;
            this.$nextTick(() =>{
                this.setting.memoryList.map(row => {
                    if(this.setting.detail.memIds && this.setting.detail.memIds.indexOf(row.memId) !== -1){
                        this.$refs.memoryTable.toggleRowSelection(row, true);
                        this.memorySelectionOrigin = 'init';
                    }
                });
                setTimeout(()=>{
                    self.memorySelectionOrigin = '';
                }, 200);
            });
        },
        setTermSelection(){
            let self = this;
            this.$nextTick(() =>{
                this.setting.termsList.map(row => {
                    if(this.setting.detail?.termIds && this.setting.detail.termIds.indexOf(row.termId) !== -1){
                        this.$refs.termsTable.toggleRowSelection(row, true);
                        this.termSelectionOrigin = 'init';
                    }
                });
                setTimeout(()=>{
                    self.termSelectionOrigin = '';
                }, 200);
            });
        },
        
        setProjectsSetting(param, callback){
            let self = this;
            if(!this.setting.saveMessage){
                this.setting.saveMessage = this.$message({
                    dangerouslyUseHTMLString: true,
                    message: '&nbsp;&nbsp;保存中...',
                    iconClass: 'el-icon-loading',
                });
            }
            let url = this.$ajax.format(projectApi.updateProjectsSettingInfo, {id: this.info.projectId});
            this.$ajax.put(url, param).then(res => {
                if(res.status === 200){
                    if(callback){
                        callback();
                    }
                }
            }).finally(() => {
                setTimeout(()=>{
                    self.setting.saveMessage.close();
                    self.setting.saveMessage = null;
                }, 500);
            });
        },
        handleMemoryTableSelectionChange(val){
            this.setting.memoryTableSelection = val;
            if(this.memorySelectionOrigin === ''){
                let ids = val.map(item => item.memId);
                let data = {memIds: ids};
                if (this.setting.detail.writeableMemId.length) {
                    let _list = [];
                    this.setting.detail.writeableMemId.map(id => {
                        if(ids.includes(id)) {
                            _list.push(id);
                        }
                    });
                    this.setting.detail.writeableMemId = _list;
                    data['writeableMemId'] = _list;
                }
                this.setProjectsSetting(data, ()=> {
                    this.setting.detail.memIds = ids;
                });
            }
        },
        
        handleMemoryWriteChange(val, row) {
            // console.log(val, row);
            if(val){
                let hitItem = this.setting.memoryList.filter(item => this.setting.detail.writeableMemId.includes(item.memId) && row.sourceLang === item.sourceLang && row.targetLang === item.targetLang);
                if(hitItem.length > 0) {
                    let index = this.setting.detail.writeableMemId.findIndex(id => id === hitItem[0].memId);
                    this.setting.detail.writeableMemId.splice(index, 1, row.memId);
                }else {
                    this.setting.detail.writeableMemId.push(row.memId);
                }
                this.$refs.memoryTable.toggleRowSelection(row, true);
            }else {
                let index = this.setting.detail.writeableMemId.findIndex(id => id === row.memId);
                this.setting.detail.writeableMemId.splice(index, 1);
            }
            this.setProjectsSetting({writeableMemId: this.setting.detail.writeableMemId});
        },
        handleMemoryPenaltyPointsChange(val, row) {
            // console.log(val, row, this.setting);
            let self = this;
            if(!this.setting.saveMessage){
                this.setting.saveMessage = this.$message({
                    dangerouslyUseHTMLString: true,
                    message: '&nbsp;&nbsp;保存中...',
                    iconClass: 'el-icon-loading',
                });
            }
            let url = projectApi.saveProjectMemPenaltyPoints;
            this.$ajax.post(url, {projectId: this.info.projectId, memId: row.memId, setting: [{penaltyPoints: val, penaltyType: 'MEM_OT'}]}).then(res => {
                
            }).finally(() => {
                setTimeout(()=>{
                    self.setting.saveMessage.close();
                    self.setting.saveMessage = null;
                }, 500);
            });
        },
        getMemoryPenaltyPointsInputStatus(row) {
            return this.setting.detail.memIds.indexOf(row.memId) < 0;
        },
        handleTermWriteChange(val, row) {
            if(val){
                // this.setting.detail.writeableTermId = row.termId;
                let hitItem = this.setting.termsList.filter(item => this.setting.detail.writeableTermId.includes(item.termId) && row.sourceLang === item.sourceLang && row.targetLang === item.targetLang);
                if(hitItem.length > 0) {
                    let index = this.setting.detail.writeableTermId.findIndex(id => id === hitItem[0].termId);
                    this.setting.detail.writeableTermId.splice(index, 1, row.termId);
                }else {
                    this.setting.detail.writeableTermId.push(row.termId);
                }
                this.$refs.termsTable.toggleRowSelection(row, true);
            }else {
                let index = this.setting.detail.writeableTermId.findIndex(id => id === row.termId);
                this.setting.detail.writeableTermId.splice(index, 1);
                // this.setting.detail.writeableTermId = null;
            }


            this.setProjectsSetting({writeableTermId: this.setting.detail.writeableTermId});
        },
        handleTermsTableSelectionChange(val){
            this.setting.termsTableSelection = val;
            if(this.termSelectionOrigin === ''){
                let ids = val.map(item => item.termId);
                let data = {termIds: ids};
                if (this.setting.detail.writeableTermId.length) {
                    let _list = [];
                    this.setting.detail.writeableTermId.map(id => {
                        if(ids.includes(id)) {
                            _list.push(id);
                        }
                    });
                    this.setting.detail.writeableTermId = _list;
                    data['writeableTermId'] = _list;
                }
                this.setProjectsSetting(data);
            }
        },
        handleMemoryMatchingChange(val){
            this.setProjectsSetting({memMatchingRate: val});
        },
        gotoTermPage() {
            let list = [];
            this.info.targetLangs.map(target => {
                list.push({source: this.info.sourceLang.key, target: target.key, object: {}});
            })
            this.$refs.createTermDialog.setShow({list});
		},
		gotoMemoryPage() {
            let list = [];
            this.info.targetLangs.map(target => {
                list.push({source: this.info.sourceLang.key, target: target.key, object: {}});
            })
            this.$refs.createMemoryDialog.setShow({list});
		},
        docSettingCheckAllChange(val,index){
            this.setting.docSettingOpts[index]['checkAll'] = val;
            this.setting.docSettingOpts[index]['indeterminate'] = false;
            let tmpOptions = this.setting.docSettingOpts[index]['options'];
            tmpOptions.map((opt, oIndex)=>{
                tmpOptions[oIndex]['value'] = val;
                this.setting.detail.docSetting[opt.key] = val;
            });
            this.setting.docSettingOpts[index]['options'] = tmpOptions;
            this.setProjectsSetting({docSetting: this.setting.detail.docSetting});
        },
        docSettingCheckChange(val,index,oIndex){
            let checkedCount = 0;
            this.setting.docSettingOpts[index]['options'][oIndex]['value'] = val;
            let options = this.setting.docSettingOpts[index]['options'];
            options.map(opt=>{
                if(opt.value === true){
                    checkedCount = checkedCount + 1;
                }
            });
            this.setting.docSettingOpts[index]['checkAll'] = checkedCount === options.length;
            this.setting.docSettingOpts[index]['indeterminate'] = checkedCount > 0 && checkedCount < options.length;
            this.setProjectsSetting({docSetting: this.setting.detail.docSetting});
        },
        /**根据设置配置接口返回的数据设置默认选中 */
        initDocSettingOpts(){
            if(this.setting.detail.docSetting){
                this.setting.docSettingOpts.map((setting, i) => {
                    let checkedCount = 0;
                    setting.options.map((opt, j) => {
                        let key = opt.key;
                        let val = this.setting.detail.docSetting[key];
                        this.setting.docSettingOpts[i]['options'][j]['value'] = val;
                        if(val){
                            checkedCount = checkedCount + 1;
                        }
                    });
                    this.setting.docSettingOpts[i]['checkAll'] = checkedCount === setting.options.length;
                    this.setting.docSettingOpts[i]['indeterminate'] = checkedCount > 0 && checkedCount < setting.options.length;
                });
            }
        },
        qaSettingItemChange(){
            let self = this;
            let list = this.setting.detail.qaSetting.options;
            let optionsObject = {};
            list.forEach(option => {
                optionsObject[option.code] = option.selected;
            });
            if(!this.setting.saveMessage){
                this.setting.saveMessage = this.$message({
                    type: 'success',
                    message: ' 保存中...',
                    iconClass: 'el-icon-loading',
                    duration: 0,
                });
            }
            let url = this.$ajax.format(projectApi.updateProjectsSettingInfo,{id: this.info.projectId})
            this.$ajax.put(url,{qaSetting:{selected: optionsObject}}).then(res=>{
                
            }).finally(()=>{
                setTimeout(()=>{
                    self.setting.saveMessage.close();
                    self.setting.saveMessage = null;
                }, 500);
            })
        },
        getTargetLangNames(targetLangs) {
            // console.log(targetLangs);
            return targetLangs.map(item=> item.name).join('/');
        },
        submitUploadRemarkFile(){
            let files = this.$refs.remarkFileUploadDom.getOssFiles();
            let list = [];
            files.map(item => {
                list.push({...item, time: moment().format('YYYY-MM-DD HH:mm:ss')});
            });
            this.remarkFileList = this.remarkFileList.concat(list);
            this.updateRemarkFile();
            this.remarkFileUpload.loading = false;
            this.remarkFileUpload.show = false;
        },
        deleteRemarkFile(scope){
            this.remarkFileList.splice(scope.$index, 1);
            this.updateRemarkFile();
        },
        updateRemarkFile() {
            let self = this;
            if(!this.setting.saveMessage){
                this.setting.saveMessage = this.$message({
                    type: 'success',
                    message: ' 保存中...',
                    iconClass: 'el-icon-loading',
                    duration: 0,
                });
            }
            let url = this.$ajax.format(projectApi.updateProjectsSettingInfo,{id: this.info.projectId})
            this.$ajax.put(url,{styleGuideDocs: this.remarkFileList}).then(res=>{
                
            }).finally(()=>{
                setTimeout(()=>{
                    self.setting.saveMessage.close();
                    self.setting.saveMessage = null;
                }, 500);
            })
        },
        downloadBatchOfferEvent() {
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: `${this.info.name}-报价单`,
                metadataIdList: [this.info.projectId],
                metadataType: "TMS_TASK_QUOTATION",
                moreOptions: {
                    type: 'VENDOR',
                }, 
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
            });
        },
        initFileAnalysisSettingDialog () {
            let opts = [];
            config.FILE_ANALYSIS_SETTING_OPTS.map(item => {
                let opt = this.setting.detail.analysisSettings.filter(opt => opt.type === item.type)[0];
                opts.push({...item, ...opt, name: item.name})
            });
            this.fileAnalysisSettingDialog.fileAnalysisSettingOpts = opts;
        },
        submitFileAnalysisSettingDialog () {
            let self = this;
            if(!this.setting.saveMessage){
                this.setting.saveMessage = this.$message({
                    dangerouslyUseHTMLString: true,
                    message: '&nbsp;&nbsp;保存中...',
                    iconClass: 'el-icon-loading',
                });
            }
            let url = this.$ajax.format(projectApi.updateProjectsSettingInfo,{id: this.info.projectId})
            this.$ajax.put(url,{analysisSettings: this.fileAnalysisSettingDialog.fileAnalysisSettingOpts}).then(res=>{
                if(res.status === 200){
                    // this.$message.success('修改成功');
                    this.setting.detail.analysisSettings = this.fileAnalysisSettingDialog.fileAnalysisSettingOpts;
                }
            }).finally(()=>{
                setTimeout(()=>{
                    self.setting.saveMessage.close();
                    self.setting.saveMessage = null;
                }, 500);
            });
        },
        
    },
    mounted(){
        /* if(this.userInfo.organizationType === 'ENTERPRISE'){
            this.pageSize = 10;
        } */
        if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType) < 0) {
            this.hasPdf = true;
            this.upload.accept = `.docx,.doc,.rtf,.xlsx,.xls,.ppt,.pptx,.srt,.txt,.csv,.xliff,.xlf,.sdlxliff,.sdlxlf,.pdf,.zip,.rar,.7z,.html,.htm,.dita,.idml,.ditamap`;
            this.upload.supportFiles = `支持docx/doc/rtf/xlsx/xls/ppt/pptx/srt/txt/csv/xliff/xlf/sdlxliff/sdlxlf/pdf/zip/rar/7z/html/htm/dita/idml/ditamap格式的文件`;
        }
        this.initPage('init');
        
    },
    destroyed(){
        clearInterval(this.listTimer);
    },
} 
</script>
<style lang="scss" scoped>
.project-list-page{
    // padding:20px;
    ::v-deep .el-table {
        .el-table__fixed::before, .el-table__fixed-right::before {
            height: 0px;
            background-color: transparent;
        }
         &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
    .more-info {
        font-size: 20px;
    }
    .filter-tabs{
        height: 100%;
        ::v-deep .el-tabs{
            height: 100%;
            display: flex;
            flex-direction: column;
            .el-tabs__header{
                // padding: 0 20px;
                margin-bottom: 0;
                
            }
            .el-tabs__content{
                flex: 1;
                padding: 20px;
                overflow-y: auto;
            }
        }
        ::v-deep .el-tabs--top{ 
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
        }
        ::v-deep .el-table .cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        ::v-deep .el-input-number__decrease{
            background: rgb(248, 248, 248);
        }
        ::v-deep .el-input-number__increase{
            background: rgb(248, 248, 248);
        }
        .project-info{
            .project-name{
                line-height: 40px;
                font-size: 15px;
                font-weight: 600;
                // padding: 0 14px;
                box-sizing: border-box;
                // border: 1px solid transparent;
                border-radius: 4px;
                cursor: pointer;
                // &:hover{
                //     border-color: #e6e6e6;
                // }
            }
            ::v-deep .el-input__inner{
                // border: none;
                font-size: 15px;
                font-weight: 600;
                color: #000000;
            }
        }
        .save-toast{
            position: absolute;
            left: 50%;
            top: 10px;
            right: 10px;
            font-size: 12px;
            z-index: 10;
        }
        .qa-setting-panel{
            background-color: #FFFFFF;
            .option-wrapper {
                box-sizing: border-box;
                .block-title{
                    font-size: 14px;
                    font-weight: 600;
                    padding: 15px 30px 0 30px;
                }
                .option-list{
                    display: grid;
                    padding: 15px 30px;
                    grid-gap: 0px;
                    grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
                    // flex-wrap: wrap;
                    .item{
                        // width: 7%;
                        padding: 10px 0;
                        word-break: break-all;
                        word-wrap: break-word;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            
        }
    }
    
}
.setting-engine-table{
    th, td {
        text-align: left;
        padding: 10px;
    }
    th {
        font-size: 12px;
        &:first-child {
            padding-left: 0;
        }
    }
    td {
        font-size: 15px;
        &:first-child {
            padding-left: 0;
        }
    }
}
.search-content{
    display: flex;
    justify-content: flex-end;
}
.none-list-block{
    margin-top: 135px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .none-files-content{
        margin-bottom: 50px;
        width: 460px;
        height: 460px;
        background-color: #F2F2F2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        > div.info{
            margin:46px 0 20px 0;
            font-size: 15px;
            line-height: 24px;
            color: #00000066;
        }
        > svg{
            font-size: 96px;
            color: #222222;
        }
        /* > .el-button{
            background: initial;
        } */
    }
}
.file-list{
    margin-top: 30px;
    padding: 0 90px;
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
    // flex-wrap: wrap;
    // justify-content: center;
    > .file-card{
        padding: 0 15px;
        min-width: 200px;
        background: #FFFFFF;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        cursor: pointer;
        > .file-type-content{
            margin-top: 30px;
            display: flex;
            justify-content: center;
            > .file-type{
                width: 96px;
                height: 96px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                flex-direction: column;
                
                > .file-status{
                    font-size: 12px;
                    margin-top: 10px;
                    color: #00000080;
                }
            }
        }
        > .file-name{
            width: 100%;
            text-align: center;
            margin-top: 20px;
            font-weight: 500;
            font-size: 16px;
            color: #222222;
            overflow:hidden; 
            text-overflow:ellipsis; 
            white-space:nowrap;
        }
        > .upload-time{
            font-size: 13px;
            line-height: 18px;
            color: #22222280;
            margin-top: 4px;
            text-align: center;
        }
        > .translate-info{
            display: flex;
            font-size: 16px;
            color: #222222;
            margin-top: 10px;
            > .word-count{
                flex: 1;
                line-height: 24px;
                > span{
                    > span{
                        font-size: 14px;
                        color: rgba(34, 34, 34, 0.4);
                        margin-left: 5px;
                    }
                }
            }
            > .translate-type{
                flex: 1;
                line-height: 24px;
                font-size: 14px;
                text-align: right;
                > span{
                    > span{
                        margin: 0 8px;
                        > i{
                            font-size: 7px;
                            color: #22222266;
                        }
                    }
                }
            }
        }
        > .translate-progress{
            margin-top: 20px;
        }
        > .file-actions{
            margin-top: 10px;
            display: flex;
            line-height: 24px;
            margin-bottom: 10px;
            > .progress{
                font-size: 13px;
                color: #222222;
            }
            > .actions{
                flex:1;
                text-align: right;
                > span{
                    cursor: pointer;
                    margin-left: 20px;
                    i{
                        color: #22222266;
                        font-size: 20px;
                        &:hover{
                            color: #222222;
                        }
                    }
                }
            }
        }
    }
    
}

.mem-matching-setting{
    ::v-deep .el-input__inner{
        border-radius: 50px;
    }
}


</style>
