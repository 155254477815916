<template>
    <div class="task-table-list">
        <el-table ref="taskTable" :data="list"  @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column type="selection" header-align="center" align="center" fixed width="50"></el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_1')" min-width="330" fixed :show-overflow-tooltip="false">
                <template slot-scope="scope">
                    <div class="flex min-w-0 flex-align-center">
                    <div class="file-icon">
                        <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + scope.row.taskFileType] ? config.FILE_TYPE_MATCH['.' + scope.row.taskFileType].icon : ''}`"></svg-icon>
                    </div>
                    <div class="flex-1 text-omission">
                        <el-tooltip placement="top-start" >
                            <div class="fonts-12 line-height-1.5" slot="content">
                                {{$t('project.task_page_card_item_label_1')}}{{scope.row.taskName}}<template v-if="scope.row.startTime"><br>{{$t('project.task_page_card_item_label_2')}}{{scope.row.startTime}}</template>
                            </div>
                            <span>
                                <template v-if="['ASSIGNED', 'INVALID'].includes(scope.row.status)">{{ scope.row.taskName }}</template>
                                <template v-else>
                                    <router-link :to="`/workbench/editer?id=${scope.row.documentId}&taskId=${scope.row.taskId}`" class="table-link">{{ scope.row.taskName }}</router-link>
                                </template>
                            </span>
                        </el-tooltip>
                    </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_2')" prop="sourceWords"  min-width="170">
                <template slot-scope="scope">
                    <span :class="{'rejected': scope.row.returned}">{{ scope.row.taskTypeDesc }}{{scope.row.returned ? $t('project.task_page_table_colum_tips_2') : ''}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_3')" min-width="110">
                <template slot-scope="scope">
                    {{scope.row.splitRange.startSentenceIndex}}-{{scope.row.splitRange.endSentenceIndex}} {{$t('project.task_page_table_colum_label_3')}}
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_4')" width="200" prop="projectName" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_5')" min-width="110">
                <template slot-scope="scope">
                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_6')" min-width="110">
                <template slot-scope="scope">
                    {{scope.row.sourceWords}}<span v-if="scope.row.sourceWordsByOffice">（{{scope.row.sourceWordsByOffice}}）</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_12')" min-width="110">
                <template slot-scope="scope">
                    <template v-if="['REJECTED'].indexOf(scope.row.status) === -1">
                        <el-tooltip :content="$t('project.task_page_table_colum_tips_1')" placement="top">
                            <el-button type="text" class="button-text" @click="$emit('action', 'showOffer', [scope.row])"><svg-icon name="ib-rmb"></svg-icon></el-button>
                        </el-tooltip>
                    </template>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="price" :label="$t('project.task_page_table_colum_label_9')" min-width="70">
            </el-table-column>
            <el-table-column prop="totalPrice" :label="$t('project.task_page_table_colum_label_10')" min-width="70">
            </el-table-column> -->
            <el-table-column :label="$t('project.task_page_table_colum_label_7')" prop="sourceWords" min-width="170">
                <template slot-scope="scope">
                    <span :class="{'color-error': scope.row.overdue}">{{scope.row.deadlineTime || '—'}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_11')" min-width="80">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === 'IN_PROGRESS'">{{scope.row.progress}}%</span>
                    <template v-else>
                        <span :class="scope.row.status">{{scope.row.statusDesc}}</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.task_page_table_colum_label_8')" header-align="center" align="center" fixed="right" width="190">
                <template slot-scope="scope">
                    <div class="flex flex-align-center">
                    <div class="lanx-btn-group" style="width: 150px;" v-if="scope.row.status === 'ASSIGNED'">
                        <el-button type="text" size="small" @click.stop="$emit('action', 'refuse', [scope.row])" v-permission="`tasks:refuse`" style="color: #808080;font-size: 12px">{{$t('project.task_page_card_item_label_5')}}</el-button>
                        <el-button type="text" size="small" @click.stop="$emit('action', 'accept', [scope.row])" v-permission="`tasks:toAccept`" style="font-size: 12px">{{$t('project.task_page_card_item_label_6')}}</el-button>
                    </div>
                    <div class="lanx-btn-group" style="width: 150px;" v-if="scope.row.status === 'IN_PROGRESS'">
                        <el-button type="text" size="small" @click.stop="$emit('action', 'export', [scope.row])" v-permission="``" style="color: #808080;font-size: 12px">{{$t('project.task_page_card_item_label_8')}}</el-button>
                        <el-button type="text" size="small" @click.stop="$emit('action', 'import', [scope.row])" v-permission="``" style="font-size: 12px">{{$t('project.task_page_card_item_label_9')}}</el-button>
                    </div>
                    <template v-if="scope.row.pkFailureCode">
                        <el-tooltip :content="`${$t('project.task_page_table_colum_tips_3')}${config.TASK_IMPORT_ERROR_OPTS[scope.row.pkFailureCode]}`" placement="top">
                            <svg-icon name="ib-warning-o" className="margin-l-5 color-error"></svg-icon>
                        </el-tooltip>
                    </template>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        
    </div>
</template>
<script>
import { commonApi } from '@/utils/api';
import config from '@/utils/config';
import LanguageDirection from '@/components/LanguageDirection';
export default {
    components: {
        LanguageDirection,
    },
    props: {
        list: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            config,
            
        }
    },
    methods: {
        handleSelectionChange(selection) {
            this.$emit('action', 'updateSelected', selection)
        },
        resetTableSelected() {
            this.$refs.taskTable.clearSelection();
        },
        
    },
}
</script>
<style lang="scss" scoped>
.task-table-list {
    .table-link {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
    }
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
    .task-progress {
        display: inline-block;
        width: 198px;
        position: relative;
        .percentage {
            width: 100%;
            position: absolute;
            top: 0;
            line-height: 20px;
            text-align: center;
            color: #1AC49C;
            font-weight: 500;
        }
    }
    .TO_START {
        color: #FE8E0B;
    }
    .IN_PROGRESS {
        color: #2979FF;
    }
    .COMPLETED,
    .FINISHED {
        color: #00B670;
    }
    .ASSIGNED {
        color: #FF9900;
    }
    .REJECTED {
        color: #999999;
    }
    .rejected {
        color: #F56C6C;
        // background-color: #FDE2E2;
    }
    .progress-text {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.file-icon {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #F8F8F8;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 12px;
}
</style>
