<template>
    <div class="left-aside-compontent">
        <div class="logo-content">
            <img src="../../static/images/logo.png" alt="logo"/>
            <div class="child-title">{{ $t('component.LeftAside_slogan') }}</div>
        </div>
        <div class="nav-content">
            <div class="flex-1 margin-x-10">
                <template v-for="(item,index) in menuData">
                    <router-link :to="item.path" class="item" :class="{'active': activePath && item.path.indexOf(activePath) === 0}" :key="index" v-permission="item.permission" @click="itemClick(item)">
                        <div class="icon-content">
                            <template v-if="activePath && item.path.indexOf(activePath) === 0">
                                <svg-icon :name="item.aIcon" v-if="item.aIcon"></svg-icon>
                                <svg-icon :name="item.icon" v-else></svg-icon>
                            </template>
                            <svg-icon :name="item.icon" v-else></svg-icon>
                        </div>
                        <div class="text-content">{{item.title}}</div>
                    </router-link>
                </template>
                <el-dropdown @command="handleCommand" placement="right">
                    <div class="item" style="margin-bottom:0;">
                        <div class="icon-content"><svg-icon name="ib-help-o"></svg-icon></div>
                        <div class="text-content">{{ $t('component.LeftAside_menu_item_help') }}</div>
                    </div>
                    <el-dropdown-menu>
                        <el-dropdown-item command="tohelp">
                            <svg-icon name="ib-help-o" className="fonts-14"></svg-icon>
                            {{ $t('component.LeftAside_menu_item_help_child_1') }}
                        </el-dropdown-item>
                        <el-dropdown-item command="feedback">
                            <svg-icon name="ib-feedback" className="fonts-14"></svg-icon>
                            {{ $t('component.LeftAside_menu_item_help_child_2') }}
                        </el-dropdown-item>
                        <el-dropdown-item command="community">
                            <svg-icon name="ib-community" className="fonts-14"></svg-icon>
                            {{ $t('component.LeftAside_menu_item_help_child_3') }}
                        </el-dropdown-item>
                        <el-dropdown-item command="invoicing">
                            <svg-icon name="ib-invoicing" className="fonts-14"></svg-icon>
                            {{ $t('component.LeftAside_menu_item_help_child_4') }}
                        </el-dropdown-item>
                        <el-dropdown-item command="zh-CN" divided>简体中文</el-dropdown-item>
                        <el-dropdown-item command="en-US">English</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="org-list" v-if="myOrgList.length > 0" >
            <template v-for="(org, index) in myOrgList">
                <div class="user-content" :class="{'active': org.isSelected}" :key="index" v-if="index < 2">
                    <div v-if="!org.isSelected">
                        <div class="user-name" @click="transferOrgEvent(org)">
                            <div class="relative">
                                <el-avatar class="avatar" :size="32" :src="org.avatar" :icon="org.organizationType === 'ENTERPRISE' ? 'el-icon-suitcase' : 'el-icon-user'"></el-avatar>
                                <div class="vip-icon">
                                    <vip-icon :size="14" :type="org.accountType"></vip-icon>
                                </div>
                            </div>
                            <div class="name">{{org.organizationType === 'ENTERPRISE' ? org.organizationName : org.name}}</div>
                        </div>
                    </div>
                    <el-dropdown v-else trigger="click" placement="right">
                        <div class="user-name" id="selected-user-name">
                            <div class="relative">
                                <el-avatar class="avatar" :size="32" :src="org.avatar" :icon="org.organizationType === 'ENTERPRISE' ? 'el-icon-suitcase' : 'el-icon-user'"></el-avatar>
                                <div class="vip-icon">
                                    <vip-icon :size="14" :type="org.accountType"></vip-icon>
                                </div>
                            </div>
                            <div class="name">{{org.organizationType === 'ENTERPRISE' ? org.organizationName : org.name}}</div>
                        </div>
                        <el-dropdown-menu class="user-avatar-dropdown" slot="dropdown" v-if="userInfo && org.isSelected">
                            <div class="flex flex-align-center margin-15">
                                <el-avatar class="avatar" :size="44" :src="activeOrg.avatar" :icon="activeOrg.organizationType === 'ENTERPRISE' ? 'el-icon-suitcase' : 'el-icon-user'"></el-avatar>
                                <div class="margin-l-10 min-w-0 flex-1">
                                    <div class="fonts-14 text-weight-600 text-omission">{{ activeOrg.name }}</div>
                                    <div class="fonts-12 color-666666 margin-t-8">{{ activeOrg.organizationName }}</div>
                                </div>
                            </div>
                            <div class="vip-wapper pro" @click="handlerUserCommandClick('goVip')">
                                <div class="flex flex-align-center flex-justify-between">
                                    <div class="flex flex-align-center">
                                        <vip-icon :size="16" :type="activeOrg.organizationType === 'ENTERPRISE' ? 'enterprise' : userInfo.accountType"></vip-icon>
                                        <span class="margin-l-4 text-weight-600 fonts-14">{{ activeOrg.accountTypeName }}</span>
                                        <span class="margin-l-8 color-warning fonts-12" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) < 0 && userInfo.expireTime">{{moment(userInfo.expireTime).format($t('component.UserAvatarMenu_expireTime_format'))}} {{$t('component.UserAvatarMenu_expireTime_label')}}</span>
                                        <span class="margin-l-8 color-warning fonts-12" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">{{ $t('component.UserAvatarMenu_upgrade_to_pro') }}</span>
                                    </div>
                                    <svg-icon :size="15" name="ib-arrow-right" class-name="color-666666" v-permission:delay="`account:VIPRouter`"></svg-icon>
                                </div>
                                <div class="margin-t-10 fonts-12" v-if="activeOrg.organizationType === 'ENTERPRISE' && userInfo.memberQuota">
                                    <span>{{ $t('component.UserAvatarMenu_info_label_1') }}</span>
                                    <span>{{userInfo.memberQuota.used}}</span>
                                    <span class="color-999">/{{userInfo.memberQuota.limit}}</span>
                                </div>
                                <div class="margin-t-10 fonts-12" v-if="userInfo.flowQuota">
                                    <span>{{ $t('component.UserAvatarMenu_info_label_2') }}</span>
                                    <span>{{userInfo.flowQuota.surplus}}</span>
                                    <span class="color-999">/{{userInfo.flowQuota.limit}}</span>
                                </div>
                                <div class="margin-t-10 fonts-12" v-if="userInfo.flowQuota">
                                    <span>{{ $t('component.UserAvatarMenu_info_label_3') }}</span>
                                    <span>{{userInfo.tokenQuota.surplus}}</span>
                                    <span class="color-999">/{{userInfo.tokenQuota.limit}}</span>
                                </div>
                            </div>
                            <div class="menu-list">
                                <div class="item divider" v-permission:delay="`account`" @click="handlerUserCommandClick('goUser')">
                                    <svg-icon name="ib-user" class-name="fonts-18 margin-l-15"></svg-icon>
                                    <span class="margin-l-10">{{ $t('component.UserAvatarMenu_button_user_center') }}</span>
                                </div>
                                <div class="item" @click="handlerUserCommandClick('logout')">
                                    <svg-icon name="ib-exit" class-name="fonts-18 margin-l-15"></svg-icon>
                                    <span class="margin-l-10">{{ $t('component.UserAvatarMenu_button_logout') }}</span>
                                </div>
                            </div>
                        </el-dropdown-menu>
                    </el-dropdown>
         
                </div>
            </template>
            
            <div class="more-content" v-if="myOrgList.length > 2">
                <el-dropdown class="width-full" @command="handleMoreCommand" placement="right" trigger="click">
                    <span class="color-white fonts-18">
                    <svg-icon name="ib-more"></svg-icon>
                    </span>
                    <el-dropdown-menu class="padding-0 radius-10 transfer-dropdown" style="width: 170px;" slot="dropdown">
                        <template v-for="(org, index) in myOrgList">
                            <template v-if="index > 1">
                                <el-dropdown-item class="padding-y-8" :command="org" :key="index">
                                    <div class="more-org-item">
                                        <div class="relative" style="width: 32px;height: 32px">
                                            <el-avatar class="avatar" :size="32" :src="org.avatar" icon="el-icon-suitcase"></el-avatar>
                                            <div class="vip-icon">
                                                <vip-icon :size="14" :type="org.accountType"></vip-icon>
                                            </div>
                                        </div>
                                        <div class="margin-l-10 min-w-0">
                                            <div class="name">{{org.organizationType === 'ENTERPRISE' ? org.organizationName : org.name}}</div>
                                        </div>
                                    </div>
                                </el-dropdown-item>
                            </template>
                        </template>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!-- <div class="org-list" v-else>
            <div class="user-content active" >
                <el-dropdown trigger="hover" placement="right">
                        <div class="user-name" id="selected-user-name">
                            <div class="relative">
                                <el-avatar class="avatar" :size="32" :src="activeOrg.avatar" :icon="activeOrg.organizationType === 'ENTERPRISE' ? 'el-icon-suitcase' : 'el-icon-user'"></el-avatar>
                                <div class="vip-icon">
                                    <vip-icon :size="14" :type="activeOrg.accountType"></vip-icon>
                                </div>
                            </div>
                            <div class="name">{{activeOrg.organizationType === 'ENTERPRISE' ? activeOrg.organizationName : activeOrg.name}}</div>
                        </div>
                        <el-dropdown-menu class="user-avatar-dropdown" slot="dropdown" v-if="userInfo">
                            <div class="flex flex-align-center margin-15">
                                <el-avatar class="avatar" :size="44" :src="activeOrg.avatar" :icon="activeOrg.organizationType === 'ENTERPRISE' ? 'el-icon-suitcase' : 'el-icon-user'"></el-avatar>
                                <div class="margin-l-10 min-w-0 flex-1">
                                    <div class="fonts-14 text-weight-600 text-omission">{{ activeOrg.name }}</div>
                                    <div class="fonts-12 color-666666 margin-t-8">{{ activeOrg.organizationName }}</div>
                                </div>
                            </div>
                            <div class="vip-wapper pro" @click="handlerUserCommandClick('goVip')">
                                <div class="flex flex-align-center flex-justify-between">
                                    <div class="flex flex-align-center">
                                        <vip-icon :size="16" :type="activeOrg.organizationType === 'ENTERPRISE' ? 'enterprise' : userInfo.accountType"></vip-icon>
                                        <span class="margin-l-4 text-weight-600 fonts-14">{{ activeOrg.accountTypeName }}</span>
                                        <span class="margin-l-8 color-warning fonts-12" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) < 0 && userInfo.expireTime">{{moment(userInfo.expireTime).format($t('component.UserAvatarMenu_expireTime_format'))}} {{$t('component.UserAvatarMenu_expireTime_label')}}</span>
                                        <span class="margin-l-8 color-warning fonts-12" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">{{ $t('component.UserAvatarMenu_upgrade_to_pro') }}</span>
                                    </div>
                                    <svg-icon :size="15" name="ib-arrow-right" class-name="color-666666" v-permission:delay="`account:VIPRouter`"></svg-icon>
                                </div>
                                <div class="margin-t-10 fonts-12" v-if="activeOrg.organizationType === 'ENTERPRISE' && userInfo.memberQuota">
                                    <span>{{ $t('component.UserAvatarMenu_info_label_1') }}</span>
                                    <span>{{userInfo.memberQuota.used}}</span>
                                    <span class="color-999">/{{userInfo.memberQuota.limit}}</span>
                                </div>
                                <div class="margin-t-10 fonts-12" v-if="userInfo.flowQuota">
                                    <span>{{ $t('component.UserAvatarMenu_info_label_2') }}</span>
                                    <span>{{userInfo.flowQuota.surplus}}</span>
                                    <span class="color-999">/{{userInfo.flowQuota.limit}}</span>
                                </div>
                            </div>
                            <div class="menu-list">
                                <div class="item divider" v-permission:delay="`account`" @click="handlerUserCommandClick('goUser')">
                                    <svg-icon name="ib-user" class-name="fonts-18 margin-l-15"></svg-icon>
                                    <span class="margin-l-10">{{ $t('component.UserAvatarMenu_button_user_center') }}</span>
                                </div>
                                <div class="item" @click="handlerUserCommandClick('logout')">
                                    <svg-icon name="ib-exit" class-name="fonts-18 margin-l-15"></svg-icon>
                                    <span class="margin-l-10">{{ $t('component.UserAvatarMenu_button_logout') }}</span>
                                </div>
                            </div>
                        </el-dropdown-menu>
                    </el-dropdown>
            </div>
        </div> -->
    </div>
</template>
<script>
import moment from 'moment';
import $ from 'jquery';
import { mapState,mapGetters } from 'vuex';
import {i18n, setLocale} from '@/assets/i18n'
import { userApi, orgApi } from '@/utils/api';
import tools from '@/utils/tools';
import VipIcon from "@/components/icon/VipIcon";
import UserAvatarMenu from '@/components/UserAvatarMenu';

export default {
    name:"LeftAside",
    components:{ VipIcon, UserAvatarMenu},
    data(){
        return {
            menuData: [],//config.MENU_DATA,
            activePath:'',
            userPopoverVisible: false,
            moment,
            isUserContent:false,
        }
    },
    computed:{
        ...mapGetters({
            iconName:"user/iconName",
            isLogin:'user/loginStatus',
            userData: 'user/userInfo',
        }),
        ...mapState({
			userInfo: state => state.user.userData,
            // myOrgList: state => state.org.myOrgList,
            activeOrg: state => state.org.activeOrg,
		}),
        myOrgList() {
        const myOrgList = this.$store.state.org.myOrgList;
        if (myOrgList.length === 0) {
            return [{
                ...this.activeOrg,
                isSelected: true
            }];
        }
        return myOrgList;
    }
    },
    methods:{
        initMenus(){
            // console.log('userData:::', this.userData);
            this.$store.dispatch('user/initUserPermissions').then(res=>{
                let projectPath = '/project/index';
                if (this.userInfo.organizationType === 'ENTERPRISE' && this.userInfo.myRole.role === 'TRANSLATOR') {
                    projectPath = '/task/index';
                }
                let teamPath = '/team/index';
                if (this.userInfo.internal) {
                    teamPath = '/team/index-inside';
                }
                let menus = [];
                menus.push({title: this.$t('component.LeftAside_menu_item_engine'),route:'EngineConfig', path: '/engine/config', icon:'ib-robot', permission:'engine'});
                menus.push({title: this.$t('component.LeftAside_menu_item_project'),route:'ProjectList', path: '/project/index', icon:'ib-translate-o', permission:'project'});
                menus.push({title: this.$t('component.LeftAside_menu_item_task'),route:'TaskList', path: '/task/list/index', icon:'ib-task', permission:'tasks'});
                menus.push({title: this.$t('component.LeftAside_menu_item_asset'),route:'TermIndex', path: '/asset/memory/index', icon:'ib-translate-asset', permission:'memory'});
                menus.push({title: this.$t('component.LeftAside_menu_item_tools'),route:'ToolsIndex', path: '/tools', icon:'ib-toolbox', permission:'tools'});
                menus.push({title: this.$t('component.LeftAside_menu_item_agent'),route:'AgentGptTranslateIndex', path: '/agent/gptTranslate/index', icon:'ib-bot', aIcon:'ib-bot-a', permission:''});
                menus.push({title: this.$t('component.LeftAside_menu_item_team'),route:'TeamIndex', path: teamPath, icon:'ib-invite-1', permission:'team'});
                
                this.menuData = menus;
            });
        },
        setActivePath(path){
            let paths=path.split('/');
            this.activePath = '/'+paths[1];
        },
        itemClick(item){
            if(item.type === 'jump'){
                window.open(item.path, '_black');
            }else{
                this.$router.push(item.path);
            }
        },
        handleCommand(command){
            let self = this;
            if(command === 'logout'){
                let url= userApi.logout;
                this.$ajax.delete(url,{}).then(res=>{
                    if(res.status === 200){
                        localStorage.removeItem("userInfo");
                        setTimeout(()=>{
                            // location.reload(true);
                            self.$router.replace('/login');
                        },500);
                    }
                });
            }else if(command === 'changepass'){
                this.changeDialogShow = true;
            }else if(command === 'usercenter'){
                this.$router.push('/account/vip');
            }else if(command === 'tohelp'){
                window.open('https://m15er90ta0.feishu.cn/docs/doccnpnEA2swEXwJ8yyeGs2sZFb','_blank');
            }else if(command === 'feedback'){
                window.open('https://m15er90ta0.feishu.cn/share/base/form/shrcnHaLlI7xeeD1FNZ6nyLHAW7','_blank') //https://support.qq.com/products/413842/
            }else if(command === 'community'){
                window.open('https://support.qq.com/products/413842/','_blank');
            }else if(command === 'website'){
                window.open('https://www.languagex.com','_blank')
            }else if(command === 'invoicing'){
                window.open('https://m15er90ta0.feishu.cn/share/base/form/shrcnt4Z1ziC7IVeP7AuTq8ffDc', '_blank');
            }else if(command === 'zh-CN' || command === 'en-US'){
                setLocale(command);
            }
        },
        handlerUserCommandClick(type){
            this.isUserContent = true;
            let self = this;
            switch (type){
                case "goVip":
                    if (this.$checkPermission('account:VIPRouter')){
                        this.$router.push('/account/vip');
                    }
                    break;
                case "goUser":
                    this.$router.push('/account/index');
                    break;
                case "logout":
                    let url= userApi.logout;
                    this.$ajax.delete(url,{}).then(res=>{
                        if(res.status === 200){
                            localStorage.removeItem("userInfo");
                            setTimeout(()=>{
                                location.replace('/login');
                            },500);
                        }
                    });
                    break;
            }
            this.closeMenu();
        },
        closeMenu(){
            $('#selected-user-name').trigger('click');
        },
        handleMoreCommand(command){
            // console.log('handleMoreCommand::', command);
            this.transferOrgEvent(command);
        },
        
        async transferOrgEvent(org){

            if (org.isSelected) {
                console.log("selected");
                return;
            }
            console.log("not");
            let url = orgApi.transferOrg;
            let postData = {
                organizationId: org.organizationId,
                // organizationTypeId: org.organizationType,
            };
            const changeMessage = this.$message({
                type: 'success',
                message: `正在切换组织...`,
                iconClass: 'el-icon-loading',
            });
            await tools.sleep(1000);
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$nextTick(() => {
                        location.replace('/');
                    });
                }
            }).finally(() => {
                changeMessage.close();
            })
        }
    },
    async created(){
        /**初始化配置数据 */
        let isLogin = localStorage.getItem('userInfo') !==null && this.$cookies.get('token')!==null;
        if(isLogin){
            //引擎信息
            this.$store.dispatch('common/initEngineConfigAction');
            //用户信息
            await this.$store.dispatch('user/queryUserInfoAction');
            //我的机构信息
            this.$store.dispatch('org/queryMyOrgList');
            // 语言信息
            this.$store.dispatch('common/initLanguageDictAction');
            this.initMenus();
        }
    },
    mounted(){
        this.setActivePath(this.$route.path);
    },
    watch:{
        "$route" (to, from){
            this.setActivePath(to.path);
            // this.$store.dispatch('common/initUploadTokenAction');
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
$nav-default-color:#ffffff;
$nav-active-color:#00D959;
$nav-active-back-color:#000000;
$child-title-color:#ffffff66;
.left-aside-compontent{
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    border-radius: 0px 40px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.03);
    overflow: hidden;
    .logo-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
        img{
            width: 90px;
            height: 15px;
            margin-bottom: 10px;
            margin-top: 30px;
        }
        .child-title{
            text-align: center;
            font-size: 12px;
            color: $child-title-color;
        }
    }
    .nav-content{
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        .item{
            transition: color .3s,background-color .3s; 
            color: $nav-default-color;
            font-weight: normal;
            border-radius: 50px;
            cursor: pointer;
            // width: 104px;
            height: 40px;
            min-height: 40px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            // justify-content: center;
            margin-bottom: 10px;
            &:hover{
                color: $nav-active-color;
                background-color: #0000004D;
            }
            &.active{
                background-color: $nav-active-back-color;
                color: $nav-active-color;
            }
            .icon-content{
                font-size: 22px;
            }
            .text-content{
                font-size: 14px;
                margin-left: 5px;
            }
        }
    }
    .nav-content::-webkit-scrollbar,
    .nav-content::-webkit-scrollbar-track{
        background: transparent;
        width: 4px;
    }
    .nav-content::-webkit-scrollbar-button {
        background: transparent;
        height: 0px;
    }
    .nav-content::-webkit-scrollbar-thumb {
        background:#000000;
    }
    .org-list{
        padding: 20px 0px;
        // border-top: 1px solid #000000;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .more-content{
            text-align: center;
            color: #ffffff;
            font-size: 18px;
            cursor: pointer;
        }
    }
    .user-content{
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        // justify-content: center;
        // line-height: 32px;
        cursor: pointer;
        min-width: 0;
        .user-name {
            display: flex;
            align-items: center;
            padding-left: 15px;
            padding-right: 17px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.active{
            // cursor: default;
            .avatar{
                border-color: #63D569;
            }
            .name{
                color: #63D569;
            }
        }
        .avatar{
            background: #000;
            color: #63D569;
            border: 1px solid transparent;
            box-sizing: border-box;
            i {
                margin-right: 0;
            }
        }
        .name{
            width: 50px;
            margin-left: 10px;
            //margin-right: 10px;
            font-size: 12px;
            line-height: 1.2;
            color: #ffffff;
            overflow:hidden; 
            text-overflow:ellipsis; 
            white-space:nowrap;
        }
        .vip-icon{
            position: absolute;
            bottom:-2px;
            right: -2px;
        }
    }
    
    .change-password-form-content{
        padding-right: 30px;
        .tips{
            margin-bottom: 20px;
            text-align: center;
            color: #F56C6C;
            font-size: 14px;
        }
        .actions{
            margin-top: 30px;
            text-align: center;

        }
    }
}
.more-org-item{
    display: flex;
    align-items: center;
    line-height: normal;
    // img {
    //     width: 32px;
    //     height: 32px;
    //     border-radius: 50%;
    // }
    .avatar{
        color: #63D569;
        // background:#000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .vip-icon{
        position: absolute;
        bottom: -6px;
        right: -2px;
    }
    .name {
        font-size: 13px;
        line-height: 1.2;
        // font-weight: 600;
        color: #2C2C2E;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.transfer-dropdown{
    ::v-deep .el-dropdown-menu__item {
        i {
            margin: 0;
            font-size: 18px;
        }
        &:first-child {
            border-radius: 10px 10px 0 0;
        }
        &:last-child {
            border-radius: 0 0 10px 10px;
        }
    }
}
</style>
<style lang="scss">
.user-popover{
    padding: 0 !important;
    .popover-wrapper{
        .user-content{
            padding: 20px;
            border-bottom: 1px solid #f2f2f2;
            box-sizing: border-box;
            
            img{
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }
        }
        .menu-countent{
            margin-bottom: 10px;
            .item{
                font-size: 16px;
                color: #666666;
                padding: 0 20px 0 42px;
                height: 48px;
                line-height: 48px;
                cursor: pointer;
                transition: background-color linear .2s;
                &:hover{
                    background-color: #f2f2f2;
                    color: #000;
                }
                i{
                    margin-right: 15px;
                }
            }
        }
    }
}
.user-avatar-dropdown{
    width: 270px;
    // min-height: 350px;
    border-radius: 8px;
    padding: 20px 15px 10px 15px;
    box-sizing: border-box;
    .vip-wapper{
        margin: 10px 15px;
        background-color: #F2F2F2;
        border-radius: 8px;
        padding: 15px;
        cursor: pointer;
        &.free {
            background-color: #F2F2F2;
        }
        &.pro {
            background-color: #FDF4E1;
        }
        &.enterprise {
            background-color: #F7F3FB;
        }

    }
    .menu-list{
        margin-top: 10px;

        .item{
            display: flex;
            align-items: center;
            height: 40px;
            background-color: transparent;
            color: #666666;
            box-sizing: border-box;
            font-size: 12px;
            cursor: pointer;
            &:hover {
                border-radius: 8px;
                background-color: #F2F2F2;
                color: #000000;
            }
            &.divider{
                border-bottom: 1px solid #e6e6e6;
            }
            ::v-deep .el-popover__reference-wrapper{
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                // display: block;
            }
        }
    }
}
</style>
