<template>
    <div class="project-template-page">
        <div class="page-tools-content" style="margin: 0;">
            <div class="left">
                <el-button size="small" plain round icon="el-icon-delete" v-permission="``" @click="deleteBtnEvent">删除</el-button>
                <el-button size="small" plain round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage()">{{$t('project.project_index_page_btn_2')}}</el-button>
            </div>
            <div class="right">
                <div style="width:200px;">
                    <el-input :placeholder="`输入模版名称`" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
            </div>
        </div>
        <el-table :data="list" @selection-change="handleSelectionChange" style="width: 100%;">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column label="项目模板名称" min-width="120"></el-table-column>
          <el-table-column label="语言方向" min-width="120"></el-table-column>
          <el-table-column label="项目流程" min-width="120"></el-table-column>
          <el-table-column label="所属团队" min-width="120"></el-table-column>
          <el-table-column label="创建人" min-width="120"></el-table-column>
          <el-table-column label="创建时间" min-width="120"></el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
                <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command, scope.row)">
                        <div class="fonts-20 cursor-pointer">
                            <svg-icon name="ib-more"></svg-icon>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="editName" v-permission="``">编辑模板名</el-dropdown-item>
                            <el-dropdown-item command="delete" divided v-permission="``" style="color:#F56C6C;">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="`模板重命名`" center :visible.sync="nameDialog.show" destroy-on-close width="480px" top="20px" @close="nameDialog.form.name = ''">
            <el-form class="padding-x-20">
                <el-form-item  required>
                    <el-input :placeholder="`输入模板名称`" v-model="nameDialog.form.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="nameDialog.show = false" round plain size="small">取消</el-button>
                <el-button :loading="nameDialog.loading" @click="saveRenameBtnEvent" round type="primary" size="small" style="margin-left:30px;" >确定</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteConfirmEvent">
        </alert-dialog>
    </div>
</template>
<script>
import AlertDialog from '@/components/AlertDialog.vue';
export default {
    components: {
        AlertDialog
    },
    data() {
        return {
            alertInfo:{
                message: `确定要删除该项目模板吗？`,
                type:'confirm', //alert ,confirm
                buttonName: `确定`,
                buttonType:'danger',
            },
            searchForm: {
                kw: '',
            },
            list: [1],
            total: 0,
            refreshLoading: false,
            pageNumber: 1,
            pageSize: 16,
            searchTimer: null,
            selectedItem: [],
            nameDialog: {
                show: false,
                loading: false,
                param: null,
                form: {
                    name: '',
                }
            }

        }
    },
    methods: {
        initPage() {

        },
        inputSearchEvent (val) {
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                self.initPage();
            },500);
        },
        handleSelectionChange(val) {
            this.selectedItem = val;
        },
        editTemplateName (row) {
            this.nameDialog.param = row;
            this.nameDialog.form.name = row.name;
            this.nameDialog.show = true;
        },
        deleteBtnEvent () {
            if(this.selectedItem.length === 0){
                this.$message.console.error('请选择模板');
                return;
            }
            this.alertInfo.message = '确认删除选中的项目模板吗？';
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteTemplateItem (row) {
            this.selectedItem = [row];
            this.alertInfo.message = '确定要删除该项目模板吗？';
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteConfirmEvent() {

            this.selectedItem = [];
        },
        handleMoreCommand (command, row) {
            switch (command) {
                case 'editName':
                    this.editTemplateName(row);
                    break;
                case 'delete':
                    this.deleteTemplateItem(row);
                    break;
            }
        },
        saveRenameBtnEvent () {
            if (!this.nameDialog.name) {
                this.$message.error('请输入模板名称');
                return;
            }

        },
    },
    mounted() {
        this.initPage();
    }
}
</script>
<style lang="scss" scoped>
.project-template-page{
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
}
</style>
