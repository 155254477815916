import { render, staticRenderFns } from "./TaskStatisticsTableList.vue?vue&type=template&id=8e5b6ae8&scoped=true"
import script from "./TaskStatisticsTableList.vue?vue&type=script&lang=js"
export * from "./TaskStatisticsTableList.vue?vue&type=script&lang=js"
import style0 from "./TaskStatisticsTableList.vue?vue&type=style&index=0&id=8e5b6ae8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e5b6ae8",
  null
  
)

export default component.exports