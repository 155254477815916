<template>
    <div class="task-table-list">
        <div class="flex flex-align-center flex-justify-between padding-y-12">
            <div></div>
            <div>
                <!-- <svg-icon name="ib-download"></svg-icon>  -->
                <el-button @click="handleDownloadBtnEvent" :loading="downloadLoading" plain size="medium" round>下载</el-button>
            </div>
        </div>
        <el-table ref="taskTable" :data="list" row-key="taskId" @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column type="selection" fixed :reserve-selection="true" width="50"></el-table-column>
            <el-table-column :label="`负责人`" fixed prop="assigneeName"></el-table-column>
            <el-table-column :label="`任务名`" fixed min-width="180" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                    <div class="flex min-w-0 flex-align-center">
                    <div class="file-icon">
                        <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + scope.row.docType] ? config.FILE_TYPE_MATCH['.' + scope.row.docType].icon : ''}`"></svg-icon>
                    </div>
                    <div class="flex-1 text-omission">
                        <el-tooltip placement="top-start" :content="scope.row.taskName">
                            <span>
                                <template v-if="scope.row.status === 'ASSIGNED'">{{ scope.row.taskName }}</template>
                                <template v-else>
                                    <router-link :to="`/workbench/editer?id=${scope.row.docId}&taskId=${scope.row.taskId}`" class="table-link">{{ scope.row.taskName }}</router-link>
                                </template>
                            </span>
                        </el-tooltip>
                    </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="`任务类型`" prop="taskTypeDesc" width="100"></el-table-column>
            <el-table-column :label="`语言方向`" min-width="90">
                <template slot-scope="scope">
                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                </template>
            </el-table-column>
            <el-table-column :label="`任务时段`" min-width="170">
                <template slot-scope="scope">
                    <div class="fonts-12">
                        <template v-if="scope.row.acceptTime">
                            {{ scope.row.acceptTime }}
                        </template>
                        <template v-else>
                            <span class="color-999 fonts-12 text-italic">暂无任务开始时间</span>
                        </template>
                        <br/>
                        <template v-if="scope.row.submitTime">{{ scope.row.submitTime }}</template>
                        <template v-else>
                            <span class="color-999 text-italic">暂无任务结束时间</span>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column :label="$t('project.task_statistics_page_table_column_lable_7')" prop="taskStatusDesc" min-width="90"></el-table-column> -->
            <el-table-column :label="`任务进度`" min-width="90">
                <template slot-scope="scope">
                    {{ scope.row.process }}%
                </template>
            </el-table-column>
            <el-table-column :label="`任务字数`" prop="taskWords" min-width="110"></el-table-column>
            <el-table-column :label="`计价字数`" min-width="110">
                <template slot-scope="scope">
                    {{ scope.row.valuationWords || '-' }}
                </template>
            </el-table-column>
            <el-table-column :label="`单价`" min-width="110">
                <template slot-scope="scope">
                    {{ scope.row.price ? `${scope.row.price}/${scope.row.unitDesc}` : '-' }}
                </template>
            </el-table-column>
            <el-table-column :label="`币种`" min-width="90">
                <template slot-scope="scope">
                    {{ scope.row.currencyDesc || '-' }}
                </template>
            </el-table-column>
            <el-table-column :label="`总价`" prop="" min-width="110">
                <template slot-scope="scope">
                    {{ scope.row.totalPrice || '-' }}
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize" >
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import config from '@/utils/config';
import { projectApi, commonApi } from '@/utils/api';
import LanguageDirection from '@/components/LanguageDirection';
export default {
    components: {
        LanguageDirection,
    },
    props: {
        
    },
    data() {
        return {
            config,
            pageNumber: 1,
            pageSize: 20,
            total: 0,
            list: [],
            customized: false,
            selectedList: [],
            project: {},
            downloadLoading: false,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            activeOrg: state => state.org.activeOrg,
        }),
    },
    methods: {
        initTaskStatistics(param) {
            if(param){
                this.project = param;
            }
            let url = `${projectApi.queryTaskStatisticsList}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}&queryType=PROJECT&projectId=${this.project.projectId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.list = res.data.page.records;
                    this.total = res.data.page.total;
                    this.customized = res.data.customized;
                }
            })
        },
        handleSelectionChange(val) {
            this.selectedList = val;
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.$nextTick(()=> {
                this.initTaskStatistics();
            })
        },
        handleDownloadBtnEvent() {
            let ids = this.selectedList.map(item => item.taskId);
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: `任务统计`,
                metadataIdList: [this.userInfo.memberId],
                metadataType: "TMS_TASK_STATISTICS",
                moreOptions: {
                    projectId: this.project.projectId,
                    orgId: this.activeOrg.organizationId,
                    taskIds: ids,
                    queryType: 'PROJECT',
                },
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                    this.$refs.taskTable.clearSelection();
                }
            }).finally(()=>{
                this.downloadLoading = false;
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.task-table-list {
    .table-link {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
    }
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
    .task-progress {
        display: inline-block;
        width: 198px;
        position: relative;
        .percentage {
            width: 100%;
            position: absolute;
            top: 0;
            line-height: 20px;
            text-align: center;
            color: #1AC49C;
            font-weight: 500;
        }
    }
    .TO_START {
        color: #FE8E0B;
    }
    .IN_PROGRESS {
        color: #2979FF;
    }
    .COMPLETED,
    .FINISHED {
        color: #00B670;
    }
    .ASSIGNED {
        color: #FF9900;
    }
    .REJECTED {
        color: #999999;
    }
    .rejected {
        color: #F56C6C;
        // background-color: #FDE2E2;
    }
    .progress-text {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.file-icon {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #F8F8F8;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 12px;
}
</style>
