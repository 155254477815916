<template>
    <el-dialog :title="$t('project.component_CreateTermMultiLanguage_dialog_title')" center :visible.sync="dialog.show" destroy-on-close width="430px" top="20px" @close="handleDialogClose">
        <el-form ref="newDialogForm" size="medium" label-position="top">
            <el-form-item :label="$t('project.component_CreateTermMultiLanguage_dialog_form_item_label_1')" required>
                <el-input :placeholder="$t('project.component_CreateTermMultiLanguage_dialog_form_item_placeholder_1')" v-model="dialog.form.name" style="width: 306px;"></el-input>
            </el-form-item>
            <el-form-item :label="$t('project.component_CreateTermMultiLanguage_dialog_form_item_label_2')" required v-if="userInfo.organizationType === 'ENTERPRISE'">
                <el-select :placeholder="$t('project.component_CreateTermMultiLanguage_dialog_form_item_placeholder_2')" v-model="dialog.form.teamId" style="width:140px;">
                    <template v-for="item in teamOptions">
                        <el-option :key="item.value" :value="item.value" :label="item.text"></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('project.component_CreateTermMultiLanguage_dialog_form_item_label_3')" v-if="userInfo">
                <div class="fonts-12 margin-b-16 line-height-0"><!-- {{userInfo.featureConfig.singleFileSize}} -->
                    {{ $t('project.component_CreateTermMultiLanguage_tips_5', {types: 'xlsx/xls/tbx/sdltb', size: userInfo.featureConfig.singleFileSize}) }} 
                    <el-button type="text" class="color-link padding-0" :disabled="dialog.templateLoading" @click="downloadTemplateEvent">excel</el-button>
                </div>
                <div class="file-list">
                    <template v-for="(item, index) in dialog.form.list">
                        <upload-oss ref="uploadOssDom" class="margin-b-10" style="line-height: 36px;" :key="index" @success="param => uploadSuccess(param, item)" accept=".xls,.xlsx,.tbx,.sdltb" :size="userInfo.featureConfig.singleFileSize" :params="{}" type="TERM">
                            <div class="flex flex-justify-center border-default radius-4 padding-x-20 fonts-12">
                                <div class="text-left" style="min-width: 152px;">
                                    <language-direction :source="item.source" :target="item.target" type="half"></language-direction>
                                </div>
                                <div class="flex-1 text-left padding-x-20 text-omission">
                                    <template v-if="item.object && item.object.fileName">
                                        <el-tooltip :content="item.object.fileName" placement="top-start">
                                            <span>{{item.object.fileName}}</span>
                                        </el-tooltip>
                                    </template>
                                    <template v-else>
                                        <span class="color-link">{{$t('project.component_CreateTermMultiLanguage_tips_6')}}</span>
                                    </template>
                                </div>
                                <div class="status">
                                    <template v-if="item.object && item.object.fileName">
                                        <svg-icon name="ib-check-circle" className="color-success fonts-16"></svg-icon>
                                    </template>
                                </div>
                            </div>
                        </upload-oss>
                    </template>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button round plain size="small" @click="dialog.show = false">{{$t('project.component_CreateTermMultiLanguage_btn_1')}}</el-button>
            <el-button round type="primary" size="small" style="margin-left:30px;" :loading="dialog.loading" @click="saveBtnEvent">{{$t('project.component_CreateTermMultiLanguage_btn_2')}}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import fileDownload from 'js-file-download';
import UploadOss from '@/components/UploadOssBlock';
import LanguageDirection from '@/components/LanguageDirection';
import { jargonApi, termApi, commonApi } from '@/utils/api';
export default {
    components: { UploadOss, LanguageDirection },
    props:{
        
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    data(){
        return{
            dialog:{
                show:false,
                loading:false,
                templateLoading:false,
                form:{
                    name:'',
                    teamId: '',
                    list: [],
                }
            },
            teamOptions: [],
        }
    },
    methods:{
        setShow(param){
            this.dialog.show = true;
            this.dialog.form.list = param.list;
            if(this.userInfo.organizationType === 'ENTERPRISE') {
                this.queryTeamOptions();
            }
        },
        handleDialogClose(){
            this.dialog.form = this.$options.data().dialog.form;
        },
        downloadTemplateEvent(){
            let downMsg = this.$message({
                message: this.$t('project.component_CreateTermMultiLanguage_method_downloadTemplateEvent_message_1'),
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.dialog.templateLoading = true;
            this.$ajax.get(jargonApi.downloadTemplate).then(res=>{
                if(res.status === 200){
                    if(res.data){
                        window.open(res.data);
                    }else {
                        this.$message.error(this.$t('project.component_CreateTermMultiLanguage_method_downloadTemplateEvent_message_2'));
                    }
                }
            }).finally(()=>{
                this.dialog.templateLoading = false;
                downMsg.close();
            })
        },
        saveBtnEvent(){
            if(!this.dialog.form.name){
                this.$message.error(this.$t('project.component_CreateTermMultiLanguage_method_saveBtnEvent_message_1'));
                return;
            }
            let langList = this.dialog.form.list.filter(item => item.object.fileName);
            if(langList.length === 0){
                this.$message.error(this.$t('project.component_CreateTermMultiLanguage_method_saveBtnEvent_message_2'));
                return;
            }
            let postLangList = [];
            langList.map(item=>{
                postLangList.push({
                    sourceLang: item.source,
                    targetLang: item.target,
                    filePath: [item.object],
                })
            });
            let postData = {
                name: this.dialog.form.name,
                teamId: this.dialog.form.teamId,
                assertsCreationRequests: postLangList,
            };
            let url = termApi.createTermMultiLanguage;
            this.dialog.loading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('project.component_CreateTermMultiLanguage_method_saveBtnEvent_message_3'));
                    this.dialog.show = false;
                    this.$emit('success', res.data);
                }
            }).finally(()=>{
                this.dialog.loading = false;
            })

        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.teamOptions = res.data.teamFilters;
                    this.dialog.form.teamId = this.teamOptions[0].value
                }
            });
        },
        uploadSuccess(param, item) {
            console.log(param, item);
            item.object = param;
        }
    },
}
</script>
