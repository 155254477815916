<template>
    <div class="project-tasks-statistics-page">
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content" style="margin: 0; padding: 0;">
            <div class="left">
                <el-tabs class="filter-tabs task-status-tabs" v-model="searchForm.status" @tab-click="filterClickEvent">
                    <el-tab-pane :label="$t('project.task_statistics_page_tab_label_1')" name="ALL"></el-tab-pane>
                    <el-tab-pane :label="$t('project.task_statistics_page_tab_label_2')" name="ASSIGNED"></el-tab-pane>
                    <el-tab-pane :label="$t('project.task_statistics_page_tab_label_3')" name="IN_PROGRESS"></el-tab-pane>
                    <el-tab-pane :label="$t('project.task_statistics_page_tab_label_4')" name="FINISHED"></el-tab-pane>
                    <el-tab-pane :label="$t('project.task_statistics_page_tab_label_5')" name="INVALID"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="right" style="padding: 12px 0">
                <div style="width:220px;" class="margin-l-10">
                    <el-input :placeholder="$t('project.task_statistics_page_search_input_placeholder')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <el-tooltip :content="$t('project.task_statistics_page_search_button_text_1')" placement="top">
                    <el-button size="small" plain round icon="el-icon-refresh-left" class="margin-l-10" style="padding: 6.4px; font-size: 18px;" :loading="refreshLoading" @click="initPage(false)"></el-button>
                </el-tooltip>
                <div class="margin-l-10" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-popover placement="bottom" trigger="click">
                        <div class="more-filter-content">
                            <el-form label-width="90px" style="width: 290px;">
                                <el-form-item :label="$t('project.task_statistics_page_search_select_placeholder_3')" class="margin-b-5">
                                    <el-select v-model="searchForm.taskType" size="small" :placeholder="$t('project.task_statistics_page_search_select_placeholder_3')" @change="selectSearchEvent" clearable>
                                        <template v-for="item in taskTypeOptions">
                                            <el-option :key="item.code" :value="item.code" :label="item.desc"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.task_statistics_page_search_select_placeholder_1')" class="margin-b-5">
                                    <el-select size="small" :placeholder="$t('project.task_statistics_page_search_select_placeholder_1')" filterable clearable v-model="searchForm.source" @change="selectSearchEvent">
                                        <template v-for="(item,key) in LANGUAGE_DICT">
                                            <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.target === item.key"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.task_statistics_page_search_select_placeholder_2')" class="margin-b-5">
                                    <el-select size="small" :placeholder="$t('project.task_statistics_page_search_select_placeholder_2')" filterable clearable v-model="searchForm.target" @change="selectSearchEvent">
                                        <template v-for="(item,key) in LANGUAGE_DICT">
                                            <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.source === item.key"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.task_statistics_page_search_select_placeholder_6')" class="margin-b-5">
                                    <el-date-picker v-model="searchForm.startTime" @change="selectSearchEvent" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" round size="small" :placeholder="$t('project.task_statistics_page_search_select_placeholder_6')" clearable style="width: 100%;"></el-date-picker>
                                </el-form-item>
                                <el-form-item :label="$t('project.task_statistics_page_search_select_placeholder_7')" class="margin-b-5">
                                    <el-date-picker v-model="searchForm.endTime" @change="selectSearchEvent" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" round size="small" :placeholder="$t('project.task_statistics_page_search_select_placeholder_7')" clearable style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-form>
                           
                        </div>
                        <el-badge slot="reference" is-dot class="more-filter-badge" :hidden="!(searchForm.taskType || searchForm.source || searchForm.target || searchForm.startTime || searchForm.endTime)">
                            <el-button plain round size="small" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-funnel"></svg-icon>
                            </el-button>
                        </el-badge>
                    </el-popover>
                </div>
                <div class="margin-l-10">
                    <el-button @click="downloadBtnEvent" :loading="downloadLoading" type="primary" round size="small">
                        <!-- <svg-icon name="ib-download-1" v-if="!downloadLoading"></svg-icon> -->
                        {{$t('project.task_statistics_page_search_button_text_2')}}
                    </el-button>
                </div>
            </div>
        </div>
        <div class="margin-t-15">
            <task-table-list ref="taskTableList" @update="updateSelected" :list="list" :customized="customized"></task-table-list>
        </div>
        <!-- <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info">没有任务～</div>
            </div>
        </div> -->
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize" >
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import qs from 'querystring';
import { projectApi, commonApi } from '@/utils/api';
import FullLoading from '@/components/FullLoading';
import TaskTableList from './components/TaskStatisticsTableList';
import config from '@/utils/config';
export default {
    components: {
        FullLoading, TaskTableList, 
    },
    data () {
        return {
            config,
            
            refreshLoading: false,
            searchForm: {
                kw: '',
                source: '',
                target: '',
                owner: '',
                taskType: '',
                status: 'ALL',
                order: '',
                startTime: '',
                endTime: '',
            },
            customized: false,
            list: [],
            total: 0,
            pageNumber: 1,
            pageSize: 16,
            searchTimer: null,
            ownerOptions: [],
            taskTypeOptions: [],
            orderOptions: [
                {value: 'FILENAME_ASC', text: this.$t('project.task_statistics_page_orderOptions_item_1')},
                {value: 'FILENAME_DESC', text: this.$t('project.task_statistics_page_orderOptions_item_2')},
                {value: 'START_TIME_ASC', text: this.$t('project.task_statistics_page_orderOptions_item_3')},
                {value: 'START_TIME_DESC', text: this.$t('project.task_statistics_page_orderOptions_item_4')},
                {value: 'DEADLINE_TIME_ASC', text: this.$t('project.task_statistics_page_orderOptions_item_5')},
                {value: 'DEADLINE_TIME_DESC', text: this.$t('project.task_statistics_page_orderOptions_item_6')},
            ],
            statusOptions: [
                {value: 'ASSIGNED', text: this.$t('project.task_statistics_page_statusOptions_item_1')},
                {value: 'REJECTED', text: this.$t('project.task_statistics_page_statusOptions_item_2')},
                {value: 'IN_PROGRESS', text: this.$t('project.task_statistics_page_statusOptions_item_3')},
                {value: 'COMPLETED', text: this.$t('project.task_statistics_page_statusOptions_item_4')},
                {value: 'FINISHED', text: this.$t('project.task_statistics_page_statusOptions_item_5')},
            ],
            downloadLoading: false,
            queryParams: {},
            selectedList: [],
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            activeOrg: state => state.org.activeOrg,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods: {
        initPage(showFullLoading){
            if(showFullLoading){
                this.$refs.fullLoading.setShow();
            }
            let url = `${projectApi.queryMyTaskStatisticsList}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}`;
            let query = {};
            if(this.searchForm.kw) {
                query['keyword'] = this.searchForm.kw;
            }
            if(this.searchForm.source) {
                query['sourceLanguage'] = this.searchForm.source;
            }
            if(this.searchForm.target) {
                query['targetLanguage'] = this.searchForm.target;
            }
            if(this.searchForm.taskType){
                query['taskType'] = this.searchForm.taskType;
            }
            if(this.searchForm.status !== 'ALL'){
                query['status'] = this.searchForm.status;
            }
            if(this.searchForm.startTime){
                query['startTime'] = this.searchForm.startTime;
            }
            if(this.searchForm.endTime){
                query['endTime'] = this.searchForm.endTime;
            }
            this.queryParams = query;
            let queryStr = qs.stringify(query);
            url = `${url}${queryStr.length > 0 ? '&' : ''}${queryStr}`;
            this.refreshLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.customized = res.data.customized;
                    this.list = res.data.page.records;
                    this.total = res.data.page.total;
                }
            }).finally(() => {
                this.refreshLoading = false;
                this.$refs.fullLoading.setHide();
            })
        },
        filterClickEvent(){
            this.pageNumber = 1;
            this.initPage(false);
        },
        initOwnerOptions(){
            let url = `${projectApi.queryAssignedList}?teamId=${this.userInfo.initTeam.teamId}&keyword=`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.ownerOptions = res.data;
                }
            })
        },
        initTaskTypeOptions () {
            let url = projectApi.queryTaskTypeList;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.taskTypeOptions = res.data.taskType;
                }
            })
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.$nextTick(()=> {
                this.initPage(false);
            })
        },
        inputSearchEvent(val){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.pageNumber = 1;
                self.initPage(false);
            }, 500)
        },
        selectSearchEvent(){
            this.$nextTick(()=> {
                this.pageNumber = 1;
                this.initPage(false);
            })
        },
        downloadBtnEvent() {
            if(this.list.length === 0) {
                this.$message.error(this.$t('project.task_statistics_methods_downloadBtnEvent_message_1'));
                return;
            }
            // console.log(this.activeOrg);
            let ids = this.selectedList.map(item => item.taskId);
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: `任务统计`,
                metadataIdList: [this.userInfo.memberId],
                metadataType: "TMS_TASK_STATISTICS",
                moreOptions: {
                    ...this.queryParams,
                    orgId: this.activeOrg.organizationId,
                    taskIds: ids,
                    queryType: 'TRANSLATOR',
                },
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                    this.selectedItem = [];
                    this.resetFileList = new Date().getTime();
                    this.$refs.taskTableList.resetTableSelected();
                }
            }).finally(()=>{
                this.downloadLoading = false;
            });
        },
        updateSelected (val) {
            this.selectedList = val;
        }
    },
    created() {
        
    },
    async mounted() {
        this.initPage(true);
        this.initTaskTypeOptions();
        
    }
}
</script>
<style lang="scss" scoped>
.project-tasks-statistics-page{
    .task-status-tabs {
        ::v-deep .el-tabs__nav-wrap {
            &::after {
                display: none;
            }
        }
        ::v-deep .el-tabs__item {
            height: 57.1px;
            line-height: 57.1px;
        }
    }
    .task-card-list{
        margin: 30px 90px;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill,minmax(190px,1fr));
        .item {
            // min-height: 228px;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 30px 15px 20px 15px;
            cursor: pointer;
            position: relative;
            .task-type-label {
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                display: flex;
                padding: 0 10px;
                align-items: center;
                font-size: 12px;
                color: #1AC49C;
                background-color: #DDF3EB;
                border-radius: 10px 0 10px 0;
                &.rejected {
                    color: #F56C6C;
                    background-color: #FDE2E2;
                }
            }
            .icon-content{
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: #F8F8F8;
                font-size: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .go-btn{
                width: 50px;
                height: 24px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                background-color: #f5f5f5;
            }
            .TO_START {
                color: #FE8E0B;
            }
            .IN_PROGRESS {
                color: #2979FF;
            }
            .COMPLETED,
            .FINISHED {
                color: #00B670;
            }
            .ASSIGNED {
                color: #FF9900;
            }
            .REJECTED {
                color: #999999;
            }
            .progress-text {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            ::v-deep .el-progress-bar__outer{
                background-color: #F8F8F8;
            }
        }
    }
}
.more-filter-content {
    display: flex;
    flex-direction: column;
    ::v-deep .el-select {
        .el-input__inner {
            // border-radius: 20px;
        }
    }
}
.more-filter-badge {
    ::v-deep .el-badge__content.is-dot {
        top: 5px;
        right: 8px;
    }
}
</style>
