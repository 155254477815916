<template>
    <div class="project-table-list">
    <el-table ref="projectTableList" :data="list"  @selection-change="handleSelectionChange" style="width: 100%">
        <el-table-column type="selection" :selectable="handleSetSelectionEnable" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column :label="$t('project.component_ProjectTableList_table_column_lable_1')" width="300" fixed :show-overflow-tooltip="false">
            <template slot-scope="scope">
                <el-tooltip placement="top-start">
                    <div class="fonts-12 line-height-2" slot="content">
                        {{$t('project.component_ProjectTableList_table_column_tooltip_label_1')}}{{scope.row.name}}<br>
                        <template v-if="userInfo.organizationType === 'ENTERPRISE' && scope.row.team">
                            {{$t('project.component_ProjectTableList_table_column_tooltip_label_2')}}{{scope.row.team.teamName}}<br>
                        </template>
                        <template v-if="userInfo.organizationType === 'ENTERPRISE'">
                            {{$t('project.component_ProjectTableList_table_column_tooltip_label_3')}}{{scope.row.createBy}}
                        </template>
                    </div>
                    <span class="text-omission" v-if="scope.row.preprocessStatus === 'completed'">
                        <router-link :to="`/project/list?id=${scope.row.projectId}`" class="table-link">{{ scope.row.name }}</router-link>
                    </span>
                    <span class="text-omission" v-else>{{scope.row.name}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column :label="$t('project.component_ProjectTableList_table_column_lable_2')" min-width="110">
            <template slot-scope="scope">
                <el-tooltip placement="top-start">
                    <template slot="content">
                        <div class="line-height-1.5" style="max-width: 400px;">
                            <span class="language-block">{{ scope.row.sourceLang.shortName }}</span>
                            <svg-icon name="ib-arrow-right" style="margin:0 4px;"></svg-icon>
                            <span class="language-block">{{ scope.row.targetShortName }}</span>
                        </div>
                    </template>
                    <span class="text-omission">
                        <span class="language-block">{{ scope.row.sourceLang.shortName }}</span>
                        <svg-icon name="ib-arrow-right" style="font-size:12px;margin:0 4px;"></svg-icon>
                        <span class="language-block">{{ scope.row.targetShortName }}</span>
                    </span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column :label="$t('project.component_ProjectTableList_table_column_lable_3')" prop="fileCount"></el-table-column>
        <el-table-column  :label="$t('project.component_ProjectTableList_table_column_lable_4')" prop="wordCount">
            <template slot-scope="scope">
                <span>{{scope.row.wordCount || '-'}}</span>
            </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="$t('project.component_ProjectTableList_table_column_lable_5')" prop="wordCount">
            <template slot-scope="scope">
                <span>{{scope.row.sourceCharacters || '-'}}</span>
            </template>
        </el-table-column>
        <el-table-column :label="$t('project.component_ProjectTableList_table_column_lable_6')" header-align="center" align="center" min-width="280">
            <template slot-scope="scope">
                <span v-if="scope.row.preprocessStatus === 'limited'">
                    <svg-icon name="ib-warning-o" class="fonts-20 color-error"></svg-icon>
                    <span class="margin-l-5 margin-r-10">{{$t('project.component_ProjectTableList_table_column_label_1')}}</span>
                    <el-tooltip :content="$t('project.component_ProjectTableList_table_column_tooltip_1')" placement="top" v-permission="`project:buy`">
                        <el-button type="text" class="button-link" @click="showPayCharDialog">{{$t('project.component_ProjectTableList_table_column_btn_1')}}</el-button>
                    </el-tooltip>
                    <el-button type="text" class="button-link" v-permission="`project:retry`" @click="executeAction('retry', scope.row)">{{$t('project.component_ProjectTableList_table_column_btn_2')}}</el-button>
                </span>
                <template v-else-if="scope.row.preprocessStatus === 'completed'">
                    <div class="task-progress" style="margin-top: 4px;max-width: 150px;">
                        <el-progress :percentage="scope.row.progress*1" :stroke-width="20" color="#D3F4EC" :show-text="false"></el-progress>
                        <div class="percentage">{{scope.row.progress}}%</div>
                    </div>
                </template>
                <template v-else>
                    <div class="fonts-14"><i class="el-icon-loading"></i> {{ scope.row.preprocessStatusDesc }}</div>
                </template>
            </template>
        </el-table-column>
        <el-table-column :label="$t('project.component_ProjectTableList_table_column_lable_7')" min-width="100">
            <template slot-scope="scope">
                <engine-group :size="24" :list="scope.row.engines"></engine-group>
            </template>
        </el-table-column>
        <el-table-column :label="$t('project.component_ProjectTableList_table_column_lable_8')" min-width="140">
            <template slot-scope="scope">
                <svg-icon name="ib-upload-file" className="fonts-18 color-999 margin-r-10"></svg-icon>{{getTimeText(scope.row.createTime)}}
            </template>
        </el-table-column>
        <el-table-column :label="$t('project.component_ProjectTableList_table_column_lable_9')" fixed="right" width="70">
            <template slot-scope="scope">
                <template v-if="scope.row.preprocessStatus == 'completed' || scope.row.preprocessStatus === 'limited'">
                    <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command, scope.row)">
                        <div class="fonts-20 cursor-pointer">
                            <svg-icon name="ib-more"></svg-icon>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <template v-if="scope.row.preprocessStatus == 'completed'">
                                <el-dropdown-item command="view" v-permission="`project:detail`">{{$t('project.component_ProjectTableList_table_column_dropdown_item_1')}}</el-dropdown-item>
                                <template v-if="userInfo.internal">
                                    <template v-if="scope.row.status !== 'FINISHED'">
                                        <el-dropdown-item command="check" v-permission="`project:acceptance`">{{$t('project.component_ProjectTableList_table_column_dropdown_item_2')}}</el-dropdown-item>
                                    </template>
                                </template>
                                <!-- <el-dropdown-item command="download" v-permission="`project:download`">下载项目</el-dropdown-item> -->
                                <el-dropdown-item command="target" divided v-permission="`project:download`">{{$t('project.component_ProjectTableList_table_column_dropdown_item_3')}}</el-dropdown-item>
                                <el-dropdown-item command="bilingual" v-permission="`project:download`">{{$t('project.component_ProjectTableList_table_column_dropdown_item_4')}}
                                    <pro-icon :size="14" style="margin-bottom:-2px;margin-left:5px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></pro-icon>
                                </el-dropdown-item>
                                <el-dropdown-item command="source" v-permission="`project:download`">{{$t('project.component_ProjectTableList_table_column_dropdown_item_5')}}</el-dropdown-item>
                                <el-dropdown-item command="tmx" v-permission="`project:download`">{{$t('project.component_ProjectTableList_table_column_dropdown_item_6')}}</el-dropdown-item>
                            </template>
                            <!-- <el-dropdown-item command="template" divided v-permission="``">{{$t('project.component_ProjectTableList_table_column_dropdown_item_8')}}</el-dropdown-item> -->
                            <el-dropdown-item command="delete" :divided="scope.row.preprocessStatus == 'completed'" v-permission="`project:delete`" style="color:#F56C6C;">{{$t('project.component_ProjectTableList_table_column_dropdown_item_7')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </template>
        </el-table-column>
    </el-table>
    </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import EngineGroup from '@/components/EngineGroup';
import ProIcon from "@/components/icon/ProIcon";
export default {
    components: {
        EngineGroup, ProIcon
    },
    props: {
        type:{
            type: String,
            default: '',
        },
        list: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {

        }
    },
    computed:{
        ...mapState({
            // engineDist:state=>state.common.engineConfig,
            userInfo: state => state.user.userData,
            // LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods: {
        executeAction(key,data){
            this.$emit('action', key, data);
        },
        handleMoreCommand(command,item){
            switch(command){
                case 'view':
                    this.executeAction('goto', item);
                    break;
                case 'target':
                case 'bilingual':
                case 'source':
                case 'tmx':
                    this.executeAction('download', {type: command, project: item});
                    break;
                case 'check': 
                    this.executeAction('check', item);
                    break;
                case 'delete':
                    this.executeAction('delete', item);
                    break;
                case 'template':
                    this.executeAction('template', item);
                    break;
            }
        },
        handleSetSelectionEnable(row, index) {
            return row.preprocessStatus === 'completed' || row.preprocessStatus === 'limited' || row.preprocessStatus.indexOf('error') > -1;
        },
        showPayCharDialog() {
            this.$router.push({path:'/account/vip', query: {view: 'char', child: true}});
        },
        getTimeText(t) {
            let tYear = moment(t).year();
            let cYear = moment().year();
            if(tYear === cYear) {
                return moment(t).format(`MM-DD HH:mm`);
            }else {
                return moment(t).format(`YYYY-MM-DD`);
            }
        },
        handleSelectionChange(selection) {
            this.executeAction('updateSelected', selection);
        },
        resetTableSelected() {
            this.$refs.projectTableList.clearSelection();
        },
    },
    mounted() {
        
    }
}
</script>
<style lang="scss" scoped>
.project-table-list{
    .table-link {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
    }
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
    .task-progress {
        display: inline-block;
        width: 198px;
        position: relative;
        .percentage {
            width: 100%;
            position: absolute;
            top: 0;
            line-height: 20px;
            text-align: center;
            color: #1AC49C;
            font-weight: 500;
        }
    }
}
</style>
