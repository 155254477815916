<template>
    <div class="editer-right-component">
        <div class="search-memery-btn">
            <div class="btn-main" @click="$emit('action','searchTMDb')">
                <span>{{$t('workbench.component_EditerRight_tips_1')}}</span>
                <svg-icon name="ib-search"></svg-icon>
            </div>
        </div>
        <div class="jargon-content">
            <div class="block-title margin-y-10">{{$t('workbench.component_EditerRight_chunk_title_1')}}</div>
            <div class="jargon-list">
                <template v-if="jargonsLoading">
                    <div class="loading-block"><i class="el-icon-loading"></i> {{$t('workbench.component_EditerRight_tips_2')}}</div>
                </template>
                <template v-else>
                    <template v-if="jargons.length > 0">
                        <div class="item" v-for="(item,index) in jargons" :key="index" @mousedown.prevent @click="applyJargonBtnEvent(item)">
                            <div :lang="doc.sourceLang">{{item.source}}</div>
                            <div :lang="doc.targetLang">
                                <el-tooltip :content="$t('workbench.component_EditerRight_tips_3')" placement="top">
                                    <span>{{item.target}}</span>
                                </el-tooltip>
                            </div>
                            <div class="fonts-12 color-666666" v-if="item.description">{{$t('workbench.component_EditerRight_tips_4')}}{{item.description}}</div>
                            <div class="fonts-16 color-666666">
                                <el-tooltip placement="top">
                                    <div class="line-height-1.5" slot="content">
                                        <div>{{$t('workbench.component_EditerRight_tips_12')}}{{ item.asset?.name }}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_14')}}{{ item.asset?.createTime || '-' }}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_13')}}{{ item.asset?.createBy || '-'}}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_15')}}{{ item.asset?.updateTime || '-'}}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_16')}}{{ item.asset?.updateBy || '-' }}</div>
                                    </div>
                                    <svg-icon name="ib-info"></svg-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <!-- <div class="none-block" v-if="doc.splitMode === 'PARAGRAPH'">段落拆分模式不支持查询术语</div> -->
                        <div class="none-block">{{$t('workbench.component_EditerRight_chunk_empty_message_1')}}</div>
                    </template>
                </template>
            </div>
        </div>
        <div class="memory-content">
            <div class="block-title margin-y-10">{{$t('workbench.component_EditerRight_chunk_title_2')}}</div>
            <div class="memory-list">
                <template v-if="memoriesLoading">
                    <div class="loading-block"><i class="el-icon-loading"></i> {{$t('workbench.component_EditerRight_tips_2')}}</div>
                </template>
                <template v-else>
                    <template v-if="memories.length > 0">
                        <div class="item" v-for="(item,index) in memories" :key="index">
                            <div class="line">
                                <div class="left">
                                    <el-tooltip placement="top" v-if="item.setting.length">
                                        <div class="line-height-1.5" slot="content">
                                            <template v-for="(se,index) in item.setting">
                                                <div :key="index">
                                                    {{ se.penaltyType === 'MEM_OT' ? '翻译记忆库罚分' 
                                                        : ''
                                                    }}
                                                    {{se.penaltyPoints}}
                                                </div>
                                                
                                            </template>
                                        </div>
                                        <div :class="['rate',item.rate===100?'':'not']">{{item.rate}}%</div>
                                    </el-tooltip>
                                    <div :class="['rate',item.rate===100?'':'not']" v-else>{{item.rate}}%</div>
                                </div>
                                <div class="right">
                                    <div class="source" :lang="doc.sourceLang" v-html="item.taggedSourceText"></div>
                                </div>
                            </div>
                            <div class="line">
                                <div class="left" >
                                    <div class="apply-btn" @click="applyMemoryBtnEvent(item)" v-if="sentPermission">{{$t('workbench.component_EditerRight_btn_1')}}</div>
                                </div>
                                <div class="right">
                                    <div class="target" :lang="doc.targetLang">{{item.target}}</div>
                                </div>
                            </div>
                            <div class="line margin-x-15">
                                <el-tooltip placement="top">
                                    <div class="line-height-1.5" slot="content">
                                        <div>{{$t('workbench.component_EditerRight_tips_5')}}{{ item.asset.name }}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_11')}}{{ item.asset.updateBy  || '-'}}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_10')}}{{ item.asset.updateTime || '-'}}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_17')}}{{item.asset.fileName || '-'}}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_18')}}{{item.asset.projectName || '-'}}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_7')}}{{ item.asset.createTime }}</div>
                                        <div>{{$t('workbench.component_EditerRight_tips_6')}}{{ item.asset.createBy || '-' }}</div>
                                    </div>
                                    <div class="color-666666 fonts-12" style="max-width: 100%">
                                        <div class="flex">
                                            <div class="flex-1 text-omission">
                                                <svg-icon name="ib-info"></svg-icon>
                                                {{ item.asset.name }}
                                            </div>
                                            <div class="flex-1 text-omission margin-l-5">
                                                {{ item.asset.updateTime || '-' }}
                                            </div>
                                        </div>
                                        <div class="flex margin-t-5">
                                            <div class="flex-1 text-omission">
                                                {{$t('workbench.component_EditerRight_tips_19')}}{{ item.asset.fileName || '-' }}
                                            </div>
                                            <div class="flex-1 text-omission margin-l-5">
                                                {{$t('workbench.component_EditerRight_tips_20')}}{{ item.asset.updateBy || '-' }}
                                            </div>
                                        </div>
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="none-block" v-if="doc.splitMode === 'PARAGRAPH'">{{$t('workbench.component_EditerRight_chunk_empty_message_2')}}</div>
                        <div class="none-block" v-else>{{$t('workbench.component_EditerRight_chunk_empty_message_3')}}</div>
                    </template>
                </template>
            </div>
        </div>
        <div class="mt-content" v-if="doc.enableAiTrans">
            <div class="block-title  margin-y-10">{{$t('workbench.component_EditerRight_chunk_title_3')}}</div>
            <div class="mt-list">
                <template>
                    <template v-if="translates.engine">
                        <div class="item">
                            <div class="translate-content">
                                <div class="icon-block">
                                    <el-tooltip placement="top" :content="$t('workbench.component_EditerRight_tips_8', {name: translates.engine.name})">
                                        <svg-icon :name="'ie-'+translates.engine.engine"></svg-icon>
                                    </el-tooltip>
                                </div>
                                <div class="translate-block" :lang="doc.targetLang" v-html="translates.engine.target"></div>
                            </div>
                            <div class="btn-content" v-if="sentPermission">
                                <div class="apply-btn" @click="applyBtnEvent(translates.engine)">{{$t('workbench.component_EditerRight_btn_1')}}</div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="none-block" v-if="doc.splitMode === 'PARAGRAPH'">{{$t('workbench.component_EditerRight_chunk_empty_message_4')}}</div>
                        <div class="none-block" v-else>{{$t('workbench.component_EditerRight_chunk_empty_message_5')}}</div>
                    </template>
                </template>
            </div>
        </div>
        <div class="mt-content">
            <div class="block-title  margin-y-10">{{$t('workbench.component_EditerRight_chunk_title_4')}}
                <!-- <span class="margin-l-5 cursor-pointer fonts-16" @click="showReferenceEngine"><svg-icon name="ib-edit"></svg-icon></span> -->
            </div>
            <div class="mt-list">
                 <template v-if="translatesLoading">
                    <div class="loading-block"><i class="el-icon-loading"></i> {{$t('workbench.component_EditerRight_tips_9')}}</div>
                </template>
                <template v-else>
                    <template v-if="translates.reference">
                        <div class="item">
                            <div class="translate-content">
                                <div class="icon-block">
                                    <el-tooltip placement="top" :content="$t('workbench.component_EditerRight_tips_8', {name: translates.reference.name})">
                                        <svg-icon :name="'ie-'+translates.reference.engine"></svg-icon>
                                    </el-tooltip>
                                </div>
                                <div class="translate-block" :lang="doc.targetLang" v-html="translates.reference.target"></div>
                            </div>
                            <div class="btn-content" v-if="sentPermission">
                                <div class="apply-btn" @click="applyBtnEvent(translates.reference)">{{$t('workbench.component_EditerRight_btn_1')}}</div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="none-block" v-if="doc.splitMode === 'PARAGRAPH'">{{$t('workbench.component_EditerRight_chunk_empty_message_4')}}</div>
                        <div class="none-block" v-else>{{$t('workbench.component_EditerRight_chunk_empty_message_5')}}</div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import config from '@/utils/config';
import tools from '@/utils/tools'
export default {
    name:'EditerRight',
    props:{
        jargonsLoading:false,
        translatesLoading:false,
        memoriesLoading:false,
        doc:{
            type:Object,
            default:{}
        },
        jargons:{
            type:Array,
            default:[]
        },
        memories:{
            type:Array,
            default:[]
        },
        translates:{
            type: Object,
            default: {},
        },
        sentPermission: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            // engineDist:tools.getEngineDist(config.DOMAIN_AND_ENGINE_LIST),
        }
    },
    computed:{
        ...mapState({
            engineDist:state=>state.common.engineConfig
        })
    },
    methods:{
        applyJargonBtnEvent(item){
            if(this.sentPermission){
                this.$emit('applyJargon',item);
            }
        },
        applyBtnEvent(item){
            this.$emit('applyTranslate',item);
        },
        applyMemoryBtnEvent(item){
            this.$emit('applyMemory',item);
        },
        showReferenceEngine(){
            this.$emit('action', 'showReferenceEngine');
        },
    },
    mounted(){
        // console.log('xxx:::', this.doc)
    }
}
</script>
<style lang="scss" scoped>
.editer-right-component{
    height: 0;
    min-height: 100%;
    width: 340px;
    padding: 0 20px;
    overflow-y: auto; 
    .block-title{
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #000000;
        display: flex;
        align-items: center;
        > i{
            font-size: 20px;
            font-weight: 500;
            margin-right: 5px;
        }
    }
    .search-memery-btn{
        padding: 15px 0px;
        border-bottom: 1px solid #f1f1f1;
        box-sizing: border-box; 
        cursor: pointer;
        .btn-main{
            height: 40px;
            border: 1px solid #e5e5e5;
            box-sizing: border-box; 
            border-radius: 6px;
            font-size: 14px;
            color: #808080;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px
        }
    }
    .jargon-content{
        //margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #F1F1F1;
        > .jargon-list{
            display: flex;
            flex-wrap: wrap;
            > .item{
                border-radius: 36px;
                margin-bottom: 10px;
                margin-right: 10px;
                padding: 8px 20px;
                font-size: 14px;
                line-height: 20px;
                // box-sizing: border-box;
                // border: 1px solid #00D959;
                background-color: #FAFAFA;
                > div{
                    float: left;
                    margin-right: 20px;
                    &:nth-child(2){
                        color: #00B670;
                        cursor: pointer;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .memory-content{
        // margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #F1F1F1;
        > .memory-list{
            > .item{
                background-color: #FAFAFA;
                border-radius: 8px;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                > .line{
                    display: flex;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    &:first-child{
                        margin-top: 20px;
                    }
                    &:last-child{
                        margin-bottom: 20px;
                    }
                    > .left{
                        width: 44px;
                        > .rate{
                            // width:100%;
                            height: 24px; 
                            color: #00B670;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 600;
                            font-size: 12px;
                            &.not{
                                color: #FE8E0B;
                            }
                        }
                    }
                    > .right{
                        flex: 1;
                        margin-left: 5px;
                        margin-right: 10px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000000;
                        font-size: 14px;
                        line-height: 20px;
                        > .source{
                            color: #00000080;
                            ::v-deep .diff-highlight {
                                color:#00B670;
                            }
                            .delete{
                                color:#FE8E0B;
                                text-decoration: line-through;
                            }
                            .insert{
                                color:#00B670;
                            }
                        }
                        word-wrap: break-word;
                    }
                    .apply-btn{
                        width: 40px;
                        height: 24px;
                        border-radius:0  20px  20px 0;
                        background: #7DD6B3;
                        text-align: center;
                        line-height: 24px;
                        font-size: 12px;
                        color: #ffffff;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                }
                
            }
        }
    }
    .mt-content{
        // margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #F1F1F1;
        > .mt-list{
            > .item{
                background-color: #FAFAFA;
                border-radius: 8px;
                margin-bottom: 10px;
                padding: 20px 0px;
                > .translate-content{
                    padding: 0 10px;
                    display: flex;
                    > .icon-block{
                        > svg{
                            font-size: 20px;
                        }
                    }
                    > .translate-block{
                        flex: 1;
                        margin-left: 10px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000000;
                        word-wrap: break-word;
                        // word-break: break-all;
                    }
                }
                > .btn-content{
                    display: flex;
                    margin-top: 10px;
                    > .apply-btn{
                        width: 44px;
                        height: 24px;
                        border-radius:0  20px  20px 0;
                        background: #7DD6B3;
                        text-align: center;
                        line-height: 24px;
                        font-size: 12px;
                        color: #ffffff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .bg-memory-name {
        background: #0000001A;
        padding: 2px 5px;
        border-radius: 6px;
    }
}
/* @media  screen and(max-width:1600px){
    .editer-right-component{
        width:300px;
    }
} */
</style>
<style lang="scss">

</style>
