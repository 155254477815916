<template>
    <div class="qa-setting-component">
        <el-dialog :title="$t('component.QaSetting_title')" :visible.sync="show" destroy-on-close width="860px" top="20px">
            <div style="width:90%;margin:auto;">
                <div class="block-title">{{ $t('component.QaSetting_type_title_1') }}</div>
                <div class="option-list">
                    <template v-for="(item,index) in basicList">
                        <div class="item" :key="index">
                            <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description"></el-checkbox>
                        </div>
                    </template>
                </div>
                <div class="block-title">
                    {{ $t('component.QaSetting_type_title_2') }}
                    <!-- <el-tooltip :content="$t('component.QaSetting_support_tooltip_content')" placement="top">
                        <span style="color:#000;">
                            <i class="el-icon-info"></i>
                        </span>
                    </el-tooltip> -->
                </div>
                <div class="option-list">
                    <template v-for="(item,index) in aiList">
                        <div class="item" :key="index">
                            <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description"></el-checkbox>
                        </div>
                    </template>
                </div>
                <!-- <div style="font-size:12px;">
                    <svg-icon name="ib-warning" style="width: 14px;height: 14px;fill:#FDB500;"></svg-icon> {{ $t('component.QaSetting_fluency_tips') }}
                </div> -->
            </div>
            <div slot="footer" style="text-align: center;">
                <el-button plain round size="small" @click="show = false">{{ $t('component.QaSetting_button_cancel') }}</el-button>
                <el-button round type="primary" size="small" :loading="loading" @click="submitBtnEvent">{{ $t('component.QaSetting_button_submit') }}</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>
<script>
import { qaApi, projectApi } from '@/utils/api';
export default {
    props: {
        doc:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            show:false,
            loading:false,
            basicList:[],
            aiList:[],
        }
    },
    methods:{
        initList(){
            let url = `${qaApi.queryQaConfig}?projectId=${this.doc.projectId}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let list = res.data.options;
                    list.map(item=>{
                        if(item.type === 'BASIC'){
                            this.basicList.push(item);
                        }else if(item.type === 'AI'){
                            this.aiList.push(item);
                        }
                    });
                }
            })
        },
        setShow(){
            this.show = true;
        },
        submitBtnEvent(){
            // let list = [].concat(this.basicList, this.aiList);
            // this.loading = true;
            // let url = this.$ajax.format(projectApi.updateProjectsSettingInfo,{id: this.doc.projectId})
            // this.$ajax.put(url,{qaSetting:{options: list}}).then(res=>{
            //     if(res.status === 200){
            //         this.$message.success(this.$t('component.QaSetting_success_message'));
            //         this.show = false;
            //         if(this.$listeners['submit']){
            //             this.$emit('submit');
            //         }
            //     }
            // }).finally(()=>{
            //     this.loading = false;
            // })
            let list = [].concat(this.basicList, this.aiList);
            this.loading = true;
            let url = this.$ajax.format(projectApi.updateProjectsSettingInfo,{id: this.doc.projectId})

            let optionsObject = {};
            list.forEach(option => {
                optionsObject[option.code] = option.selected;
            });
            
            // console.log({qaSetting:{selected: optionsObject}})

            this.$ajax.put(url,{qaSetting:{selected: optionsObject}}).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('component.QaSetting_success_message'));
                    this.show = false;
                    if(this.$listeners['submit']){
                        this.$emit('submit');
                    }
                }
            }).finally(()=>{
                this.loading = false;
            })
        }
    },
    mounted(){
        this.initList();
    }
}
</script>
<style lang="scss" scoped>
.qa-setting-component{
    .block-title{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .option-list{
        display: flex;
        margin-bottom: 25px;
        flex-wrap: wrap;
        .item{
            width: 25%;
            padding: 10px 0;
            word-break: break-all;
            word-wrap: break-word;
        }
    }
}
</style>
